import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Input,
  VStack,
  HStack,
  Avatar,
  Text,
  Heading,
  Spinner,
  Center,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { FiChevronLeft } from "react-icons/fi"; // Importing the Chevron Left icon
import axios from "axios";
import { useOutletContext, useNavigate } from "react-router-dom"; // Import the hook
import protocoluser from "../../../assets/protocol-user.jpg";
import tempImage from "../../../assets/temp.png";

const Chat = () => {
  const { orgData } = useOutletContext();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Hook for navigation

  const API_KEY = "sk-proj-Tp6FFkSvCegPaNHP53TBT3BlbkFJEonSWHHvtVsuJI4IuaDY";
  const BASE_URL = "https://api.openai.com/v1";

  const createThread = useCallback(
    async () => {
      if (threadId || !orgData?.vectorStoreId) return;

      try {
        const response = await axios.post(
          `${BASE_URL}/threads`,
          {
            tool_resources: {
              file_search: {
                vector_store_ids: [orgData.vectorStoreId],
              },
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${API_KEY}`,
              "OpenAI-Beta": "assistants=v2",
            },
          }
        );
        setThreadId(response.data.id);
        console.log(response);
        setLoading(false);
      } catch (error) {
        console.error("Error creating thread:", error);
        setLoading(false);
      }
    },
    [API_KEY, BASE_URL, threadId, orgData?.vectorStoreId]
  );

  const addMessage = async (content) => {
    await axios.post(
      `${BASE_URL}/threads/${threadId}/messages`,
      {
        role: "user",
        content: content,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
          "OpenAI-Beta": "assistants=v2",
        },
      }
    );
    runAssistant();
  };

  const runAssistant = async () => {
    await axios.post(
      `${BASE_URL}/threads/${threadId}/runs`,
      {
        assistant_id: orgData.assistantId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
          "OpenAI-Beta": "assistants=v2",
        },
      }
    );
    fetchMessages();
  };

  const fetchMessages = useCallback(async () => {
    const response = await axios.get(
      `${BASE_URL}/threads/${threadId}/messages`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_KEY}`,
          "OpenAI-Beta": "assistants=v2",
        },
      }
    );
    setMessages(response.data.data);
  }, [API_KEY, BASE_URL, threadId]);

  useEffect(() => {
    if (orgData?.vectorStoreId && !threadId) {
      createThread();
    }
  }, [orgData?.vectorStoreId, threadId, createThread]);

  useEffect(() => {
    if (threadId) {
      const interval = setInterval(() => {
        fetchMessages();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [threadId, fetchMessages]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input.trim()) {
      addMessage(input);
      setInput("");
    }
  };

  if (loading) {
    return (
      <Center h="calc(100vh - 120px)">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box display="flex" h="calc(100vh - 120px)">
      <Box flex="1" display="flex" flexDirection="column">
        <HStack mb={4} alignItems="center">
          <IconButton
            aria-label="Go back"
            icon={<FiChevronLeft />}
            size="lg"
            onClick={() => navigate("/dashboard/protocols")}
          />
          <Heading>EvalChat</Heading>
        </HStack>
        <VStack spacing={4} align="stretch" flex="1" overflowY="auto">
          {messages.length === 0 ? (
            <Center flex="1" flexDirection="column">
              <Image src={tempImage} alt="Temporary Image" mb={4} w="100px" />
              <Text fontSize="lg" color="gray.500">
                Ask a question below
              </Text>
            </Center>
          ) : (
            <VStack
              align="stretch"
              spacing={3}
              border="1px solid"
              borderColor="#e6e6e6"
              p={4}
              borderRadius="md"
              flex="1"
            >
              {messages
                .slice()
                .reverse()
                .map((msg, idx) => (
                  <HStack
                    key={idx}
                    alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
                  >
                    {msg.role === "assistant" && (
                      <Avatar name="Assistant" src={protocoluser} />
                    )}
                    <Box
                      p={2}
                      bg={msg.role === "user" ? "blue.100" : "#e6e6e6"}
                      borderRadius="md"
                    >
                      <Text>
                        {msg.content.map((c) => c.text.value).join(" ")}
                      </Text>
                    </Box>
                    {msg.role === "user" && (
                      <Avatar name="User" src="/user-icon.png" />
                    )}
                  </HStack>
                ))}
            </VStack>
          )}
        </VStack>
        <HStack spacing={2} mt={4}>
          <Input
            placeholder="Search protocols"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            flex="1"
            size="lg"
            border="1px solid"
            borderColor="#e6e6e6"
          />
          <Button
            onClick={() => {
              addMessage(input);
              setInput("");
            }}
            colorScheme="blue"
            size="lg"
            rightIcon={<ArrowForwardIcon />}
          >
            Send
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default Chat;
