import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

// Utils
import { AuthProvider } from "./components/utils/AuthContext";
import PrivateRoute from "./components/utils/PrivateRoute";
import DirectRoute from "./components/utils/DirectRoute";

// Main
import SignUp from "./components/SignUp";
import Login from "./components/Login";

// Dashboard
import Dashboard from "./components/Dashboard";
import PasswordReset from "./components/PasswordReset";
import Overview from "./components/tabs/overview/Overview";
import Profile from "./components/tabs/profile/Profile";
import Organization from "./components/tabs/organization/Organization";
import Reports from "./components/tabs/reports-page/Reports";
import Staff from "./components/tabs/staff/Staff";
import StaffProfile from "./components/tabs/staff/StaffProfile";
import Protocols from "./components/tabs/protocols/Protocols";
import Chat from "./components/tabs/protocols/Chat";
import Evaluations from "./components/tabs/evaluations/Evaluations";
import EvaluationsEdit from "./components/tabs/evaluations/EvaluationsEdit";
import EvaluationsForm from "./components/tabs/evaluations/EvaluationsForm";
import ReportDetails from "./components/tabs/reports-page/ReportsDetail";

const theme = extendTheme({
  fonts: {
    heading: "Reddit Sans, sans-serif",
    body: "Reddit Sans, sans-serif",
  },
  colors: {
    blue: {
      400: "#0066c4",
      500: "#004381",
      600: '#001c36',
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/login"
              element={
                <DirectRoute>
                  <Login />
                </DirectRoute>
              }
            />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/reset"
              element={
                <DirectRoute>
                  <PasswordReset />
                </DirectRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              <Route index element={<Navigate to="overview" />} />
              <Route path="organization" element={<Organization />} />
              <Route path="overview" element={<Overview />} />
              <Route path="staff" element={<Staff />} />
              <Route path="staff/:uid" element={<StaffProfile />} />
              <Route path="evaluations" element={<Evaluations />} />
              <Route path="evaluations/:evaluationId" element={<EvaluationsForm />} />
              <Route path="evaluations/:evaluationId/edit" element={<EvaluationsEdit />} />
              <Route path="protocols" element={<Protocols />} />
              <Route path="protocols/chat" element={<Chat />} />
              <Route path="reports" element={<Reports />} />
              <Route path="reports/:reportId" element={<ReportDetails />} />
              <Route path="profile" element={<Profile />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
