import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import axios from 'axios';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc, collection } from "firebase/firestore";
import { db } from "../../../Firebase"; // Ensure this import is correct

const API_KEY = "sk-proj-Tp6FFkSvCegPaNHP53TBT3BlbkFJEonSWHHvtVsuJI4IuaDY";
const BASE_URL = "https://api.openai.com/v1";

const AddFileModal = ({ isOpen, onClose, orgData, onUploadComplete }) => {
  const [newFile, setNewFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const storage = getStorage();

  const addFileToVectorStore = async (fileId, fileURL, imageURL) => {
    if (!orgData?.vectorStoreId) return;

    try {
      await axios.post(
        `${BASE_URL}/vector_stores/${orgData.vectorStoreId}/files`,
        {
          file_id: fileId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );
      await new Promise((resolve) => setTimeout(resolve, 5000));
      onUploadComplete(); // Assuming this function triggers a re-fetch of files
    } catch (error) {
      console.error('Error adding file to vector store:', error);
    }
  };

  const uploadFile = async () => {
    if (!newFile || !newImage || !orgData?.vectorStoreId) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('purpose', 'assistants');
      const fileResponse = await axios.post(
        `${BASE_URL}/files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${API_KEY}`,
          },
        }
      );
      const fileId = fileResponse.data.id;

      const fileStorageRef = ref(storage, `${orgData.code}/protocols/${fileId}/file`);
      await uploadBytes(fileStorageRef, newFile);
      const fileURL = await getDownloadURL(fileStorageRef);

      const imageStorageRef = ref(storage, `${orgData.code}/protocols/${fileId}/image`);
      await uploadBytes(imageStorageRef, newImage);
      const imageURL = await getDownloadURL(imageStorageRef);

      await addFileToVectorStore(fileId, fileURL, imageURL);

      const protocolsRef = doc(collection(db, "org", orgData.code, "protocols"), fileId);
      await setDoc(protocolsRef, {
        name: fileName,
        fileURL: fileURL,
        imageURL: imageURL,
        createdAt: new Date().toISOString(),
      });

      onUploadComplete();
      onClose();
    } catch (error) {
      console.error('Error uploading file or image:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add a new file</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>File Name</FormLabel>
            <Input
              type="text"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              placeholder="Enter file name"
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Upload File</FormLabel>
            <Input
              type="file"
              onChange={(e) => setNewFile(e.target.files[0])}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Upload Image</FormLabel>
            <Input
              type="file"
              onChange={(e) => setNewImage(e.target.files[0])}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={uploadFile} isDisabled={loading}>
            {loading ? 'Please wait...' : 'Upload File'}
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddFileModal;
