import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Avatar,
  HStack,
//   Code,
  IconButton,
  Select,
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { FiChevronLeft, FiSettings } from "react-icons/fi";
import StaffDetail from "./StaffDetail";

const StaffProfile = () => {
  const { uid } = useParams();
  const { orgData, userDoc } = useOutletContext();
  const navigate = useNavigate();
  const db = getFirestore();
  const toast = useToast();

  const staffMember = orgData?.staff.find((member) => member.uid === uid); // Staff member from URL param
  const currentUser = orgData?.staff.find((member) => member.uid === userDoc.uid); // Current logged-in user

  const [permissionsDropdownOpen, setPermissionsDropdownOpen] = useState(false);
  const [permissions, setPermissions] = useState({});

  // Update permissions state when staffMember changes
  useEffect(() => {
    if (staffMember) {
      setPermissions(staffMember.permissions || {});
    }
  }, [staffMember]);

  if (!staffMember) {
    return <Text>No staff member found with UID: {uid}</Text>;
  }

  if (!currentUser) {
    return <Text>No current user found with UID: {userDoc.uid}</Text>;
  }

  const editVerification = currentUser.permissions["Edit Verification"] || false;
  const editDepartment = currentUser.permissions["Edit Department"] || false;
  const editPermissions = currentUser.permissions["Edit Permissions"] || false;
  const editRole = currentUser.permissions["Edit Role"] || false;

  const updateStaffMember = async (updatedFields) => {
    try {
      const updatedStaff = orgData.staff.map((member) => {
        if (member.uid === uid) {
          return { ...member, ...updatedFields };
        }
        return member;
      });

      const orgRef = doc(db, "org", orgData.code);
      await updateDoc(orgRef, { staff: updatedStaff });

      orgData.staff = updatedStaff; // Update local context without refreshing
      setPermissions(updatedFields.permissions || permissions); // Update local permissions state
    } catch (error) {
      console.error("Error updating staff member:", error);
      toast({
        title: "Failed to update staff member",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRoleChange = (newRole) => {
    // Find the permissions for the selected role
    const roleData = orgData.roles.find((role) => role.name === newRole);
    const newPermissions = roleData ? roleData.permissions : {};

    updateStaffMember({ role: newRole, permissions: newPermissions });
  };

  const handleDepartmentChange = (newDepartment) => {
    updateStaffMember({ department: newDepartment });
  };

  const handleVerificationChange = () => {
    updateStaffMember({ verified: !staffMember.verified });
  };

  const handlePermissionChange = (permissionKey) => {
    if (!editPermissions) return; // Prevent changes if the user doesn't have permission

    const updatedPermissions = {
      ...permissions,
      [permissionKey]: !permissions[permissionKey],
    };
    setPermissions(updatedPermissions);
    updateStaffMember({ permissions: updatedPermissions });
  };

  // Get sorted permissions keys
  const sortedPermissionsKeys = Object.keys(permissions).sort();

  return (
    <Box>
      <VStack borderWidth="1px" p={4} borderRadius="md" align="start" spacing={4}>
        <HStack spacing={8}>
          <IconButton
            icon={<FiChevronLeft />}
            onClick={() => navigate("/dashboard/staff")}
            aria-label="Back to staff list"
          />
          <Avatar
            size="lg"
            name={`${staffMember.firstName} ${staffMember.lastName}`}
            src={staffMember.profilePictureURL}
            bg="gray.200"
            color="#222222"
          />
          <VStack align="start" spacing={0}>
            <Text fontSize="2xl" fontWeight="bold">
              {staffMember.firstName} {staffMember.lastName}
            </Text>
            <Text fontSize="md" color="gray.500">
              {staffMember.email}
            </Text>
          </VStack>
          <VStack align="start" spacing={1}>
            <Text fontSize="lg" fontWeight="semibold">
              Department
            </Text>
            {editDepartment ? (
              <Select
                value={staffMember.department}
                onChange={(e) => handleDepartmentChange(e.target.value)}
                size="sm"
              >
                <option value={staffMember.department}>
                  {staffMember.department || "No Department"}
                </option>
                {orgData.departments.map((dept, i) => (
                  <option key={i} value={dept}>
                    {dept}
                  </option>
                ))}
              </Select>
            ) : (
              <Text fontSize="md">
                {staffMember.department || "No Department"}
              </Text>
            )}
          </VStack>
          <VStack align="start" spacing={1}>
            <Text fontSize="lg" fontWeight="semibold">
              Role
            </Text>
            {editRole ? (
              <Select
                value={staffMember.role}
                onChange={(e) => handleRoleChange(e.target.value)}
                size="sm"
              >
                <option value={staffMember.role}>
                  {staffMember.role || "No Role"}
                </option>
                {orgData.roles.map((role, i) => (
                  <option key={i} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </Select>
            ) : (
              <Text fontSize="md">{staffMember.role || "No Role"}</Text>
            )}
          </VStack>
          <VStack>
            <Text fontSize="lg" fontWeight="semibold">
              Verification
            </Text>
            {editVerification ? (
              <Button
                colorScheme={staffMember.verified ? "green" : "red"}
                size="sm"
                onClick={handleVerificationChange}
                isDisabled={staffMember.uid === userDoc.uid} // Disable the button if it's the current user
              >
                {staffMember.verified ? "Verified" : "Unverified"}
              </Button>
            ) : (
              <Button
                colorScheme={staffMember.verified ? "green" : "red"}
                size="sm"
                isDisabled
              >
                {staffMember.verified ? "Verified" : "Unverified"}
              </Button>
            )}
          </VStack>
          {/* Permissions Icon Button */}
          <VStack>
            <Menu isOpen={permissionsDropdownOpen}>
              <MenuButton
                as={IconButton}
                icon={<FiSettings />}
                onClick={() => setPermissionsDropdownOpen(!permissionsDropdownOpen)}
                aria-label="Permissions"
              />
              <MenuList
                onClick={(e) => {
                  e.stopPropagation(); // Prevent closing the menu on click inside
                }}
              >
                {sortedPermissionsKeys.map((permission) => (
                  <MenuItem
                    key={permission}
                    onClick={() => handlePermissionChange(permission)} // Handle click on the entire row
                  >
                    <HStack width="100%">
                      <Text>{permission}</Text>
                      <Checkbox
                        isChecked={permissions[permission]}
                        pointerEvents="none" // Prevent the checkbox from intercepting clicks
                        isDisabled={!editPermissions} // Disable if editPermissions is false
                      />
                    </HStack>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </VStack>
        </HStack>
        {/* <Box mt={4}>
          <Text fontWeight="bold" mb={2}>
            Current User Permission Status:
          </Text>
          <Code whiteSpace="pre-wrap" p={1} borderRadius="md" bg="gray.100">
            Edit Verification: {editVerification ? "True" : "False"}
            {"\n"}
            Edit Department: {editDepartment ? "True" : "False"}
            {"\n"}
            Edit Permissions: {editPermissions ? "True" : "False"}
            {"\n"}
            Edit Role: {editRole ? "True" : "False"}
          </Code>
        </Box> */}
      </VStack>
      <StaffDetail/>
    </Box>
  );
};

export default StaffProfile;
