import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  VStack,
  useDisclosure,
  IconButton,
  HStack,
  Text,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { FaEdit, FaGripVertical, FaAlignLeft, FaAlignJustify, FaListUl, FaHeading, FaFont } from 'react-icons/fa';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SelectionPicker from './form/SelectionPicker';
import SingleLineEditor from './form/SingleLineEditor';
import MultiLineEditor from './form/MultiLineEditor';
import MultiSelectEditor from './form/MultiSelectEditor';
import HeadingEditor from './form/HeadingEditor';
import TextEditor from './form/TextEditor'; // Import your TextEditor

const DraggableField = ({ field, index, moveField, handleOpenModal }) => {
  const [, drag] = useDrag({
    type: 'FIELD',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'FIELD',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveField(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const getFieldIcon = (type) => {
    switch (type) {
      case 'single-line':
        return <FaAlignLeft />;
      case 'multi-line':
        return <FaAlignJustify />;
      case 'multi-choice':
        return <FaListUl />;
      case 'heading':
        return <FaHeading />;
      case 'text':
        return <FaFont />;
      default:
        return null;
    }
  };

  const getFieldTypeName = (type) => {
    switch (type) {
      case 'single-line':
        return 'Single Line Input';
      case 'multi-line':
        return 'Multi Line Input';
      case 'multi-choice':
        return 'Multi Option Select';
      case 'heading':
        return 'Heading';
      case 'text':
        return 'Text';
      default:
        return '';
    }
  };

  return (
    <HStack
      ref={(node) => drag(drop(node))}
      p={2}
      borderWidth="1px"
      borderRadius="md"
      bg="white"
      width="100%"
      mb={2}
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{ boxShadow: 'md' }}
    >
      <IconButton
        icon={<FaGripVertical />}
        variant="ghost"
        cursor="grab"
        aria-label="Drag to reorder"
      />
      <Flex align="center" flex={1}>
        {getFieldIcon(field.type)}
        <Box ml={6}>
          <Text fontSize="md" fontWeight="bold">
            {field.label || 'Untitled Field'}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {getFieldTypeName(field.type)}
          </Text>
        </Box>
      </Flex>
      <IconButton
        icon={<FaEdit />}
        onClick={() => handleOpenModal(index)}
        aria-label="Edit field"
      />
    </HStack>
  );
};

const FormBuilder = ({ fields, setFields, totalFormPoints, setTotalFormPoints, totalPassFailCount, setTotalPassFailCount }) => {
  const { isOpen: isSelectionOpen, onOpen: onSelectionOpen, onClose: onSelectionClose } = useDisclosure();
  const [editingIndex, setEditingIndex] = useState(null);

  const handleOpenModal = (index = null) => {
    if (index !== null) {
      setEditingIndex(index);
    } else {
      onSelectionOpen();
    }
  };

  const handleSaveField = (newField) => {
    if (editingIndex !== null) {
      const updatedFields = fields.map((field, index) =>
        index === editingIndex ? { ...field, ...newField } : field
      );
      setFields(updatedFields);
    } else {
      setFields([...fields, newField]);
    }
    setEditingIndex(null);
  };

  const handleDeleteField = () => {
    const updatedFields = fields.filter((_, index) => index !== editingIndex);
    setFields(updatedFields);
    setEditingIndex(null);
  };

  const moveField = (fromIndex, toIndex) => {
    const updatedFields = [...fields];
    const [movedField] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, movedField);
    setFields(updatedFields);
  };

  const renderEditor = () => {
    if (editingIndex === null) return null;
    const field = fields[editingIndex];
    const commonProps = {
      isOpen: editingIndex !== null,
      onClose: () => setEditingIndex(null),
      onSave: handleSaveField,
      onDelete: handleDeleteField,
      initialData: field,
    };

    switch (field.type) {
      case 'single-line':
        return <SingleLineEditor {...commonProps} />;
      case 'multi-line':
        return <MultiLineEditor {...commonProps} />;
      case 'multi-choice':
        return <MultiSelectEditor {...commonProps} />;
      case 'heading':
        return <HeadingEditor {...commonProps} />;
      case 'text':
        return <TextEditor {...commonProps} />; // Add the TextEditor case
      default:
        return null;
    }
  };

  const { passFailCount, totalPoints } = useMemo(() => {
    return fields.reduce(
      (acc, field) => {
        if (field.type === 'multi-choice' && field.grading === 'passfail') {
          acc.passFailCount += 1;
        } else if (field.type === 'multi-choice' && field.grading === 'points') {
          acc.totalPoints += field.totalPoints || 0;
        }
        return acc;
      },
      { passFailCount: 0, totalPoints: 0 }
    );
  }, [fields]);

  const handleTotalFormPointsChange = (value) => {
    setTotalFormPoints(Number(value));
  };

  const handleTotalPassFailCountChange = (value) => {
    setTotalPassFailCount(Number(value));
  };

  return (
    <Box>
      <DndProvider backend={HTML5Backend}>
        <VStack spacing={1} align="stretch" mb={4}>
          {fields.map((field, index) => (
            <DraggableField
              key={index}
              field={field}
              index={index}
              moveField={moveField}
              handleOpenModal={handleOpenModal}
            />
          ))}
        </VStack>
      </DndProvider>
      <Button colorScheme="blue" onClick={() => handleOpenModal()} mb={4}>
        Add Field
      </Button>

      <Box borderTop="1px" borderColor="gray.200" pt={4}>
        <Text fontWeight="bold" mb={2}>Form Summary:</Text>
        <HStack spacing={4} align="flex-start">
          <Box>
            <Text>Pass/Fail Questions: {passFailCount}</Text>
            <NumberInput
              max={passFailCount}
              min={0}
              value={totalPassFailCount}
              onChange={handleTotalPassFailCountChange}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box>
            <Text>Total Points: {totalPoints}</Text>
            <NumberInput
              max={totalPoints}
              min={0}
              value={totalFormPoints}
              onChange={handleTotalFormPointsChange}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
        </HStack>
      </Box>

      <SelectionPicker
        isOpen={isSelectionOpen}
        onClose={onSelectionClose}
        onSave={handleSaveField}
      />

      {renderEditor()}
    </Box>
  );
};

export default FormBuilder;
