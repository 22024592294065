import React, { useState } from 'react';
import {
  Box, Button, FormControl, FormLabel, Input, Heading, VStack,
  Image, Text, Link, HStack, Spinner
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, functions } from '../Firebase'; 
import { httpsCallable } from 'firebase/functions';
import logo from '../assets/whitelogo.svg';

function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Call the Firebase function to create the EvalHero user with uid
      const createEvalHeroUser = httpsCallable(functions, 'createEvalHeroUser');
      await createEvalHeroUser({
        uid: user.uid,
        firstName,
        lastName,
        email,
      });

      // Navigate to the dashboard after signup
      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg="gray.100" minH="100vh" display="flex" flexDirection={{ base: 'column', md: 'row' }}>
      {/* Left side: Signup form */}
      <Box
        w={{ base: '100%', md: '60%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={{ base: 4, md: 8 }}
      >
        <Box w="100%" maxW="500px" p={8} borderWidth={1} borderRadius={8} boxShadow="lg" bg="white">
          <Box mb={6} textAlign="center">
            <Box display="flex" justifyContent="center" mb={10}>
              <Image src={logo} alt="White Logo" w={{ base: '200px', md: '300px' }} />
            </Box>
            <Heading>Sign Up</Heading>
          </Box>
          <form onSubmit={handleSignup}>
            <VStack spacing={4}>
              <HStack spacing={4} flexDirection={{ base: 'column', md: 'row' }} w="full">
                <FormControl id="firstName" isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input 
                    type="text" 
                    placeholder="Enter your first name" 
                    value={firstName} 
                    onChange={(e) => setFirstName(e.target.value)} 
                  />
                </FormControl>
                <FormControl id="lastName" isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input 
                    type="text" 
                    placeholder="Enter your last name" 
                    value={lastName} 
                    onChange={(e) => setLastName(e.target.value)} 
                  />
                </FormControl>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input 
                  type="email" 
                  placeholder="Enter your email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input 
                  type="password" 
                  placeholder="Enter your password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                />
              </FormControl>
              {error && <Text color="red.500">{error}</Text>}
              <Button 
                type="submit" 
                colorScheme="blue" 
                size="lg" 
                width="full" 
                isDisabled={isLoading}  // Disable button when loading
              >
                {isLoading ? <Spinner size="sm" /> : 'Sign Up'}
              </Button>
            </VStack>
          </form>
          <Text mt={4} textAlign="center">
            Already have an account?{' '}
            <Link as={RouterLink} to="/login" color="blue.500">
              Login
            </Link>
          </Text>
        </Box>
      </Box>
      {/* Right side: Background color box */}
      <Box
        w={{ base: '100%', md: '40%' }}
        bg="#001c36"
        display={{ base: 'none', md: 'flex' }} // Hide on mobile
        alignItems="center"
        justifyContent="center"
      />
    </Box>
  );
}

export default Signup;
