import React, { useState } from "react";
import {
  Box,
  Text,
  Badge,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Avatar,
  // Code,
} from "@chakra-ui/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";

const HasOrg = ({ orgDoc, userDoc }) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const functions = getFunctions(getApp());

  // Extract the user's verified status from the staff array in orgDoc
  const staffMember = orgDoc.staff.find((staff) => staff.uid === userDoc.uid);
  const userVerified = staffMember ? staffMember.verified : false;

  // Handle removing organization
  const handleRemoveOrg = async () => {
    setLoading(true);
    const removeOrgFunction = httpsCallable(functions, "removeOrg");

    try {
      await removeOrgFunction({
        uid: userDoc.uid,
        orgCode: orgDoc.code,
      });
      // Refresh the page after successful removal
      window.location.reload();
    } catch (error) {
      console.error("Error removing organization:", error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Box mx="auto" maxW="500px" textAlign="center">
      {orgDoc.imageURL && (
        <Avatar
          src={orgDoc.imageURL}
          size="2xl"
          mb={4}
          mx="auto"
          name={orgDoc.name}

          bg="gray.200"
          color="#222222"
        />
      )}

      <Text fontSize="2xl" fontWeight="bold">
        Welcome to {orgDoc.name}
      </Text>

      <Text mt={2}>
        You are currently{" "}
        {userVerified ? (
          <Badge colorScheme="green">Verified</Badge>
        ) : (
          <>
            <Badge colorScheme="red">Not Verified</Badge>
            <Text as="span" color="gray.500" ml={2} fontSize="sm">
              <br />(Please contact a director or admin to verify your account)
            </Text>
          </>
        )}
      </Text>

      {/* <Text fontWeight="bold" mt={4}>
        Organization Data (Raw JSON):
      </Text>
      <Code
        display="block"
        whiteSpace="pre-wrap"
        p={2}
        borderRadius="md"
        bg="gray.200"
        mb={4}
      >
        {JSON.stringify(orgDoc, null, 2)}
      </Code>

      <Text fontWeight="bold" mt={4}>
        User Data (Raw JSON):
      </Text>
      <Code
        display="block"
        whiteSpace="pre-wrap"
        p={2}
        borderRadius="md"
        bg="gray.200"
        mb={4}
      >
        {JSON.stringify(userDoc, null, 2)}
      </Code> */}

      <Button colorScheme="red" mt={4} size="xs" onClick={onOpen}>
        Remove Org
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove yourself from the organization{" "}
            <Text as="span" fontWeight="bold">
              {orgDoc.name}
            </Text>
            ?
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleRemoveOrg}
              isLoading={loading}
              ml={3}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default HasOrg;
