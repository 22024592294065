import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  Button,
  VStack,
  HStack,
  Avatar,
  IconButton,
  Image,
  Spinner,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, auth } from '../../../Firebase';
import { signOut } from 'firebase/auth';
import { BsUpload } from 'react-icons/bs';

const Profile = () => {
  const { userDoc, orgData } = useOutletContext();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState('None');
  const [selectedDepartment, setSelectedDepartment] = useState('None');
  const functions = getFunctions();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (userDoc) {
      setFirstName(userDoc.firstName || '');
      setLastName(userDoc.lastName || '');
      setEmail(userDoc.email || '');
      setProfilePictureURL(userDoc.profilePictureURL || '');
    }
  }, [userDoc]);

  useEffect(() => {
    if (orgData) {
      // Find the user's staff record in the orgData
      const staffMember = orgData.staff.find(staff => staff.uid === userDoc?.uid);
      if (staffMember) {
        setSelectedRole(staffMember.role || 'None');
        setSelectedDepartment(staffMember.department || 'None');
      }
    }
  }, [orgData, userDoc]);

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
    setProfilePicturePreview(URL.createObjectURL(file));
    setIsChanged(true);
  };

  const handleUpdate = async () => {
    onClose(); // Close the confirmation modal

    if (userDoc && userDoc.uid) {
      setIsLoading(true);
      let downloadURL = profilePictureURL;

      if (profilePicture) {
        const storageRef = ref(storage, `profilePictures/${userDoc.uid}`);
        await uploadBytes(storageRef, profilePicture);
        downloadURL = await getDownloadURL(storageRef);
      }

      const updateUserProfile = httpsCallable(functions, 'userProfileUpdating');
      try {
        const updates = {
          uid: userDoc.uid,
          firstName,
          lastName,
          profilePictureURL: downloadURL,
        };

        // Only include role and department if they have changed
        if (selectedRole !== userDoc.role) {
          updates.role = selectedRole === 'None' ? '' : selectedRole;
        }
        if (selectedDepartment !== userDoc.department) {
          updates.department = selectedDepartment === 'None' ? '' : selectedDepartment;
        }

        const response = await updateUserProfile(updates);
        console.log('Firebase Function Response:', response.data);
        setIsChanged(false);
        window.location.reload();
      } catch (error) {
        console.error('Error calling Firebase Function:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSave = () => {
    // Check if department or role has changed
    if (
      selectedRole !== (userDoc.role || 'None') ||
      selectedDepartment !== (userDoc.department || 'None')
    ) {
      // Open the confirmation modal
      onOpen();
    } else {
      handleUpdate();
    }
  };

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
    setIsChanged(true);
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <Box maxW="500px" mx="auto">
      <VStack spacing={4}>
        <FormControl id="profilePicture">
          <FormLabel>Profile Picture</FormLabel>
          <Box display="flex" alignItems="center" gap={4}>
            <IconButton
              aria-label="Upload Profile Picture"
              icon={<BsUpload />}
              onClick={() => document.getElementById('profilePictureInput').click()}
            />
            {profilePicturePreview ? (
              <Image
                src={profilePicturePreview}
                alt="Profile Picture Preview"
                boxSize="100px"
                objectFit="cover"
                borderRadius="full"
                bg="gray.200"
              />
            ) : (
              <Avatar
                src={profilePictureURL}
                size="xl"
                name={`${firstName} ${lastName}`}
                bg="gray.200"
                color="#222222"
              />
            )}
          </Box>
          <Input
            id="profilePictureInput"
            name="profilePicture"
            type="file"
            accept="image/*"
            onChange={handleProfilePictureChange}
            style={{ display: 'none' }}
          />
        </FormControl>

        <HStack spacing={4} width="100%">
          <FormControl id="firstName">
            <FormLabel>First Name</FormLabel>
            <Input
              value={firstName}
              onChange={handleChange(setFirstName)}
            />
          </FormControl>

          <FormControl id="lastName">
            <FormLabel>Last Name</FormLabel>
            <Input
              value={lastName}
              onChange={handleChange(setLastName)}
            />
          </FormControl>
        </HStack>

        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input
            value={email}
            isReadOnly
            bg="gray.100"
            cursor="not-allowed"
          />
        </FormControl>

        {orgData && orgData.departments && (
          <FormControl id="department">
            <FormLabel>Department</FormLabel>
            <Select value={selectedDepartment} onChange={handleChange(setSelectedDepartment)}>
              <option value="None">None</option>
              {orgData.departments.map((dept, idx) => (
                <option key={idx} value={dept}>
                  {dept}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        {orgData && orgData.roles && (
          <FormControl id="role">
            <FormLabel>Role</FormLabel>
            <Select value={selectedRole} onChange={handleChange(setSelectedRole)}>
              <option value="None">None</option>
              {orgData.roles.map((role, idx) => (
                <option key={idx} value={role.name}>
                  {role.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        <Button
          colorScheme="blue"
          w="full"
          onClick={handleSave}
          isDisabled={!isChanged || isLoading}
        >
          {isLoading ? <Spinner size="sm" /> : 'Update'}
        </Button>

        <Box width="100%" textAlign="left">
          <Button colorScheme="red" size="sm" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </VStack>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Changes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Changing your department or role will unverify your account. Please contact an admin to verify your account again. Are you sure you want to proceed?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleUpdate} ml={3}>
              Yes, I'm Sure
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Profile;
