import React, { useState } from 'react';
import {
  Box, Button, FormControl, FormLabel, Input, Heading, VStack,
  Image, Text, Link, HStack
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../Firebase'; // Assuming Firebase is initialized in this file
import logo from '../assets/whitelogo.svg';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state
    try {
      // Sign in the user with email and password
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard'); // Redirect to dashboard on successful login
    } catch (error) {
      setError(error.message); // Display error message
    }
  };

  return (
    <Box bg="gray.100" minH="100vh" display="flex" flexDirection={{ base: 'column', md: 'row' }}>
      {/* Left side: Login form */}
      <Box
        w={{ base: '100%', md: '60%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={{ base: 4, md: 8 }}
      >
        <Box w="100%" maxW="500px" p={8} borderWidth={1} borderRadius={8} boxShadow="lg" bg="white">
          <Box mb={6} textAlign="center">
            <Box display="flex" justifyContent="center" mb={10}>
              <Image src={logo} alt="White Logo" w={{ base: '200px', md: '300px' }} />
            </Box>
            <Heading>Login</Heading>
          </Box>
          <form onSubmit={handleLogin}>
            <VStack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <HStack justifyContent="space-between">
                  <FormLabel>Password</FormLabel>
                  <Link as={RouterLink} to="/reset" color="blue.500">
                    Reset Password
                  </Link>
                </HStack>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              {error && <Text color="red.500">{error}</Text>}
              <Button type="submit" colorScheme="blue" size="lg" width="full">
                Login
              </Button>
            </VStack>
          </form>
          <Text mt={4} textAlign="center">
            Don't have an account?{' '}
            <Link as={RouterLink} to="/signup" color="blue.500">
              Signup
            </Link>
          </Text>
        </Box>
      </Box>
      {/* Right side: Background color box */}
      <Box
        w="40%"
        bg="#001c36"
        display={{ base: 'none', md: 'flex' }} // Hide on mobile
        alignItems="center"
        justifyContent="center"
      />
    </Box>
  );
}

export default Login;
