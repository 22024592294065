import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { IconButton, Tooltip, Flex } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import fontData from '../../../fonts/Reddit_Sans/static/RedditSans-Medium.ttf';
import boldFontData from '../../../fonts/Reddit_Sans/static/RedditSans-Bold.ttf'; // Import the bold font
import logo from '../../../assets/whitelogo.png';

const AllReportsPDF = ({ reports, formatDate }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add custom fonts
    doc.addFont(fontData, 'RedditSans', 'normal');
    doc.addFont(boldFontData, 'RedditSansBold', 'bold'); // Add the bold font

    // Add logo with fixed dimensions
    const logoWidth = 40;
    const logoHeight = 6;
    const logoY = 10;
    doc.addImage(logo, 'PNG', 12, logoY, logoWidth, logoHeight);

    // Position title and subtitle directly below the image
    const titleY = logoY + logoHeight + 16; // Adjust the vertical gap as needed

    // Set font style to bold for the title
    doc.setFont('RedditSansBold', 'bold');
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185); // Blue color
    doc.text('All Reports Summary', 10, titleY);

    // Calculate totals
    let totalPoints = 0;
    let earnedPoints = 0;
    let totalPassFail = 0;
    let passedCount = 0;
    let totalReports = reports.length;

    reports.forEach(report => {
      totalPoints += report.stats.totalPoints || 0;
      earnedPoints += report.stats.earnedPoints || 0;
      totalPassFail += report.stats.totalPassFail || 0;
      passedCount += report.stats.passedCount || 0;
    });

    // Overall Results
    const overallResult = (earnedPoints >= totalPoints && passedCount >= totalPassFail) ? 'PASS' : 'FAIL';

    // Move the information box up
    const infoBoxY = 40; // Adjusted to move up
    doc.setDrawColor(200);
    doc.setFillColor(240);
    doc.roundedRect(10, infoBoxY, 190, 40, 3, 3, 'FD');

    doc.setFontSize(10);
    doc.setTextColor(60);
    doc.text(`Total Reports: ${totalReports}`, 15, infoBoxY + 10);
    doc.text(`Total Points: ${earnedPoints} / ${totalPoints}`, 15, infoBoxY + 20);
    doc.text(`Pass/Fail Count: ${passedCount} / ${totalPassFail}`, 15, infoBoxY + 30);

    // Overall Result below the information box
    const resultY = infoBoxY + 55; // Position below the info box
    doc.setFontSize(14);
    doc.setTextColor(overallResult === 'PASS' ? 46 : 231, overallResult === 'PASS' ? 204 : 76, overallResult === 'PASS' ? 113 : 60);
    doc.text(`Overall Result: ${overallResult}`, 15, resultY);

    // Table of reports
    const tableData = reports.map(report => [
      report.evaluationName || '',
      formatDate(report.timeSubmitted) || 'N/A',
      report.stats.earnedPoints || 0,
      report.stats.totalPoints || 0,
      report.stats.passedCount || 0,
      report.stats.totalPassFail || 0
    ]);

    doc.autoTable({
      startY: resultY + 10, // Adjust startY to align below overall result
      head: [['Evaluation Name', 'Submitted At', 'Earned Points', 'Total Points', 'Pass/Fail Count', 'Total Pass/Fail']],
      body: tableData,
      theme: 'striped',
      headStyles: { fillColor: [41, 128, 185], textColor: 255 },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      styles: { font: 'RedditSans' },
      didDrawPage: (data) => {
        // Footer for each page
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.setTextColor(150);
        doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
      },
    });

    const fileName = `All_Reports_Summary_${new Date().toISOString().split('T')[0].replace(/-/g, '_')}.pdf`;
    doc.save(fileName);
  };

  return (
    <Flex alignItems="center">
      <Tooltip label="Download All Reports Summary" aria-label="Download All Reports Summary">
        <IconButton
          colorScheme="blue"
          icon={<DownloadIcon />}
          onClick={generatePDF}
        />
      </Tooltip>
    </Flex>
  );
};

export default AllReportsPDF;
