import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { IconButton, Tooltip, Image, Flex } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { useOutletContext } from 'react-router-dom';
import fontData from '../../../fonts/Reddit_Sans/static/RedditSans-Medium.ttf';
import boldFontData from '../../../fonts/Reddit_Sans/static/RedditSans-Bold.ttf'; // Import the bold font
import logo from '../../../assets/whitelogo.png';

const ReportPDF = ({ report, formatDate }) => {
  const orgData = useOutletContext(); // Get orgData from the outlet context
  console.log('orgData:', orgData); // Log orgData to check its value

  const generatePDF = () => {
    console.log('Generating PDF...'); // Indicate the start of the PDF generation process
    const doc = new jsPDF();

    // Add custom fonts
    console.log('Adding custom fonts...');
    doc.addFont(fontData, 'RedditSans', 'normal');
    doc.addFont(boldFontData, 'RedditSansBold', 'bold'); // Add the bold font

    // Add logo with fixed dimensions
    console.log('Adding logo...');
    const logoWidth = 40;
    const logoHeight = 6;
    const logoY = 10;
    doc.addImage(logo, 'PNG', 12, logoY, logoWidth, logoHeight);

    // Position title and subtitle directly below the image
    const titleY = logoY + logoHeight + 16; // Adjust the vertical gap as needed

    // Set font style to bold for the title
    console.log('Adding title and subtitle...');
    doc.setFont('RedditSansBold', 'bold');
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185); // Blue color
    doc.text(report.evaluationName || 'Evaluation Report', 10, titleY);

    // Reset font to normal for the subtitle
    const subtitleY = titleY + 10; // Adjust the vertical gap as needed
    doc.setFont('RedditSans', 'normal');
    doc.setFontSize(14);
    doc.setTextColor(100);
    doc.text(report.evaluationDescription || '', 10, subtitleY, { maxWidth: 130 });

    // Information box
    console.log('Adding information box...');
    doc.setDrawColor(200);
    doc.setFillColor(240);
    doc.roundedRect(10, 50, 190, 40, 3, 3, 'FD');

    doc.setFontSize(10);
    doc.setTextColor(60);
    const infoY = 60;
    doc.text(`Submitted At: ${formatDate(report.timeSubmitted) || 'N/A'}`, 15, infoY); // Moved date to the top left
    doc.text(`Status: ${report.status || 'N/A'}`, 15, infoY + 10); // Moved status below date
    doc.text(`Verified On: ${report.verifiedOn ? formatDate(report.verifiedOn) : 'N/A'}`, 15, infoY + 20); // Moved verified to left side
    doc.text(`Submitted By: ${report.submittedBy?.name || 'N/A'}`, 110, infoY); // Kept submitted by on the right side
    doc.text(`Submitted For: ${report.submittedFor?.name || 'N/A'}`, 110, infoY + 10); // Kept submitted for on the right side

    // Overall Results - Align with other text
    console.log('Adding overall results...');
    doc.setFont('RedditSansBold', 'bold');
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text('Overall Results', 10, 105); // Align with other text

    doc.setFontSize(12);
    doc.setTextColor(60);
    doc.text(`Total Points: ${report.earnedPoints || 0} / ${report.totalFormPoints || 0}`, 10, 115); // Align with other text
    doc.text(`Pass/Fail Count: ${report.passedCount || 0} / ${report.totalPassFailCount || 0}`, 10, 125); // Align with other text

    const overallResult = (report.earnedPoints >= report.totalFormPoints && report.passedCount >= report.totalPassFailCount) ? 'PASS' : 'FAIL';
    console.log('Overall Result:', overallResult); // Log the calculated overall result
    doc.setFontSize(14);
    doc.setTextColor(overallResult === 'PASS' ? 46 : 231, overallResult === 'PASS' ? 204 : 76, overallResult === 'PASS' ? 113 : 60);
    doc.text(`Overall Result: ${overallResult}`, 10, 135); // Align with other text

    // Form Responses - Add this text only once
    console.log('Adding form responses...');
    doc.setFont('RedditSansBold', 'bold');
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text('Form Responses', 10, 155);

    const tableData = (report.formResponses || []).map(response => [
      response.label || '',
      Array.isArray(response.answer) ? response.answer.join(', ') : (response.answer || ''),
      response.answerDetails ? (response.answerDetails.result || `${response.answerDetails.points || 0}/${response.answerDetails.maxPoints || 0}`) : ''
    ]);

    console.log('Table data:', tableData); // Log the table data to verify its structure

    doc.autoTable({
      startY: 160,
      head: [['Question', 'Answer', 'Result']],
      body: tableData,
      theme: 'striped',
      headStyles: { fillColor: [41, 128, 185], textColor: 255 },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      styles: { font: 'RedditSans' },
      didDrawPage: (data) => {
        // Footer for each page
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.setTextColor(150);
        doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
      },
    });

    const fileName = `${(report.evaluationName || 'Report').replace(/\s+/g, '_')}_${formatDate(report.timeSubmitted || new Date()).replace(/[/, :]/g, '_')}.pdf`;
    console.log('Saving PDF with filename:', fileName); // Log the filename before saving
    doc.save(fileName);
  };

  return (
    <Flex alignItems="center">
      {/* Display the image to the left of the IconButton */}
      {orgData?.imageURL && (
        <>
          <Image src={orgData.imageURL} alt="Organization Logo" boxSize="40px" mr={3} />
          {console.log('Displaying organization logo:', orgData.imageURL)} {/* Log the logo URL */}
        </>
      )}
      <Tooltip label="Download Report" aria-label="Download Report">
        <IconButton
          colorScheme="blue"
          icon={<DownloadIcon />}
          onClick={generatePDF}
        />
      </Tooltip>
    </Flex>
  );
};

export default ReportPDF;
