import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Grid,
  GridItem,
  FormControl,
  Input,
  Text,
  Button,
  Icon,
  Tag,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { db } from "../../../Firebase";
import {
  doc,
  collection,
  onSnapshot,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { useNavigate, useOutletContext } from "react-router-dom";
import * as Icons from "react-icons/fa";

const Evaluations = () => {
  const { userDoc, orgData } = useOutletContext();
  const [evaluations, setEvaluations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const navigate = useNavigate();

  // Find the staff member with matching UID and their permissions
  const currentUserStaffData = orgData?.staff?.find(
    (staff) => staff.uid === userDoc.uid
  );
  const editEvaluationValue =
    currentUserStaffData?.permissions?.["Edit Evaluation"] || false;
  const makeEvaluationValue =
    currentUserStaffData?.permissions?.["Make Evaluation"] || false;
  const createNewEvaluationValue =
    currentUserStaffData?.permissions?.["Create New Evaluation"] || false;

  useEffect(() => {
    if (userDoc && userDoc.orgId) {
      fetchEvaluations(userDoc.uid);
    } else {
      setEvaluations([]);
    }
  }, [userDoc]);

  const fetchEvaluations = async (userId) => {
    let unsubscribe;

    try {
      const userDocSnapshot = await getDoc(doc(db, "users", userId));
      const userData = userDocSnapshot.data();
      const orgId = userData.orgId;

      if (orgId) {
        const evaluationsCollection = collection(
          db,
          `org/${orgId}/evaluations`
        );
        unsubscribe = onSnapshot(evaluationsCollection, (snapshot) => {
          const evaluationsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEvaluations(evaluationsData);
        });
      }
    } catch (error) {
      console.error("Error fetching evaluations:", error);
    }

    return unsubscribe;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  const filteredEvaluations = evaluations.filter(
    (evaluation) =>
      (evaluation.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (evaluation.tags &&
          evaluation.tags.some((tag) =>
            tag.toLowerCase().includes(searchTerm.toLowerCase())
          ))) &&
      (selectedTags.length === 0 ||
        (evaluation.tags &&
          selectedTags.every((tag) => evaluation.tags.includes(tag))))
  );

  const createNewEvaluation = async () => {
    if (!createNewEvaluationValue) return;

    try {
      const orgId = userDoc.orgId;
      const newEvaluationRef = await addDoc(
        collection(db, `org/${orgId}/evaluations`),
        {
          name: "",
          description: "",
          tags: [],
          icon: "FaClipboardList", // Default icon
        }
      );
      navigate(`/dashboard/evaluations/${newEvaluationRef.id}/edit`);
    } catch (error) {
      console.error("Error creating new evaluation:", error);
    }
  };

  const navigateToEditEvaluation = (evaluationId) => {
    if (editEvaluationValue) {
      navigate(`/dashboard/evaluations/${evaluationId}/edit`);
    }
  };

  const navigateToEvaluationForm = (evaluationId) => {
    if (makeEvaluationValue) {
      navigate(`/dashboard/evaluations/${evaluationId}`);
    }
  };

  const uniqueTags = Array.from(
    new Set(evaluations.flatMap((evaluation) => evaluation.tags || []))
  );

  return (
    <Box>
      {userDoc ? (
        <>
          <FormControl mb={4}>
            <Input
              id="search"
              name="search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search by evaluation name or tags"
            />
          </FormControl>
          <Wrap spacing={4} mb={4}>
            {uniqueTags.map((tag) => (
              <WrapItem key={tag}>
                <Button
                  onClick={() => handleTagClick(tag)}
                  colorScheme={selectedTags.includes(tag) ? "blue" : "gray"}
                >
                  {tag}
                </Button>
              </WrapItem>
            ))}
          </Wrap>
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
            gap={6}
          >
            {filteredEvaluations.map((evaluation) => {
              const IconComponent =
                Icons[evaluation.icon] || Icons.FaQuestionCircle;
              return (
                <GridItem
                  key={evaluation.id}
                  bg="gray.200"
                  p={6}
                  borderRadius="md"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                  boxShadow="md"
                  minHeight="200px"
                  cursor={makeEvaluationValue ? "pointer" : "default"}
                  onClick={() =>
                    makeEvaluationValue &&
                    navigateToEvaluationForm(evaluation.id)
                  }
                  position="relative"
                >
                  <Box textAlign="center">
                    <Icon as={IconComponent} boxSize={12} mb={4} />
                    <Heading fontSize="xl" mb={2}>
                      {evaluation.name}
                    </Heading>
                    <Text>{evaluation.description}</Text>
                  </Box>
                  <Box position="absolute" bottom={2} left={2}>
                    {evaluation.tags &&
                      evaluation.tags.map((tag, index) => (
                        <Tag mr={2} key={index} bottom={2} bg="gray.300">
                          {tag}
                        </Tag>
                      ))}
                  </Box>
                  {editEvaluationValue && (
                    <Button
                      mt={4}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToEditEvaluation(evaluation.id);
                      }}
                      position="absolute"
                      bottom={2}
                      right={2}
                      zIndex={9999}
                      variant="link"
                    >
                      Edit
                    </Button>
                  )}
                </GridItem>
              );
            })}
            {createNewEvaluationValue && (
              <GridItem
                bg="gray.300"
                p={4}
                borderRadius="md"
                cursor="pointer"
                onClick={createNewEvaluation}
                boxShadow="md"
                minHeight="200px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text fontWeight="bold">New Evaluation</Text>
              </GridItem>
            )}
          </Grid>
        </>
      ) : (
        <Text>Loading...</Text>
      )}
    </Box>
  );
};

export default Evaluations;
