import React, { useState, useEffect, useCallback, useMemo } from "react";
import { doc, getDoc, collection, query, where, getDocs, orderBy } from "firebase/firestore";
import {
  Box, Heading, Text, Spinner, Badge,
  Flex, VStack, HStack, Wrap, WrapItem, Button, Input, FormControl,
  Stat, StatLabel, StatNumber, StatGroup, Tag, Progress
} from "@chakra-ui/react";
import { db } from "../../../Firebase";
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import DateRangeSelector from '../../utils/DateRangeSelector';
import StaffReportsPDF from './StaffReportsPDF'; // Import the new PDF component

const StaffDetail = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [staffMember, setStaffMember] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  // Use useOutletContext to get orgData from parent component
  const { orgData } = useOutletContext();

  // Extract orgId from orgData
  const orgId = orgData ? orgData.code : null;

  // Extract department and role from orgData for the staff member
  const staffDetails = orgData?.staff.find(member => member.uid === uid);
  const department = staffDetails ? staffDetails.department : 'N/A';
  const role = staffDetails ? staffDetails.role : 'N/A';

  const fetchStaffMemberAndReports = useCallback(async () => {
    if (!orgId) {
      console.log("Organization ID not found");
      setIsLoading(false);
      return;
    }

    console.log("Fetching staff member details for user ID:", uid);
    console.log("Using organization ID:", orgId);

    try {
      // Fetch staff member details
      const staffDocRef = doc(db, "users", uid);
      const staffDocSnapshot = await getDoc(staffDocRef);

      if (staffDocSnapshot.exists()) {
        console.log("Staff member found:", staffDocSnapshot.data());
        setStaffMember(staffDocSnapshot.data());
      } else {
        console.error("Staff member not found for user ID:", uid);
        setIsLoading(false);
        return;
      }

      // Fetch reports for the staff member
      const reportsRef = collection(db, 'org', orgId, 'reports');
      let q = query(
        reportsRef,
        where('submittedFor.id', '==', uid),
        orderBy('timeSubmitted', 'desc')
      );

      if (startDate && endDate) {
        console.log("Applying date range filter:", { startDate, endDate });
        q = query(q, where('timeSubmitted', '>=', startDate), where('timeSubmitted', '<=', endDate));
      }

      const querySnapshot = await getDocs(q);
      console.log("Fetched reports:", querySnapshot.docs.length);

      const fetchedReports = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          timeSubmitted: data.timeSubmitted instanceof Date
            ? data.timeSubmitted
            : new Date(data.timeSubmitted),
          stats: calculateReportStats(data)
        };
      });

      setReports(fetchedReports);
    } catch (error) {
      console.error("Error fetching staff member details and reports:", error);
    } finally {
      setIsLoading(false);
    }
  }, [uid, orgId, startDate, endDate]);

  useEffect(() => {
    fetchStaffMemberAndReports();
  }, [fetchStaffMemberAndReports]);

  const handleDateRangeChange = (start, end) => {
    console.log("Date range changed:", { start, end });
    setStartDate(start);
    setEndDate(end);
  };

  const handleSearchChange = (e) => {
    console.log("Search term changed:", e.target.value);
    setSearchTerm(e.target.value);
  };

  const handleTagClick = (tag) => {
    console.log("Tag clicked:", tag);
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
  };

  const handleViewDetails = (reportId) => {
    console.log("Navigating to report details for report ID:", reportId);
    navigate(`/dashboard/reports/${reportId}`);
  };

  const calculateReportStats = (report) => {
    let totalPoints = 0;
    let earnedPoints = 0;
    let totalPassFail = 0;
    let passedCount = 0;

    report.formResponses.forEach(response => {
      if (response.answerDetails) {
        if (typeof response.answerDetails === 'object') {
          if ('points' in response.answerDetails) {
            totalPoints += response.answerDetails.maxPoints || 0;
            earnedPoints += response.answerDetails.points || 0;
          }
          if ('result' in response.answerDetails) {
            totalPassFail++;
            if (response.answerDetails.result === 'pass') {
              passedCount++;
            }
          }
        }
      }
    });

    const pointsPercentage = totalPoints > 0 ? (earnedPoints / totalPoints) * 100 : 100;
    const allPassFailPassed = totalPassFail === passedCount;
    const overallPass = allPassFailPassed && pointsPercentage >= 70;

    return {
      totalPoints,
      earnedPoints,
      totalPassFail,
      passedCount,
      overallPass
    };
  };

  const filteredReports = reports.filter((report) =>
    (report.evaluationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (report.evaluationTags && report.evaluationTags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())))) &&
    (selectedTags.length === 0 || (report.evaluationTags && selectedTags.every((tag) => report.evaluationTags.includes(tag))))
  );

  const uniqueTags = Array.from(new Set(reports.flatMap((report) => report.evaluationTags || [])));

  const reportStats = useMemo(() => {
    const totalReports = filteredReports.length;
    const passedReports = filteredReports.filter(report => report.stats.overallPass).length;
    const failedReports = totalReports - passedReports;
    const passPercentage = totalReports > 0 ? (passedReports / totalReports) * 100 : 0;

    let totalPoints = 0;
    let earnedPoints = 0;
    let totalPassFail = 0;
    let passedCount = 0;

    filteredReports.forEach(report => {
      totalPoints += report.stats.totalPoints;
      earnedPoints += report.stats.earnedPoints;
      totalPassFail += report.stats.totalPassFail;
      passedCount += report.stats.passedCount;
    });

    const averageScore = totalPoints > 0 ? (earnedPoints / totalPoints) * 100 : 0;
    const passFailRatio = totalPassFail > 0 ? (passedCount / totalPassFail) * 100 : 0;

    return {
      totalReports,
      passedReports,
      failedReports,
      passPercentage,
      averageScore,
      passFailRatio
    };
  }, [filteredReports]);

  // Function to format dates (you may need to adjust based on your date handling)
  const formatDate = (date) => {
    return date instanceof Date ? date.toLocaleDateString() : new Date(date).toLocaleDateString();
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!staffMember) {
    return <Text fontSize="xl" textAlign="center" mt={8}>Staff member not found.</Text>;
  }

  return (
    <Box>
      <Box mt={10} mb={6}>
        <Heading as="h3" size="lg" mb={4}>Report Statistics</Heading>
        <Box p={4} borderWidth={1} borderRadius="md" bg="white">
          <StatGroup>
            <Stat>
              <StatLabel>Total Reports</StatLabel>
              <StatNumber>{reportStats.totalReports}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Passed Reports</StatLabel>
              <StatNumber>{reportStats.passedReports}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Failed Reports</StatLabel>
              <StatNumber>{reportStats.failedReports}</StatNumber>
            </Stat>
          </StatGroup>
          <Text mt={4} mb={2}>Overall Pass Percentage</Text>
          <Progress value={reportStats.passPercentage} colorScheme="green" mb={4} />
          <StatGroup>
            <Stat>
              <StatLabel>Average Score</StatLabel>
              <StatNumber>{reportStats.averageScore.toFixed(2)}%</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Pass/Fail Question Ratio</StatLabel>
              <StatNumber>{reportStats.passFailRatio.toFixed(2)}%</StatNumber>
            </Stat>
          </StatGroup>
        </Box>
      </Box>

      <Heading as="h3" size="lg" mb={4}>Reports for {staffMember.firstName} {staffMember.lastName}</Heading>
      <HStack spacing={4} mb={4}>
        <FormControl>
          <Input
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search by evaluation name or tags"
          />
        </FormControl>
        <StaffReportsPDF 
          staffMember={staffMember} 
          reports={filteredReports} 
          reportStats={reportStats} 
          formatDate={formatDate}
          startDate={startDate}
          endDate={endDate}
          department={department}
          role={role}
        />
        <DateRangeSelector onDateRangeChange={handleDateRangeChange} />
      </HStack>

      <Box>
        <Wrap spacing={4} mb={4}>
          {uniqueTags.map((tag) => (
            <WrapItem key={tag}>
              <Button
                onClick={() => handleTagClick(tag)}
                colorScheme={selectedTags.includes(tag) ? 'blue' : 'gray'}
              >
                {tag}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
        {filteredReports.length === 0 ? (
          <Text>No reports found for the selected criteria.</Text>
        ) : (
          <VStack spacing={4} align="stretch">
            {filteredReports.map((report) => (
              <Box key={report.id} p={4} borderWidth={1} borderRadius="md">
                <Heading size="md">{report.evaluationName}</Heading>
                {report.evaluationTags && (
                  <Wrap spacing={2} py={1}>
                    {report.evaluationTags.map((tag, index) => (
                      <WrapItem key={index}>
                        <Tag>{tag}</Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                )}
                <HStack>
                  <Text>Submitted At: {report.timeSubmitted instanceof Date 
                    ? report.timeSubmitted.toLocaleDateString()
                    : new Date(report.timeSubmitted).toLocaleDateString()}</Text>
                  <Badge colorScheme={report.status === 'verified' ? 'green' : 'orange'}>
                    Status: {report.status || 'Pending'}
                  </Badge>
                  <Badge colorScheme={report.stats.overallPass ? 'green' : 'red'}>
                    Overall: {report.stats.overallPass ? 'PASS' : 'FAIL'}
                  </Badge>
                </HStack>
                <Text>Submitted By: {report.submittedBy.name}</Text>
                <Text>Submitted For: {report.submittedFor.name}</Text>
                
                <StatGroup mt={2}>
                  <Stat>
                    <StatLabel>Points</StatLabel>
                    <StatNumber>{report.stats.earnedPoints} / {report.stats.totalPoints}</StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel>Pass/Fail Questions</StatLabel>
                    <StatNumber>{report.stats.passedCount} / {report.stats.totalPassFail}</StatNumber>
                  </Stat>
                </StatGroup>
                
                <Button 
                  size="sm" 
                  colorScheme="blue" 
                  onClick={() => handleViewDetails(report.id)}
                  mt={2}
                >
                  View Details
                </Button>
              </Box>
            ))}
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default StaffDetail;
