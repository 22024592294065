import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from '@chakra-ui/react';

const HeadingEditor = ({ isOpen, onClose, onSave, onDelete, initialData }) => {
  const [label, setLabel] = useState(initialData?.label || '');

  const handleSave = () => {
    onSave({ label, type: 'heading' });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Heading</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Enter heading text"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave} mr={3}>
            Save
          </Button>
          <Button variant="ghost" onClick={onDelete}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default HeadingEditor;
