import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, FormControl, FormLabel, Input, Heading, Alert,
  AlertIcon, AlertTitle, AlertDescription, VStack, Image, Text, Link,
  useToast
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../Firebase';
import logo from '../assets/whitelogo.svg';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  const handleReset = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        title: 'Password Reset Email Sent',
        description: 'Please check your email for instructions to reset your password.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      navigate('/login');
    } catch (error) {
      setError(error.message);
      toast({
        title: 'Reset Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  return (
    <Box bg="gray.100" minH="100vh" display="flex" flexDirection={{ base: 'column', md: 'row' }}>
      {/* Left side: Password reset form */}
      <Box
        w={{ base: '100%', md: '60%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={{ base: 4, md: 8 }}
      >
        <Box w="100%" maxW="500px" p={8} borderWidth={1} borderRadius={8} boxShadow="lg" bg="white">
          <Box mb={6} textAlign="center">
            <Box display="flex" justifyContent="center" mb={10}>
              <Image src={logo} alt="White Logo" w={{ base: '200px', md: '300px' }} />
            </Box>
            <Heading>Password Reset</Heading>
          </Box>
          {error && (
            <Alert status="error" mb={4} borderRadius={4}>
              <AlertIcon />
              <Box>
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Box>
            </Alert>
          )}
          <form onSubmit={handleReset}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" size="lg" width="full">
                Send Password Reset
              </Button>
            </VStack>
          </form>
          <Text mt={4} textAlign="center">
            Remember your password?{' '}
            <Link as={RouterLink} to="/login" color="blue.500">
              Login
            </Link>
          </Text>
        </Box>
      </Box>
      {/* Right side: Background color box */}
      <Box
        w={{ base: '100%', md: '40%' }}
        bg="#001c36"
        display={{ base: 'none', md: 'flex' }} // Hide on mobile
        alignItems="center"
        justifyContent="center"
      />
    </Box>
  );
}

export default PasswordReset;
