// Firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDGFErUyvWD7MbnbEDJpVx03XeKpgZbfv0",
  authDomain: "evalhero-v3.firebaseapp.com",
  projectId: "evalhero-v3",
  storageBucket: "evalhero-v3.appspot.com",
  messagingSenderId: "38711706466",
  appId: "1:38711706466:web:22f0d20b6e58318f0be23b",
  measurementId: "G-4W1S2HP4Z3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { app, auth, db, storage, functions };
