import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  SimpleGrid,
  GridItem,
  Image,
  Text,
  Button,
  IconButton,
  useDisclosure,
  HStack,
  VStack,
//   Code,
} from '@chakra-ui/react';
import axios from 'axios';
import { useOutletContext, useNavigate } from 'react-router-dom';
import AddFileModal from './AddFileModal';
import FileInformationModal from './FileInformationModal';
import { getStorage, ref, deleteObject } from "firebase/storage";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../../Firebase";
import { FiSettings } from 'react-icons/fi';
import TempImage from '../../../assets/temp.png';

const Protocols = () => {
  const { orgData, userDoc } = useOutletContext();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isFileOpen, onOpen: onFileOpen, onClose: onFileClose } = useDisclosure();
  const storage = getStorage();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const API_KEY = "sk-proj-Tp6FFkSvCegPaNHP53TBT3BlbkFJEonSWHHvtVsuJI4IuaDY"; 
  const BASE_URL = "https://api.openai.com/v1";

  const fetchFiles = useCallback(async () => {
    if (!orgData?.vectorStoreId || !orgData?.code) return;

    try {
      const response = await axios.get(
        `${BASE_URL}/vector_stores/${orgData.vectorStoreId}/files`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );

      const filesData = await Promise.all(response.data.data.map(async (file) => {
        const fileDocRef = doc(db, "org", orgData.code, "protocols", file.id);
        const fileDoc = await getDoc(fileDocRef);
        if (fileDoc.exists()) {
          return {
            id: file.id,
            openAiData: file, // OpenAI data
            firestoreData: fileDoc.data() // Firestore data
          };
        } else {
          return {
            id: file.id,
            openAiData: file,
            firestoreData: null,
          };
        }
      }));

      setFiles(filesData.filter(file => file !== null));
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  }, [API_KEY, orgData?.vectorStoreId, orgData?.code]);

  const deleteFile = async (fileId) => {
    if (!orgData?.vectorStoreId || !orgData?.code) return;

    setLoading(true);
    try {
      // Delete from OpenAI Vector Store
      await axios.delete(
        `${BASE_URL}/vector_stores/${orgData.vectorStoreId}/files/${fileId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
          },
        }
      );

      // Delete from OpenAI Files
      await axios.delete(
        `${BASE_URL}/files/${fileId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
          },
        }
      );

      // Delete from Firebase Storage
      const fileStorageRef = ref(storage, `${orgData.code}/protocols/${fileId}/file`);
      const imageStorageRef = ref(storage, `${orgData.code}/protocols/${fileId}/image`);
      await deleteObject(fileStorageRef);
      await deleteObject(imageStorageRef);

      // Delete document from Firestore
      const docRef = doc(db, "org", orgData.code, "protocols", fileId);
      await deleteDoc(docRef);

      // Fetch the updated list of files
      await fetchFiles();
      onFileClose();
    } catch (error) {
      console.error('Error deleting file:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orgData?.vectorStoreId && orgData?.code) {
      fetchFiles();
    }
  }, [orgData?.vectorStoreId, orgData?.code, fetchFiles]);

  // Find the staff member with matching UID
  const currentUserStaffData = orgData?.staff?.find(staff => staff.uid === userDoc.uid);

  // Find the value of "Edit Protocols" for the current user
  const editProtocolsValue = currentUserStaffData?.permissions?.["Edit Protocols"] || false;

  return (
    <Box w="100%">
      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
        {files.map((file) => (
          <GridItem
            key={file.id}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            border="1px solid"
            borderColor="#e6e6e6"
            borderRadius="xl"
            onClick={() => window.open(file.firestoreData?.fileURL, "_blank")}
            _hover={{ background: 'gray.100', cursor: 'pointer' }}
            p={4}
            overflow="hidden"
          >
            <Box w="100%" textAlign="center">
              {file.firestoreData?.imageURL ? (
                <Image 
                  src={file.firestoreData.imageURL} 
                  alt={file.firestoreData.name || "Image"} 
                  borderRadius="md"
                  maxH="200px"
                  objectFit="cover"
                  w="100%"
                />
              ) : (
                <Text isTruncated>{file.firestoreData?.name || file.id}</Text>
              )}
              <HStack 
                w="100%" 
                textAlign="center" 
                mt={4} 
                justifyContent="space-between" 
                alignItems="center"
              >
                <Text fontSize="3xl" fontWeight="bold" mb={2}>{file.firestoreData?.name || file.id}</Text>
                {editProtocolsValue && (
                  <IconButton
                    aria-label="Settings"
                    icon={<FiSettings />}
                    size="md"
                    borderWidth="1px"
                    variant="ghost"
                    _hover={{ background: 'gray.300' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFile(file);
                      onFileOpen();
                    }}
                  />
                )}
              </HStack>
            </Box>
          </GridItem>
        ))}

        {/* EvalChat Grid Item */}
        <GridItem
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderColor="#e6e6e6"
          borderRadius="xl"
          _hover={{ background: 'gray.100', cursor: 'pointer' }}
          p={4}
          overflow="hidden"
          onClick={() => navigate('/dashboard/protocols/chat')}
        >
          <VStack spacing={4} align="center">
            <Image src={TempImage} alt="EvalChat" maxH="50px" />
            <Text fontSize="3xl" fontWeight="bold" textAlign="center">EvalChat</Text>
            <Text color="gray.500" textAlign="center">Chat with your protocols.</Text>
          </VStack>
        </GridItem>
      </SimpleGrid>

      {editProtocolsValue && (
        <Button onClick={onOpen} colorScheme="blue" mt={4} w="200px" isDisabled={loading}>
          {loading ? 'Please wait...' : 'Add File'}
        </Button>
      )}

      {/* Add File Modal */}
      <AddFileModal 
        isOpen={isOpen} 
        onClose={onClose} 
        orgData={orgData} 
        onUploadComplete={fetchFiles} 
      />

      {/* File Information Modal */}
      <FileInformationModal 
        isOpen={isFileOpen} 
        onClose={onFileClose} 
        selectedFile={selectedFile} 
        onDelete={deleteFile} 
        loading={loading} 
      />

      {/* Display orgData, userData, and current staff member data */}
      {/* <Box mt={8}>
        <Text fontSize="xl" fontWeight="bold" mb={2}>Organization Data:</Text>
        <Code p={4} borderRadius="md" w="100%" bg="gray.50">
          {JSON.stringify(orgData, null, 2)}
        </Code>

        <Text fontSize="xl" fontWeight="bold" mt={8} mb={2}>User Data:</Text>
        <Code p={4} borderRadius="md" w="100%" bg="gray.50">
          {JSON.stringify(userDoc, null, 2)}
        </Code>

        {currentUserStaffData && (
          <Box mt={8}>
            <Text fontSize="xl" fontWeight="bold" mb={2}>Current User Staff Data:</Text>
            <Code p={4} borderRadius="md" w="100%" bg="gray.50">
              {JSON.stringify(currentUserStaffData, null, 2)}
            </Code>
          </Box>
        )}

        {currentUserStaffData && (
          <Box mt={8}>
            <Text fontSize="xl" fontWeight="bold" mb={2}>"Edit Protocols" Permission Value:</Text>
            <Code p={4} borderRadius="md" w="100%" bg="gray.50">
              {JSON.stringify(editProtocolsValue)}
            </Code>
          </Box>
        )}
      </Box> */}
    </Box>
  );
};

export default Protocols;
