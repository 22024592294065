import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Button,
  Avatar,
  Input,
  HStack,
} from "@chakra-ui/react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

const Staff = () => {
  const { orgData, userDoc } = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [userPermissions, setUserPermissions] = useState(null);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [selectedRole, setSelectedRole] = useState("All");
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    if (orgData) {
      setIsLoading(false);
      const staffMember = orgData.staff.find(
        (member) => member.uid === userDoc.uid
      );
      if (staffMember) {
        setUserPermissions(staffMember.permissions);
        setSelectedDepartment(staffMember.department || "All");
      }
      setFilteredStaff(orgData.staff);
    }
  }, [orgData, userDoc]);

  const updateStaffMember = async (uid, updatedFields) => {
    console.log(`User ${uid} changed:`, updatedFields);

    try {
      const updatedStaff = orgData.staff.map((member) => {
        if (member.uid === uid) {
          return { ...member, ...updatedFields };
        }
        return member;
      });

      const orgRef = doc(db, "org", orgData.code);
      await updateDoc(orgRef, { staff: updatedStaff });

      orgData.staff = updatedStaff;
      filterStaff();
    } catch (error) {
      console.error("Error updating staff member:", error);
    }
  };

  const handleRowClick = (uid, e) => {
    if (
      e.target.tagName !== "SELECT" &&
      e.target.tagName !== "OPTION" &&
      e.target.tagName !== "BUTTON"
    ) {
      navigate(`/dashboard/staff/${uid}`);
    }
  };

  const handleDepartmentChange = (uid, newDepartment) => {
    updateStaffMember(uid, { department: newDepartment });
  };

  const handleRoleChange = (uid, newRole) => {
    // Find the permissions for the selected role
    const roleData = orgData.roles.find((role) => role.name === newRole);
    const newPermissions = roleData ? roleData.permissions : {};

    updateStaffMember(uid, { role: newRole, permissions: newPermissions });
  };

  const handleVerificationChange = (uid, newVerificationStatus, e) => {
    e.stopPropagation(); // Stop the row click event from being triggered
    updateStaffMember(uid, { verified: newVerificationStatus });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleDepartmentFilterChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleRoleFilterChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const filterStaff = useCallback(() => {
    if (!orgData) return;

    let filtered = orgData.staff;

    if (searchQuery) {
      filtered = filtered.filter(
        (member) =>
          member.firstName.toLowerCase().includes(searchQuery) ||
          member.lastName.toLowerCase().includes(searchQuery) ||
          member.email.toLowerCase().includes(searchQuery)
      );
    }

    if (selectedDepartment !== "All") {
      filtered = filtered.filter(
        (member) => member.department === selectedDepartment
      );
    }

    if (selectedRole !== "All") {
      filtered = filtered.filter((member) => member.role === selectedRole);
    }

    setFilteredStaff(filtered);
  }, [searchQuery, selectedDepartment, selectedRole, orgData]);

  useEffect(() => {
    filterStaff();
  }, [filterStaff]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="90vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box>
      {orgData ? (
        <Box>
          <HStack mb={4} spacing={4}>
            <Input
              placeholder="Search by name or email"
              value={searchQuery}
              onChange={handleSearch}
            />
            <Select
              value={selectedDepartment}
              onChange={handleDepartmentFilterChange}
              width="200px"
            >
              <option value="All">All</option>
              {orgData.departments.map((dept, i) => (
                <option key={i} value={dept}>
                  {dept}
                </option>
              ))}
            </Select>
            <Select
              value={selectedRole}
              onChange={handleRoleFilterChange}
              width="200px"
            >
              <option value="All">All</option>
              {orgData.roles.map((role, i) => (
                <option key={i} value={role.name}>
                  {role.name}
                </option>
              ))}
            </Select>
          </HStack>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Profile</Th>
                <Th>Department</Th>
                <Th>Role</Th>
                <Th>Verified</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredStaff.map((member, index) => (
                <Tr 
                  key={index} 
                  cursor="pointer" 
                  onClick={(e) => handleRowClick(member.uid, e)}
                  _hover={{ bg: "gray.100" }}
                >
                  <Td>
                    <Box display="flex" alignItems="center">
                      <Avatar 
                        name={`${member.firstName} ${member.lastName}`} 
                        src={member.profilePictureURL} 
                        bg="gray.200"
                        mr={4}
                        color="#222222"
                      />
                      <Box>
                        <Text fontWeight="bold">{`${member.firstName} ${member.lastName}`}</Text>
                        <Text fontSize="sm" color="gray.500">{member.email}</Text>
                      </Box>
                    </Box>
                  </Td>
                  <Td onClick={(e) => e.stopPropagation()}>
                    {userPermissions?.["Edit Department"] ? (
                      <Select 
                        value={member.department} 
                        onChange={(e) => handleDepartmentChange(member.uid, e.target.value)} 
                        size="sm"
                      >
                        <option value={member.department}>
                          {member.department || "No Department"}
                        </option>
                        {orgData.departments.map((dept, i) => (
                          <option key={i} value={dept}>
                            {dept}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      member.department || "No Department"
                    )}
                  </Td>
                  <Td onClick={(e) => e.stopPropagation()}>
                    {userPermissions?.["Edit Role"] ? (
                      <Select 
                        value={member.role} 
                        onChange={(e) => handleRoleChange(member.uid, e.target.value)} 
                        size="sm"
                      >
                        <option value={member.role}>
                          {member.role || "No Role"}
                        </option>
                        {orgData.roles.map((role, i) => (
                          <option key={i} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      member.role || "No Role"
                    )}
                  </Td>
                  <Td onClick={(e) => e.stopPropagation()}>
                    {userPermissions?.["Edit Verification"] ? (
                      <Button
                        colorScheme={member.verified ? "green" : "red"}
                        size="sm"
                        onClick={(e) =>
                          handleVerificationChange(member.uid, !member.verified, e)
                        }
                        isDisabled={member.uid === userDoc.uid} // Disable the button if it's the current user
                      >
                        {member.verified ? "Verified" : "Unverified"}
                      </Button>
                    ) : (
                      <Button
                        colorScheme={member.verified ? "green" : "red"}
                        size="sm"
                        isDisabled
                      >
                        {member.verified ? "Verified" : "Unverified"}
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Text>No organization data available.</Text>
      )}
    </Box>
  );
};

export default Staff;
