import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  FormControl,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  List,
  ListItem,
} from "@chakra-ui/react";
import { db } from "../../../../Firebase";
import { collection, getDocs } from "firebase/firestore";
import { useOutletContext } from "react-router-dom";

const TagHandler = ({ tags, setTags }) => {
  const { userDoc } = useOutletContext();
  const [newTag, setNewTag] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const orgId = userDoc?.orgId;

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const evaluationsRef = collection(db, "org", orgId, "evaluations");
        const evaluationsSnapshot = await getDocs(evaluationsRef);
        const tagsSet = new Set();

        evaluationsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.tags) {
            data.tags.forEach((tag) => tagsSet.add(tag));
          }
        });

        setAllTags([...tagsSet]);
      } catch (error) {
        console.error("Error fetching tags: ", error);
      }
    };

    if (orgId) {
      fetchTags();
    }
  }, [orgId]);

  const handleAddTag = (tag) => {
    if (tag.trim() && !tags.includes(tag.trim())) {
      setTags([...tags, tag.trim()]);
      setNewTag("");
      setShowDropdown(false);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setNewTag(value);
    if (value) {
      setFilteredTags(
        allTags.filter(
          (tag) =>
            tag.toLowerCase().includes(value.toLowerCase()) &&
            !tags.includes(tag)
        )
      );
    } else {
      setFilteredTags(allTags.filter((tag) => !tags.includes(tag)));
    }
    setShowDropdown(true);
  };

  const handleInputFocus = () => {
    setFilteredTags(allTags.filter((tag) => !tags.includes(tag)));
    setShowDropdown(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => setShowDropdown(false), 200);
  };

  return (
    <FormControl id="tags" mb={4}>
      <HStack spacing={2}>
        <Input
          type="text"
          value={newTag}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          placeholder="Add a tag..."
          bg="gray.100"
          borderWidth="0px"
        />
        <Button onClick={() => handleAddTag(newTag)} colorScheme="blue">
          Add Tag
        </Button>
      </HStack>
      {showDropdown && (
        <List
          position="absolute"
          zIndex={1}
          bg="white"
          border="1px solid #e2e8f0"
          borderRadius="md"
          mt={1}
          w="100%"
          maxH="200px"
          overflowY="auto"
        >
          {filteredTags.map((tag, index) => (
            <ListItem
              key={index}
              p={2}
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
              onClick={() => handleAddTag(tag)}
              bg={tags.includes(tag) ? "gray.200" : "white"}
            >
              {tag}
            </ListItem>
          ))}
        </List>
      )}
      <HStack spacing={2} mt={4}>
        {tags.map((tag, index) => (
          <Tag
            key={index}
            size="md"
            borderRadius="full"
            variant="solid"
            colorScheme="blue"
          >
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton onClick={() => handleRemoveTag(tag)} />
          </Tag>
        ))}
      </HStack>
    </FormControl>
  );
};

export default TagHandler;
