import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Image, // Import Image from Chakra UI
} from '@chakra-ui/react';

const FileInformationModal = ({ isOpen, onClose, selectedFile, onDelete, loading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>File Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {selectedFile?.firestoreData?.imageURL && (
            <Image 
              src={selectedFile.firestoreData.imageURL} 
              alt={selectedFile?.firestoreData.name || "Image"} 
              mb={4} 
              borderRadius="md"
              maxH="200px"
              objectFit="cover"
            />
          )}
          <Text><strong>Name:</strong> {selectedFile?.firestoreData?.name || "N/A"}</Text>
          <Text><strong>File ID:</strong> {selectedFile?.id}</Text>
          <Text><strong>Created At:</strong> {new Date(selectedFile?.openAiData?.created_at * 1000).toLocaleString() || "N/A"}</Text>
          <Text><strong>Usage Bytes:</strong> {selectedFile?.openAiData?.usage_bytes || "N/A"}</Text>
          <Text><strong>Status:</strong> {selectedFile?.openAiData?.status || "N/A"}</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={() => onDelete(selectedFile?.id)} isDisabled={loading}>
            {loading ? 'Please wait...' : 'Delete File'}
          </Button>
          <Button variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FileInformationModal;
