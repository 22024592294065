import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  // Code,
  Input,
  FormControl,
  FormLabel,
  Button,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { useOutletContext } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import HasOrg from "./HasOrg"; // Import the HasOrg component

const Overview = () => {
  const { userDoc } = useOutletContext();
  const [inputValue, setInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isOrgLoading, setIsOrgLoading] = useState(false);
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Join Organization");
  const [buttonColorScheme, setButtonColorScheme] = useState("blue");
  const [orgDoc, setOrgDoc] = useState(null); // State to store organization document
  const [selectedDepartment, setSelectedDepartment] = useState(""); // State for selected department
  const [selectedRole, setSelectedRole] = useState(""); // State for selected role
  const [isLoading, setIsLoading] = useState(true); // State to control the loading spinner
  const functions = getFunctions();

  useEffect(() => {
    if (userDoc) {
      if (userDoc.orgId) {
        const fetchOrgData = async () => {
          setIsOrgLoading(true);
          const validateOrgId = httpsCallable(functions, "verifyOrgId");
          try {
            const response = await validateOrgId({ orgId: userDoc.orgId });
            console.log("Organization Data:", response.data);
            setOrgDoc(response.data); // Assuming the org document is the entire response.data
          } catch (error) {
            console.error("Error fetching organization data:", error);
          } finally {
            setIsOrgLoading(false);
            setIsLoading(false);
          }
        };

        fetchOrgData();
      } else {
        setIsLoading(false);
      }
    }
  }, [userDoc, functions]);

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 9) {
      setInputValue(value);
      if (value.length === 9) {
        callFirebaseFunction(value);
      } else {
        setIsButtonDisabled(true);
        setButtonText("Join Organization");
        setButtonColorScheme("blue");
        setOrgDoc(null); // Reset orgDoc if input is not valid
      }
    }
  };

  const callFirebaseFunction = async (digits) => {
    setIsOrgLoading(true);
    const validateOrgId = httpsCallable(functions, "verifyOrgId");
    try {
      const response = await validateOrgId({ orgId: digits });
      console.log("Firebase Function Response:", response.data);

      // Enable the button and show the organization document if the response is valid
      setIsButtonDisabled(false);
      setButtonText("Join Organization");
      setButtonColorScheme("blue");
      setOrgDoc(response.data); // Assuming the org document is the entire response.data
    } catch (error) {
      console.error("Error calling Firebase Function:", error);

      // Change the button to red and display "Invalid Organization"
      setIsButtonDisabled(true);
      setButtonText("Invalid Organization");
      setButtonColorScheme("red");
      setOrgDoc(null); // Clear orgDoc if there was an error
    } finally {
      setIsOrgLoading(false);
    }
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleJoinOrganization = async () => {
    if (!userDoc || !userDoc.uid) {
      console.error("User document or UID is missing");
      return;
    }

    setIsJoinLoading(true);
    const joinOrganization = httpsCallable(functions, "joinOrg");
    try {
      const response = await joinOrganization({
        uid: userDoc.uid, // Pass the user UID from userDoc
        orgId: inputValue,
        department: selectedDepartment,
        role: selectedRole,
      });
      console.log("Join Organization Response:", response.data);
      // window.location.reload();
    } catch (error) {
      console.error("Error joining organization:", error);
      // Handle error, e.g., show error message
    } finally {
      setIsJoinLoading(false);
    }
  };

  // Show a spinner while deciding what to show
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="90vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  // Conditionally render based on whether the user has an orgId
  if (userDoc?.orgId && orgDoc) {
    return <HasOrg orgDoc={orgDoc} userDoc={userDoc} />;
  }

  return (
    <Box  maxW="500px" mx="auto">
      <FormControl id="orgId">
        <FormLabel>Enter Organization ID (9 digits)</FormLabel>
        <Input
          mb={2}
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter 9 digit organization ID"
          isDisabled={isOrgLoading}
        />
      </FormControl>

      {isOrgLoading && (
        <Box mb={2}>
          <Spinner size="sm" />
          <Text ml={2} display="inline">
            Looking up organization...
          </Text>
        </Box>
      )}

      {orgDoc && (
        <Box>
          <Text mt={4}>Organization Name:</Text>
          <Text fontSize="2xl" fontWeight="bold">{orgDoc.name}</Text>

          {orgDoc.departments && orgDoc.departments.length > 0 && (
            <FormControl mt={4} id="department" mb={2}>
              <FormLabel>Select Department</FormLabel>
              <Select
                placeholder="Select a department"
                value={selectedDepartment}
                onChange={handleDepartmentChange}
              >
                {orgDoc.departments.map((department, index) => (
                  <option key={index} value={department}>
                    {department}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {orgDoc.roles && orgDoc.roles.length > 0 && (
            <FormControl mt={4} id="role" mb={2}>
              <FormLabel>Select Role</FormLabel>
              <Select
                placeholder="Select a role"
                value={selectedRole}
                onChange={handleRoleChange}
              >
                {orgDoc.roles.map((role, index) => (
                  <option key={index} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {/* <Text fontWeight="bold">Raw JSON Data:</Text>
          <Code
            display="block"
            whiteSpace="pre-wrap"
            p={2}
            borderRadius="md"
            bg="gray.200"
          >
            {JSON.stringify(orgDoc, null, 2)}
          </Code> */}
        </Box>
      )}

      <Button
        colorScheme={buttonColorScheme}
        isDisabled={isButtonDisabled || isJoinLoading}
        mb={2}
        w="full"
        onClick={handleJoinOrganization}
      >
        {isJoinLoading ? <Spinner size="sm" mr={2} /> : buttonText}
      </Button>

      {/* {userDoc ? (
        <Code
          display="block"
          whiteSpace="pre-wrap"
          p={4}
          borderRadius="md"
          bg="gray.100"
        >
          {JSON.stringify(userDoc, null, 2)}
        </Code>
      ) : (
        <Text>No user document available.</Text>
      )} */}
    </Box>
  );
};

export default Overview;
