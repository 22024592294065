import { 
    FaAddressBook, FaAddressCard, FaAdjust, FaAirFreshener,
    FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaAllergies, FaAmbulance,
    FaAnchor, FaAngleDoubleDown, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleDoubleUp,
    FaApple, FaArrowCircleDown, FaArrowCircleLeft, FaArrowCircleRight, FaArrowCircleUp,
    FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaArrowsAlt, FaArrowsAltH, FaArrowsAltV,
    FaAssistiveListeningSystems, FaAsterisk, FaAt, FaAtlas, FaAtom, FaAward, FaBaby, FaBabyCarriage,
    FaBackward, FaBacon, FaBacteria, FaBacterium, FaBahai, FaBalanceScale, FaBan, FaBandAid,
    FaBarcode, FaBars, FaBaseballBall, FaBasketballBall, FaBath, FaBatteryEmpty, FaBatteryFull,
    FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters, FaBed, FaBeer, FaBell, FaBellSlash,
    FaBezierCurve, FaBible, FaBicycle, FaBiking, FaBinoculars, FaBiohazard, FaBirthdayCake, FaBlender,
    FaBlenderPhone, FaBlind, FaBlog, FaBold, FaBolt, FaBomb, FaBone, FaBong, FaBook, FaBookDead,
    FaBookMedical, FaBookOpen, FaBookReader, FaBookmark, FaBorderAll, FaBorderNone, FaBorderStyle,
    FaBowlingBall, FaBox, FaBoxOpen, FaBoxTissue, FaBoxes, FaBraille, FaBrain, FaBreadSlice, FaBriefcase,
    FaBriefcaseMedical, FaBroadcastTower, FaBroom, FaBrush, FaBug, FaBuilding, FaBullhorn, FaBullseye,
    FaBurn, FaBus, FaBusAlt, FaBusinessTime, FaCalculator, FaCalendar, FaCalendarAlt, FaCalendarCheck,
    FaCalendarDay, FaCalendarMinus, FaCalendarPlus, FaCalendarTimes, FaCalendarWeek, FaCamera, FaCameraRetro,
    FaCampground, FaCandyCane, FaCannabis, FaCapsules, FaCar, FaCarAlt, FaCarBattery, FaCarCrash,
    FaCarSide, FaCaravan, FaCaretDown, FaCaretLeft, FaCaretRight, FaCaretSquareDown, FaCaretSquareLeft,
    FaCaretSquareRight, FaCaretSquareUp, FaCaretUp, FaCarrot, FaCartArrowDown, FaCartPlus, FaCashRegister,
    FaCat, FaCertificate, FaChair, FaChalkboard, FaChalkboardTeacher, FaChargingStation, FaChartArea,
    FaChartBar, FaChartLine, FaChartPie, FaCheck, FaCheckCircle, FaCheckDouble, FaCheckSquare, FaCheese,
    FaChess, FaChessBishop, FaChessBoard, FaChessKing, FaChessKnight, FaChessPawn, FaChessQueen, FaChessRook,
    FaChevronCircleDown, FaChevronCircleLeft, FaChevronCircleRight, FaChevronCircleUp, FaChevronDown,
    FaChevronLeft, FaChevronRight, FaChevronUp, FaChild, FaChurch, FaCircle, FaCircleNotch, FaCity,
    FaClinicMedical, FaClipboard, FaClipboardCheck, FaClipboardList, FaClock, FaClone, FaClosedCaptioning,
    FaCloud, FaCloudDownloadAlt, FaCloudMeatball, FaCloudMoon, FaCloudMoonRain, FaCloudRain, FaCloudShowersHeavy,
    FaCloudSun, FaCloudSunRain, FaCloudUploadAlt, FaCocktail, FaCode, FaCodeBranch, FaCoffee, FaCog, FaCogs,
    FaCoins, FaColumns, FaComment, FaCommentAlt, FaCommentDollar, FaCommentDots, FaCommentMedical, FaCommentSlash,
    FaComments, FaCompactDisc, FaCompass, FaCompress, FaCompressAlt, FaCompressArrowsAlt, FaConciergeBell,
    FaCookie, FaCookieBite, FaCopy, FaCopyright, FaCouch, FaCreditCard, FaCrop, FaCropAlt, FaCross, FaCrosshairs,
    FaCrow, FaCrown, FaCrutch, FaCube, FaCubes, FaCut, FaDatabase, FaDeaf, FaDemocrat, FaDesktop, FaDharmachakra,
    FaDiagnoses, FaDice, FaDiceD20, FaDiceD6, FaDiceFive, FaDiceFour, FaDiceOne, FaDiceSix, FaDiceThree, FaDiceTwo,
    FaDigitalTachograph, FaDirections, FaDisease, FaDivide, FaDizzy, FaDna, FaDog, FaDollarSign, FaDolly, FaDollyFlatbed,
    FaDonate, FaDoorClosed, FaDoorOpen, FaDotCircle, FaDove, FaDownload, FaDraftingCompass, FaDragon, FaDrawPolygon,
    FaDrum, FaDrumSteelpan, FaDrumstickBite, FaDumbbell, FaDumpster, FaDumpsterFire, FaDungeon, FaEdit, FaEgg,
    FaEject, FaEllipsisH, FaEllipsisV, FaEnvelope, FaEnvelopeOpen, FaEnvelopeOpenText, FaEnvelopeSquare, FaEquals,
    FaEraser, FaEthernet, FaEuroSign, FaExchangeAlt, FaExclamation, FaExclamationCircle, FaExclamationTriangle, FaExpand,
    FaExpandAlt, FaExpandArrowsAlt, FaExternalLinkAlt, FaExternalLinkSquareAlt, FaEye, FaEyeDropper, FaEyeSlash, FaFan,
    FaFastBackward, FaFastForward, FaFax, FaFeather, FaFeatherAlt, FaFemale, FaFighterJet, FaFile, FaFileAlt,
    FaFileArchive, FaFileAudio, FaFileCode, FaFileContract, FaFileCsv, FaFileDownload, FaFileExcel, FaFileExport,
    FaFileImage, FaFileImport, FaFileInvoice, FaFileInvoiceDollar, FaFileMedical, FaFileMedicalAlt, FaFilePdf, FaFilePowerpoint,
    FaFilePrescription, FaFileSignature, FaFileUpload, FaFileVideo, FaFileWord, FaFill, FaFillDrip, FaFilm, FaFilter, FaFingerprint,
    FaFire, FaFireAlt, FaFireExtinguisher, FaFirstAid, FaFish, FaFistRaised, FaFlag, FaFlagCheckered, FaFlagUsa, FaFlask,
    FaFlushed, FaFolder, FaFolderMinus, FaFolderOpen, FaFolderPlus, FaFont, FaFootballBall, FaForward, FaFrog, FaFrown,
    FaFrownOpen, FaFunnelDollar, FaFutbol, FaGamepad, FaGasPump, FaGavel, FaGem, FaGenderless, FaGhost, FaGift, FaGifts,
    FaGlassCheers, FaGlassMartini, FaGlassMartiniAlt, FaGlassWhiskey, FaGlasses, FaGlobe, FaGlobeAfrica, FaGlobeAmericas,
    FaGlobeAsia, FaGlobeEurope, FaGolfBall, FaGopuram, FaGraduationCap, FaGreaterThan, FaGreaterThanEqual, FaGrimace,
    FaGrin, FaGrinAlt, FaGrinBeam, FaGrinBeamSweat, FaGrinHearts, FaGrinSquint, FaGrinSquintTears, FaGrinStars, FaGrinTears,
    FaGrinTongue, FaGrinTongueSquint, FaGrinTongueWink, FaGrinWink, FaGripHorizontal, FaGripLines, FaGripLinesVertical,
    FaGripVertical, FaGuitar, FaHSquare, FaHamburger, FaHammer, FaHamsa, FaHandHolding, FaHandHoldingHeart, FaHandHoldingUsd,
    FaHandLizard, FaHandMiddleFinger, FaHandPaper, FaHandPeace, FaHandPointDown, FaHandPointLeft, FaHandPointRight, FaHandPointUp,
    FaHandPointer, FaHandRock, FaHandScissors, FaHandSpock, FaHands, FaHandsHelping, FaHandshake, FaHanukiah, FaHardHat,
    FaHashtag, FaHatCowboy, FaHatCowboySide, FaHatWizard, FaHdd, FaHeading, FaHeadphones, FaHeadphonesAlt, FaHeadset, FaHeart,
    FaHeartBroken, FaHeartbeat, FaHelicopter, FaHighlighter, FaHiking, FaHippo, FaHistory, FaHockeyPuck, FaHollyBerry, FaHome,
    FaHorse, FaHorseHead, FaHospital, FaHospitalAlt, FaHospitalSymbol, FaHotTub, FaHotdog, FaHotel, FaHourglass, FaHourglassEnd,
    FaHourglassHalf, FaHourglassStart, FaHouseDamage, FaHryvnia, FaICursor, FaIceCream, FaIcicles, FaIcons, FaIdBadge, FaIdCard,
    FaIdCardAlt, FaIgloo, FaImage, FaImages, FaInbox, FaIndent, FaIndustry, FaInfinity, FaInfo, FaInfoCircle, FaItalic, FaJedi,
    FaJoint, FaJournalWhills, FaKaaba, FaKey, FaKeyboard, FaKhanda, FaKiss, FaKissBeam, FaKissWinkHeart, FaKiwiBird, FaLandmark,
    FaLanguage, FaLaptop, FaLaptopCode, FaLaptopMedical, FaLaugh, FaLaughBeam, FaLaughSquint, FaLaughWink, FaLayerGroup, FaLeaf,
    FaLemon, FaLessThan, FaLessThanEqual, FaLevelDownAlt, FaLevelUpAlt, FaLifeRing, FaLightbulb, FaLink, FaLiraSign, FaList,
    FaListAlt, FaListOl, FaListUl, FaLocationArrow, FaLock, FaLockOpen, FaLongArrowAltDown, FaLongArrowAltLeft, FaLongArrowAltRight,
    FaLongArrowAltUp, FaLowVision, FaLuggageCart, FaMagic, FaMagnet, FaMailBulk, FaMale, FaMap, FaMapMarked, FaMapMarkedAlt,
    FaMapMarker, FaMapMarkerAlt, FaMapPin, FaMapSigns, FaMarker, FaMars, FaMarsDouble, FaMarsStroke, FaMarsStrokeH, FaMarsStrokeV,
    FaMask, FaMedal, FaMedkit, FaMeh, FaMehBlank, FaMehRollingEyes, FaMemory, FaMenorah, FaMercury, FaMeteor, FaMicrochip,
    FaMicrophone, FaMicrophoneAlt, FaMicrophoneAltSlash, FaMicrophoneSlash, FaMicroscope, FaMinus, FaMinusCircle, FaMinusSquare,
    FaMitten, FaMobile, FaMobileAlt, FaMoneyBill, FaMoneyBillAlt, FaMoneyBillWave, FaMoneyBillWaveAlt, FaMoneyCheck, FaMoneyCheckAlt,
    FaMonument, FaMoon, FaMortarPestle, FaMosque, FaMotorcycle, FaMountain, FaMouse, FaMousePointer, FaMugHot, FaMusic, FaNetworkWired,
    FaNeuter, FaNewspaper, FaNotEqual, FaNotesMedical, FaObjectGroup, FaObjectUngroup, FaOilCan, FaOm, FaOtter, FaOutdent, FaPager,
    FaPaintBrush, FaPaintRoller, FaPalette, FaPallet, FaPaperPlane, FaPaperclip, FaParachuteBox, FaParagraph, FaParking, FaPassport,
    FaPastafarianism, FaPaste, FaPause, FaPauseCircle, FaPaw, FaPeace, FaPen, FaPenAlt, FaPenFancy, FaPenNib, FaPenSquare, FaPencilAlt,
    FaPencilRuler, FaPeopleCarry, FaPepperHot, FaPercent, FaPercentage, FaPersonBooth, FaPhone, FaPhoneAlt, FaPhoneSlash, FaPhoneSquare,
    FaPhoneSquareAlt, FaPhoneVolume, FaPhotoVideo, FaPiggyBank, FaPills, FaPizzaSlice, FaPlaceOfWorship, FaPlane, FaPlaneArrival,
    FaPlaneDeparture, FaPlay, FaPlayCircle, FaPlug, FaPlus, FaPlusCircle, FaPlusSquare, FaPodcast, FaPoll, FaPollH, FaPoo, FaPooStorm,
    FaPoop, FaPortrait, FaPoundSign, FaPowerOff, FaPray, FaPrayingHands, FaPrescription, FaPrescriptionBottle, FaPrescriptionBottleAlt,
    FaPrint, FaProcedures, FaProjectDiagram, FaPumpMedical, FaPumpSoap, FaPuzzlePiece, FaQrcode, FaQuestion, FaQuestionCircle,
    FaQuidditch, FaQuoteLeft, FaQuoteRight, FaQuran, FaRadiation, FaRadiationAlt, FaRainbow, FaRandom, FaReceipt, FaRecordVinyl, FaRecycle,
    FaRedo, FaRedoAlt, FaRegistered, FaRemoveFormat, FaReply, FaReplyAll, FaRepublican, FaRestroom, FaRetweet, FaRibbon, FaRing, FaRoad,
    FaRobot, FaRocket, FaRoute, FaRss, FaRssSquare, FaRubleSign, FaRuler, FaRulerCombined, FaRulerHorizontal, FaRulerVertical, FaRunning,
    FaRupeeSign, FaSadCry, FaSadTear, FaSatellite, FaSatelliteDish, FaSave, FaSchool, FaScrewdriver, FaScroll, FaSdCard, FaSearch, FaSearchDollar,
    FaSearchLocation, FaSearchMinus, FaSearchPlus, FaSeedling, FaServer, FaShapes, FaShare, FaShareAlt, FaShareAltSquare, FaShareSquare,
    FaShekelSign, FaShieldAlt, FaShieldVirus, FaShip, FaShippingFast, FaShoePrints, FaShoppingBag, FaShoppingBasket, FaShoppingCart,
    FaShower, FaShuttleVan, FaSign, FaSignInAlt, FaSignLanguage, FaSignOutAlt, FaSignal, FaSignature, FaSimCard, FaSink, FaSitemap,
    FaSkating, FaSkiing, FaSkiingNordic, FaSkull, FaSkullCrossbones, FaSlash, FaSleigh, FaSlidersH, FaSmile, FaSmileBeam, FaSmileWink,
    FaSmog, FaSmoking, FaSmokingBan, FaSms, FaSnowboarding, FaSnowflake, FaSnowman, FaSnowplow, FaSoap, FaSocks, FaSolarPanel,
    FaSort, FaSortAlphaDown, FaSortAlphaDownAlt, FaSortAlphaUp, FaSortAlphaUpAlt, FaSortAmountDown, FaSortAmountDownAlt, FaSortAmountUp,
    FaSortAmountUpAlt, FaSortDown, FaSortNumericDown, FaSortNumericDownAlt, FaSortNumericUp, FaSortNumericUpAlt, FaSortUp, FaSpa, FaSpaceShuttle,
    FaSpellCheck, FaSpider, FaSpinner, FaSplotch, FaSprayCan, FaSquare, FaSquareFull, FaSquareRootAlt, FaStamp, FaStar, FaStarAndCrescent,
    FaStarHalf, FaStarHalfAlt, FaStarOfDavid, FaStarOfLife, FaStepBackward, FaStepForward, FaStethoscope, FaStickyNote, FaStop,
    FaStopCircle, FaStopwatch, FaStore, FaStoreAlt, FaStoreAltSlash, FaStoreSlash, FaStream, FaStreetView, FaStrikethrough, FaStroopwafel,
    FaSubscript, FaSubway, FaSuitcase, FaSuitcaseRolling, FaSun, FaSuperscript, FaSurprise, FaSwatchbook, FaSwimmer, FaSwimmingPool, FaSynagogue,
    FaSync, FaSyncAlt, FaSyringe, FaTable, FaTableTennis, FaTablet, FaTabletAlt, FaTablets, FaTachometerAlt, FaTag, FaTags, FaTape, FaTasks,
    FaTaxi, FaTeeth, FaTeethOpen, FaTemperatureHigh, FaTemperatureLow, FaTenge, FaTerminal, FaTextHeight, FaTextWidth, FaTh, FaThLarge, FaThList,
    FaTheaterMasks, FaThermometer, FaThermometerEmpty, FaThermometerFull, FaThermometerHalf, FaThermometerQuarter, FaThermometerThreeQuarters,
    FaThumbsDown, FaThumbsUp, FaThumbtack, FaTicketAlt, FaTimes, FaTimesCircle, FaTint, FaTintSlash, FaTired, FaToggleOff, FaToggleOn,
    FaToilet, FaToiletPaper, FaToiletPaperSlash, FaToolbox, FaTools, FaTooth, FaTorah, FaToriiGate, FaTractor, FaTrademark, FaTrafficLight,
    FaTrailer, FaTrain, FaTram, FaTransgender, FaTransgenderAlt, FaTrash, FaTrashAlt, FaTrashRestore, FaTrashRestoreAlt, FaTree, FaTrophy,
    FaTruck, FaTruckLoading, FaTruckMonster, FaTruckMoving, FaTruckPickup, FaTshirt, FaTty, FaTv, FaUmbrella, FaUmbrellaBeach, FaUnderline,
    FaUndo, FaUndoAlt, FaUniversalAccess, FaUniversity, FaUnlink, FaUnlock, FaUnlockAlt, FaUpload, FaUser, FaUserAlt, FaUserAltSlash,
    FaUserAstronaut, FaUserCheck, FaUserCircle, FaUserClock, FaUserCog, FaUserEdit, FaUserFriends, FaUserGraduate, FaUserInjured, FaUserLock,
    FaUserMd, FaUserMinus, FaUserNinja, FaUserNurse, FaUserPlus, FaUserSecret, FaUserShield, FaUserSlash, FaUserTag, FaUserTie, FaUserTimes,
    FaUsers, FaUsersCog, FaUsersSlash, FaUtensilSpoon, FaUtensils, FaVectorSquare, FaVenus, FaVenusDouble, FaVenusMars, FaVest, FaVestPatches,
    FaVial, FaVials, FaVideo, FaVideoSlash, FaVihara, FaVirus, FaVirusSlash, FaViruses, FaVoicemail, FaVolleyballBall, FaVolumeDown, FaVolumeMute,
    FaVolumeOff, FaVolumeUp, FaVoteYea, FaVrCardboard, FaWalking, FaWallet, FaWarehouse, FaWater, FaWaveSquare, FaWeight, FaWeightHanging, FaWheelchair,
    FaWifi, FaWind, FaWindowClose, FaWindowMaximize, FaWindowMinimize, FaWindowRestore, FaWineBottle, FaWineGlass, FaWineGlassAlt, FaWonSign,
    FaWrench, FaXRay, FaYenSign, FaYinYang 
  } from 'react-icons/fa';
  
  export const icons = [
    { value: 'FaAmbulance', label: 'Ambulance', icon: FaAmbulance },
    { value: 'FaLock', label: 'Lock', icon: FaLock },
    { value: 'FaArrowUp', label: 'Arrow Up', icon: FaArrowUp },
    { value: 'FaAddressBook', label: 'Address Book', icon: FaAddressBook },
    { value: 'FaAddressCard', label: 'Address Card', icon: FaAddressCard },
    { value: 'FaAdjust', label: 'Adjust', icon: FaAdjust },
    { value: 'FaAirFreshener', label: 'Air Freshener', icon: FaAirFreshener },
    { value: 'FaAlignCenter', label: 'Align Center', icon: FaAlignCenter },
    { value: 'FaAlignJustify', label: 'Align Justify', icon: FaAlignJustify },
    { value: 'FaAlignLeft', label: 'Align Left', icon: FaAlignLeft },
    { value: 'FaAlignRight', label: 'Align Right', icon: FaAlignRight },
    { value: 'FaAllergies', label: 'Allergies', icon: FaAllergies },
    { value: 'FaAnchor', label: 'Anchor', icon: FaAnchor },
    { value: 'FaAngleDoubleDown', label: 'Angle Double Down', icon: FaAngleDoubleDown },
    { value: 'FaAngleDoubleLeft', label: 'Angle Double Left', icon: FaAngleDoubleLeft },
    { value: 'FaAngleDoubleRight', label: 'Angle Double Right', icon: FaAngleDoubleRight },
    { value: 'FaAngleDoubleUp', label: 'Angle Double Up', icon: FaAngleDoubleUp },
    { value: 'FaApple', label: 'Apple', icon: FaApple },
    { value: 'FaArrowCircleDown', label: 'Arrow Circle Down', icon: FaArrowCircleDown },
    { value: 'FaArrowCircleLeft', label: 'Arrow Circle Left', icon: FaArrowCircleLeft },
    { value: 'FaArrowCircleRight', label: 'Arrow Circle Right', icon: FaArrowCircleRight },
    { value: 'FaArrowCircleUp', label: 'Arrow Circle Up', icon: FaArrowCircleUp },
    { value: 'FaArrowDown', label: 'Arrow Down', icon: FaArrowDown },
    { value: 'FaArrowLeft', label: 'Arrow Left', icon: FaArrowLeft },
    { value: 'FaArrowRight', label: 'Arrow Right', icon: FaArrowRight },
    { value: 'FaArrowUp', label: 'Arrow Up', icon: FaArrowUp },
    { value: 'FaArrowsAlt', label: 'Arrows Alt', icon: FaArrowsAlt },
    { value: 'FaArrowsAltH', label: 'Arrows Alt Horizontal', icon: FaArrowsAltH },
    { value: 'FaArrowsAltV', label: 'Arrows Alt Vertical', icon: FaArrowsAltV },
    { value: 'FaAssistiveListeningSystems', label: 'Assistive Listening Systems', icon: FaAssistiveListeningSystems },
    { value: 'FaAsterisk', label: 'Asterisk', icon: FaAsterisk },
    { value: 'FaAt', label: 'At', icon: FaAt },
    { value: 'FaAtlas', label: 'Atlas', icon: FaAtlas },
    { value: 'FaAtom', label: 'Atom', icon: FaAtom },
    { value: 'FaAward', label: 'Award', icon: FaAward },
    { value: 'FaBaby', label: 'Baby', icon: FaBaby },
    { value: 'FaBabyCarriage', label: 'Baby Carriage', icon: FaBabyCarriage },
    { value: 'FaBackward', label: 'Backward', icon: FaBackward },
    { value: 'FaBacon', label: 'Bacon', icon: FaBacon },
    { value: 'FaBacteria', label: 'Bacteria', icon: FaBacteria },
    { value: 'FaBacterium', label: 'Bacterium', icon: FaBacterium },
    { value: 'FaBahai', label: 'Bahai', icon: FaBahai },
    { value: 'FaBalanceScale', label: 'Balance Scale', icon: FaBalanceScale },
    { value: 'FaBan', label: 'Ban', icon: FaBan },
    { value: 'FaBandAid', label: 'Band-Aid', icon: FaBandAid },
    { value: 'FaBarcode', label: 'Barcode', icon: FaBarcode },
    { value: 'FaBars', label: 'Bars', icon: FaBars },
    { value: 'FaBaseballBall', label: 'Baseball Ball', icon: FaBaseballBall },
    { value: 'FaBasketballBall', label: 'Basketball Ball', icon: FaBasketballBall },
    { value: 'FaBath', label: 'Bath', icon: FaBath },
    { value: 'FaBatteryEmpty', label: 'Battery Empty', icon: FaBatteryEmpty },
    { value: 'FaBatteryFull', label: 'Battery Full', icon: FaBatteryFull },
    { value: 'FaBatteryHalf', label: 'Battery Half', icon: FaBatteryHalf },
    { value: 'FaBatteryQuarter', label: 'Battery Quarter', icon: FaBatteryQuarter },
    { value: 'FaBatteryThreeQuarters', label: 'Battery Three Quarters', icon: FaBatteryThreeQuarters },
    { value: 'FaBed', label: 'Bed', icon: FaBed },
    { value: 'FaBeer', label: 'Beer', icon: FaBeer },
    { value: 'FaBell', label: 'Bell', icon: FaBell },
    { value: 'FaBellSlash', label: 'Bell Slash', icon: FaBellSlash },
    { value: 'FaBezierCurve', label: 'Bezier Curve', icon: FaBezierCurve },
    { value: 'FaBible', label: 'Bible', icon: FaBible },
    { value: 'FaBicycle', label: 'Bicycle', icon: FaBicycle },
    { value: 'FaBiking', label: 'Biking', icon: FaBiking },
    { value: 'FaBinoculars', label: 'Binoculars', icon: FaBinoculars },
    { value: 'FaBiohazard', label: 'Biohazard', icon: FaBiohazard },
    { value: 'FaBirthdayCake', label: 'Birthday Cake', icon: FaBirthdayCake },
    { value: 'FaBlender', label: 'Blender', icon: FaBlender },
    { value: 'FaBlenderPhone', label: 'Blender Phone', icon: FaBlenderPhone },
    { value: 'FaBlind', label: 'Blind', icon: FaBlind },
    { value: 'FaBlog', label: 'Blog', icon: FaBlog },
    { value: 'FaBold', label: 'Bold', icon: FaBold },
    { value: 'FaBolt', label: 'Bolt', icon: FaBolt },
    { value: 'FaBomb', label: 'Bomb', icon: FaBomb },
    { value: 'FaBone', label: 'Bone', icon: FaBone },
    { value: 'FaBong', label: 'Bong', icon: FaBong },
    { value: 'FaBook', label: 'Book', icon: FaBook },
    { value: 'FaBookDead', label: 'Book Dead', icon: FaBookDead },
    { value: 'FaBookMedical', label: 'Book Medical', icon: FaBookMedical },
    { value: 'FaBookOpen', label: 'Book Open', icon: FaBookOpen },
    { value: 'FaBookReader', label: 'Book Reader', icon: FaBookReader },
    { value: 'FaBookmark', label: 'Bookmark', icon: FaBookmark },
    { value: 'FaBorderAll', label: 'Border All', icon: FaBorderAll },
    { value: 'FaBorderNone', label: 'Border None', icon: FaBorderNone },
    { value: 'FaBorderStyle', label: 'Border Style', icon: FaBorderStyle },
    { value: 'FaBowlingBall', label: 'Bowling Ball', icon: FaBowlingBall },
    { value: 'FaBox', label: 'Box', icon: FaBox },
    { value: 'FaBoxOpen', label: 'Box Open', icon: FaBoxOpen },
    { value: 'FaBoxTissue', label: 'Box Tissue', icon: FaBoxTissue },
    { value: 'FaBoxes', label: 'Boxes', icon: FaBoxes },
    { value: 'FaBraille', label: 'Braille', icon: FaBraille },
    { value: 'FaBrain', label: 'Brain', icon: FaBrain },
    { value: 'FaBreadSlice', label: 'Bread Slice', icon: FaBreadSlice },
    { value: 'FaBriefcase', label: 'Briefcase', icon: FaBriefcase },
    { value: 'FaBriefcaseMedical', label: 'Briefcase Medical', icon: FaBriefcaseMedical },
    { value: 'FaBroadcastTower', label: 'Broadcast Tower', icon: FaBroadcastTower },
    { value: 'FaBroom', label: 'Broom', icon: FaBroom },
    { value: 'FaBrush', label: 'Brush', icon: FaBrush },
    { value: 'FaBug', label: 'Bug', icon: FaBug },
    { value: 'FaBuilding', label: 'Building', icon: FaBuilding },
    { value: 'FaBullhorn', label: 'Bullhorn', icon: FaBullhorn },
    { value: 'FaBullseye', label: 'Bullseye', icon: FaBullseye },
    { value: 'FaBurn', label: 'Burn', icon: FaBurn },
    { value: 'FaBus', label: 'Bus', icon: FaBus },
    { value: 'FaBusAlt', label: 'Bus Alt', icon: FaBusAlt },
    { value: 'FaBusinessTime', label: 'Business Time', icon: FaBusinessTime },
    { value: 'FaCalculator', label: 'Calculator', icon: FaCalculator },
    { value: 'FaCalendar', label: 'Calendar', icon: FaCalendar },
    { value: 'FaCalendarAlt', label: 'Calendar Alt', icon: FaCalendarAlt },
    { value: 'FaCalendarCheck', label: 'Calendar Check', icon: FaCalendarCheck },
    { value: 'FaCalendarDay', label: 'Calendar Day', icon: FaCalendarDay },
    { value: 'FaCalendarMinus', label: 'Calendar Minus', icon: FaCalendarMinus },
    { value: 'FaCalendarPlus', label: 'Calendar Plus', icon: FaCalendarPlus },
    { value: 'FaCalendarTimes', label: 'Calendar Times', icon: FaCalendarTimes },
    { value: 'FaCalendarWeek', label: 'Calendar Week', icon: FaCalendarWeek },
    { value: 'FaCamera', label: 'Camera', icon: FaCamera },
    { value: 'FaCameraRetro', label: 'Camera Retro', icon: FaCameraRetro },
    { value: 'FaCampground', label: 'Campground', icon: FaCampground },
    { value: 'FaCandyCane', label: 'Candy Cane', icon: FaCandyCane },
    { value: 'FaCannabis', label: 'Cannabis', icon: FaCannabis },
    { value: 'FaCapsules', label: 'Capsules', icon: FaCapsules },
    { value: 'FaCar', label: 'Car', icon: FaCar },
    { value: 'FaCarAlt', label: 'Car Alt', icon: FaCarAlt },
    { value: 'FaCarBattery', label: 'Car Battery', icon: FaCarBattery },
    { value: 'FaCarCrash', label: 'Car Crash', icon: FaCarCrash },
    { value: 'FaCarSide', label: 'Car Side', icon: FaCarSide },
    { value: 'FaCaravan', label: 'Caravan', icon: FaCaravan },
    { value: 'FaCaretDown', label: 'Caret Down', icon: FaCaretDown },
    { value: 'FaCaretLeft', label: 'Caret Left', icon: FaCaretLeft },
    { value: 'FaCaretRight', label: 'Caret Right', icon: FaCaretRight },
    { value: 'FaCaretSquareDown', label: 'Caret Square Down', icon: FaCaretSquareDown },
    { value: 'FaCaretSquareLeft', label: 'Caret Square Left', icon: FaCaretSquareLeft },
    { value: 'FaCaretSquareRight', label: 'Caret Square Right', icon: FaCaretSquareRight },
    { value: 'FaCaretSquareUp', label: 'Caret Square Up', icon: FaCaretSquareUp },
    { value: 'FaCaretUp', label: 'Caret Up', icon: FaCaretUp },
    { value: 'FaCarrot', label: 'Carrot', icon: FaCarrot },
    { value: 'FaCartArrowDown', label: 'Cart Arrow Down', icon: FaCartArrowDown },
    { value: 'FaCartPlus', label: 'Cart Plus', icon: FaCartPlus },
    { value: 'FaCashRegister', label: 'Cash Register', icon: FaCashRegister },
    { value: 'FaCat', label: 'Cat', icon: FaCat },
    { value: 'FaCertificate', label: 'Certificate', icon: FaCertificate },
    { value: 'FaChair', label: 'Chair', icon: FaChair },
    { value: 'FaChalkboard', label: 'Chalkboard', icon: FaChalkboard },
    { value: 'FaChalkboardTeacher', label: 'Chalkboard Teacher', icon: FaChalkboardTeacher },
    { value: 'FaChargingStation', label: 'Charging Station', icon: FaChargingStation },
    { value: 'FaChartArea', label: 'Chart Area', icon: FaChartArea },
    { value: 'FaChartBar', label: 'Chart Bar', icon: FaChartBar },
    { value: 'FaChartLine', label: 'Chart Line', icon: FaChartLine },
    { value: 'FaChartPie', label: 'Chart Pie', icon: FaChartPie },
    { value: 'FaCheck', label: 'Check', icon: FaCheck },
    { value: 'FaCheckCircle', label: 'Check Circle', icon: FaCheckCircle },
    { value: 'FaCheckDouble', label: 'Check Double', icon: FaCheckDouble },
    { value: 'FaCheckSquare', label: 'Check Square', icon: FaCheckSquare },
    { value: 'FaCheese', label: 'Cheese', icon: FaCheese },
    { value: 'FaChess', label: 'Chess', icon: FaChess },
    { value: 'FaChessBishop', label: 'Chess Bishop', icon: FaChessBishop },
    { value: 'FaChessBoard', label: 'Chess Board', icon: FaChessBoard },
    { value: 'FaChessKing', label: 'Chess King', icon: FaChessKing },
    { value: 'FaChessKnight', label: 'Chess Knight', icon: FaChessKnight },
    { value: 'FaChessPawn', label: 'Chess Pawn', icon: FaChessPawn },
    { value: 'FaChessQueen', label: 'Chess Queen', icon: FaChessQueen },
    { value: 'FaChessRook', label: 'Chess Rook', icon: FaChessRook },
    { value: 'FaChevronCircleDown', label: 'Chevron Circle Down', icon: FaChevronCircleDown },
    { value: 'FaChevronCircleLeft', label: 'Chevron Circle Left', icon: FaChevronCircleLeft },
    { value: 'FaChevronCircleRight', label: 'Chevron Circle Right', icon: FaChevronCircleRight },
    { value: 'FaChevronCircleUp', label: 'Chevron Circle Up', icon: FaChevronCircleUp },
    { value: 'FaChevronDown', label: 'Chevron Down', icon: FaChevronDown },
    { value: 'FaChevronLeft', label: 'Chevron Left', icon: FaChevronLeft },
    { value: 'FaChevronRight', label: 'Chevron Right', icon: FaChevronRight },
    { value: 'FaChevronUp', label: 'Chevron Up', icon: FaChevronUp },
    { value: 'FaChild', label: 'Child', icon: FaChild },
    { value: 'FaChurch', label: 'Church', icon: FaChurch },
    { value: 'FaCircle', label: 'Circle', icon: FaCircle },
    { value: 'FaCircleNotch', label: 'Circle Notch', icon: FaCircleNotch },
    { value: 'FaCity', label: 'City', icon: FaCity },
    { value: 'FaClinicMedical', label: 'Clinic Medical', icon: FaClinicMedical },
    { value: 'FaClipboard', label: 'Clipboard', icon: FaClipboard },
    { value: 'FaClipboardCheck', label: 'Clipboard Check', icon: FaClipboardCheck },
    { value: 'FaClipboardList', label: 'Clipboard List', icon: FaClipboardList },
    { value: 'FaClock', label: 'Clock', icon: FaClock },
    { value: 'FaClone', label: 'Clone', icon: FaClone },
    { value: 'FaClosedCaptioning', label: 'Closed Captioning', icon: FaClosedCaptioning },
    { value: 'FaCloud', label: 'Cloud', icon: FaCloud },
    { value: 'FaCloudDownloadAlt', label: 'Cloud Download Alt', icon: FaCloudDownloadAlt },
    { value: 'FaCloudMeatball', label: 'Cloud Meatball', icon: FaCloudMeatball },
    { value: 'FaCloudMoon', label: 'Cloud Moon', icon: FaCloudMoon },
    { value: 'FaCloudMoonRain', label: 'Cloud Moon Rain', icon: FaCloudMoonRain },
    { value: 'FaCloudRain', label: 'Cloud Rain', icon: FaCloudRain },
    { value: 'FaCloudShowersHeavy', label: 'Cloud Showers Heavy', icon: FaCloudShowersHeavy },
    { value: 'FaCloudSun', label: 'Cloud Sun', icon: FaCloudSun },
    { value: 'FaCloudSunRain', label: 'Cloud Sun Rain', icon: FaCloudSunRain },
    { value: 'FaCloudUploadAlt', label: 'Cloud Upload Alt', icon: FaCloudUploadAlt },
    { value: 'FaCocktail', label: 'Cocktail', icon: FaCocktail },
    { value: 'FaCode', label: 'Code', icon: FaCode },
    { value: 'FaCodeBranch', label: 'Code Branch', icon: FaCodeBranch },
    { value: 'FaCoffee', label: 'Coffee', icon: FaCoffee },
    { value: 'FaCog', label: 'Cog', icon: FaCog },
    { value: 'FaCogs', label: 'Cogs', icon: FaCogs },
    { value: 'FaCoins', label: 'Coins', icon: FaCoins },
    { value: 'FaColumns', label: 'Columns', icon: FaColumns },
    { value: 'FaComment', label: 'Comment', icon: FaComment },
    { value: 'FaCommentAlt', label: 'Comment Alt', icon: FaCommentAlt },
    { value: 'FaCommentDollar', label: 'Comment Dollar', icon: FaCommentDollar },
    { value: 'FaCommentDots', label: 'Comment Dots', icon: FaCommentDots },
    { value: 'FaCommentMedical', label: 'Comment Medical', icon: FaCommentMedical },
    { value: 'FaCommentSlash', label: 'Comment Slash', icon: FaCommentSlash },
    { value: 'FaComments', label: 'Comments', icon: FaComments },
    { value: 'FaCompactDisc', label: 'Compact Disc', icon: FaCompactDisc },
    { value: 'FaCompass', label: 'Compass', icon: FaCompass },
    { value: 'FaCompress', label: 'Compress', icon: FaCompress },
    { value: 'FaCompressAlt', label: 'Compress Alt', icon: FaCompressAlt },
    { value: 'FaCompressArrowsAlt', label: 'Compress Arrows Alt', icon: FaCompressArrowsAlt },
    { value: 'FaConciergeBell', label: 'Concierge Bell', icon: FaConciergeBell },
    { value: 'FaCookie', label: 'Cookie', icon: FaCookie },
    { value: 'FaCookieBite', label: 'Cookie Bite', icon: FaCookieBite },
    { value: 'FaCopy', label: 'Copy', icon: FaCopy },
    { value: 'FaCopyright', label: 'Copyright', icon: FaCopyright },
    { value: 'FaCouch', label: 'Couch', icon: FaCouch },
    { value: 'FaCreditCard', label: 'Credit Card', icon: FaCreditCard },
    { value: 'FaCrop', label: 'Crop', icon: FaCrop },
    { value: 'FaCropAlt', label: 'Crop Alt', icon: FaCropAlt },
    { value: 'FaCross', label: 'Cross', icon: FaCross },
    { value: 'FaCrosshairs', label: 'Crosshairs', icon: FaCrosshairs },
    { value: 'FaCrow', label: 'Crow', icon: FaCrow },
    { value: 'FaCrown', label: 'Crown', icon: FaCrown },
    { value: 'FaCrutch', label: 'Crutch', icon: FaCrutch },
    { value: 'FaCube', label: 'Cube', icon: FaCube },
    { value: 'FaCubes', label: 'Cubes', icon: FaCubes },
    { value: 'FaCut', label: 'Cut', icon: FaCut },
    { value: 'FaDatabase', label: 'Database', icon: FaDatabase },
    { value: 'FaDeaf', label: 'Deaf', icon: FaDeaf },
    { value: 'FaDemocrat', label: 'Democrat', icon: FaDemocrat },
    { value: 'FaDesktop', label: 'Desktop', icon: FaDesktop },
    { value: 'FaDharmachakra', label: 'Dharmachakra', icon: FaDharmachakra },
    { value: 'FaDiagnoses', label: 'Diagnoses', icon: FaDiagnoses },
    { value: 'FaDice', label: 'Dice', icon: FaDice },
    { value: 'FaDiceD20', label: 'Dice D20', icon: FaDiceD20 },
    { value: 'FaDiceD6', label: 'Dice D6', icon: FaDiceD6 },
    { value: 'FaDiceFive', label: 'Dice Five', icon: FaDiceFive },
    { value: 'FaDiceFour', label: 'Dice Four', icon: FaDiceFour },
    { value: 'FaDiceOne', label: 'Dice One', icon: FaDiceOne },
    { value: 'FaDiceSix', label: 'Dice Six', icon: FaDiceSix },
    { value: 'FaDiceThree', label: 'Dice Three', icon: FaDiceThree },
    { value: 'FaDiceTwo', label: 'Dice Two', icon: FaDiceTwo },
    { value: 'FaDigitalTachograph', label: 'Digital Tachograph', icon: FaDigitalTachograph },
    { value: 'FaDirections', label: 'Directions', icon: FaDirections },
    { value: 'FaDisease', label: 'Disease', icon: FaDisease },
    { value: 'FaDivide', label: 'Divide', icon: FaDivide },
    { value: 'FaDizzy', label: 'Dizzy', icon: FaDizzy },
    { value: 'FaDna', label: 'Dna', icon: FaDna },
    { value: 'FaDog', label: 'Dog', icon: FaDog },
    { value: 'FaDollarSign', label: 'Dollar Sign', icon: FaDollarSign },
    { value: 'FaDolly', label: 'Dolly', icon: FaDolly },
    { value: 'FaDollyFlatbed', label: 'Dolly Flatbed', icon: FaDollyFlatbed },
    { value: 'FaDonate', label: 'Donate', icon: FaDonate },
    { value: 'FaDoorClosed', label: 'Door Closed', icon: FaDoorClosed },
    { value: 'FaDoorOpen', label: 'Door Open', icon: FaDoorOpen },
    { value: 'FaDotCircle', label: 'Dot Circle', icon: FaDotCircle },
    { value: 'FaDove', label: 'Dove', icon: FaDove },
    { value: 'FaDownload', label: 'Download', icon: FaDownload },
    { value: 'FaDraftingCompass', label: 'Drafting Compass', icon: FaDraftingCompass },
    { value: 'FaDragon', label: 'Dragon', icon: FaDragon },
    { value: 'FaDrawPolygon', label: 'Draw Polygon', icon: FaDrawPolygon },
    { value: 'FaDrum', label: 'Drum', icon: FaDrum },
    { value: 'FaDrumSteelpan', label: 'Drum Steelpan', icon: FaDrumSteelpan },
    { value: 'FaDrumstickBite', label: 'Drumstick Bite', icon: FaDrumstickBite },
    { value: 'FaDumbbell', label: 'Dumbbell', icon: FaDumbbell },
    { value: 'FaDumpster', label: 'Dumpster', icon: FaDumpster },
    { value: 'FaDumpsterFire', label: 'Dumpster Fire', icon: FaDumpsterFire },
    { value: 'FaDungeon', label: 'Dungeon', icon: FaDungeon },
    { value: 'FaEdit', label: 'Edit', icon: FaEdit },
    { value: 'FaEgg', label: 'Egg', icon: FaEgg },
    { value: 'FaEject', label: 'Eject', icon: FaEject },
    { value: 'FaEllipsisH', label: 'Ellipsis H', icon: FaEllipsisH },
    { value: 'FaEllipsisV', label: 'Ellipsis V', icon: FaEllipsisV },
    { value: 'FaEnvelope', label: 'Envelope', icon: FaEnvelope },
    { value: 'FaEnvelopeOpen', label: 'Envelope Open', icon: FaEnvelopeOpen },
    { value: 'FaEnvelopeOpenText', label: 'Envelope Open Text', icon: FaEnvelopeOpenText },
    { value: 'FaEnvelopeSquare', label: 'Envelope Square', icon: FaEnvelopeSquare },
    { value: 'FaEquals', label: 'Equals', icon: FaEquals },
    { value: 'FaEraser', label: 'Eraser', icon: FaEraser },
    { value: 'FaEthernet', label: 'Ethernet', icon: FaEthernet },
    { value: 'FaEuroSign', label: 'Euro Sign', icon: FaEuroSign },
    { value: 'FaExchangeAlt', label: 'Exchange Alt', icon: FaExchangeAlt },
    { value: 'FaExclamation', label: 'Exclamation', icon: FaExclamation },
    { value: 'FaExclamationCircle', label: 'Exclamation Circle', icon: FaExclamationCircle },
    { value: 'FaExclamationTriangle', label: 'Exclamation Triangle', icon: FaExclamationTriangle },
    { value: 'FaExpand', label: 'Expand', icon: FaExpand },
    { value: 'FaExpandAlt', label: 'Expand Alt', icon: FaExpandAlt },
    { value: 'FaExpandArrowsAlt', label: 'Expand Arrows Alt', icon: FaExpandArrowsAlt },
    { value: 'FaExternalLinkAlt', label: 'External Link Alt', icon: FaExternalLinkAlt },
    { value: 'FaExternalLinkSquareAlt', label: 'External Link Square Alt', icon: FaExternalLinkSquareAlt },
    { value: 'FaEye', label: 'Eye', icon: FaEye },
    { value: 'FaEyeDropper', label: 'Eye Dropper', icon: FaEyeDropper },
    { value: 'FaEyeSlash', label: 'Eye Slash', icon: FaEyeSlash },
    { value: 'FaFan', label: 'Fan', icon: FaFan },
    { value: 'FaFastBackward', label: 'Fast Backward', icon: FaFastBackward },
    { value: 'FaFastForward', label: 'Fast Forward', icon: FaFastForward },
    { value: 'FaFax', label: 'Fax', icon: FaFax },
    { value: 'FaFeather', label: 'Feather', icon: FaFeather },
    { value: 'FaFeatherAlt', label: 'Feather Alt', icon: FaFeatherAlt },
    { value: 'FaFemale', label: 'Female', icon: FaFemale },
    { value: 'FaFighterJet', label: 'Fighter Jet', icon: FaFighterJet },
    { value: 'FaFile', label: 'File', icon: FaFile },
    { value: 'FaFileAlt', label: 'File Alt', icon: FaFileAlt },
    { value: 'FaFileArchive', label: 'File Archive', icon: FaFileArchive },
    { value: 'FaFileAudio', label: 'File Audio', icon: FaFileAudio },
    { value: 'FaFileCode', label: 'File Code', icon: FaFileCode },
    { value: 'FaFileContract', label: 'File Contract', icon: FaFileContract },
    { value: 'FaFileCsv', label: 'File Csv', icon: FaFileCsv },
    { value: 'FaFileDownload', label: 'File Download', icon: FaFileDownload },
    { value: 'FaFileExcel', label: 'File Excel', icon: FaFileExcel },
    { value: 'FaFileExport', label: 'File Export', icon: FaFileExport },
    { value: 'FaFileImage', label: 'File Image', icon: FaFileImage },
    { value: 'FaFileImport', label: 'File Import', icon: FaFileImport },
    { value: 'FaFileInvoice', label: 'File Invoice', icon: FaFileInvoice },
    { value: 'FaFileInvoiceDollar', label: 'File Invoice Dollar', icon: FaFileInvoiceDollar },
    { value: 'FaFileMedical', label: 'File Medical', icon: FaFileMedical },
    { value: 'FaFileMedicalAlt', label: 'File Medical Alt', icon: FaFileMedicalAlt },
    { value: 'FaFilePdf', label: 'File Pdf', icon: FaFilePdf },
    { value: 'FaFilePowerpoint', label: 'File Powerpoint', icon: FaFilePowerpoint },
    { value: 'FaFilePrescription', label: 'File Prescription', icon: FaFilePrescription },
    { value: 'FaFileSignature', label: 'File Signature', icon: FaFileSignature },
    { value: 'FaFileUpload', label: 'File Upload', icon: FaFileUpload },
    { value: 'FaFileVideo', label: 'File Video', icon: FaFileVideo },
    { value: 'FaFileWord', label: 'File Word', icon: FaFileWord },
    { value: 'FaFill', label: 'Fill', icon: FaFill },
    { value: 'FaFillDrip', label: 'Fill Drip', icon: FaFillDrip },
    { value: 'FaFilm', label: 'Film', icon: FaFilm },
    { value: 'FaFilter', label: 'Filter', icon: FaFilter },
    { value: 'FaFingerprint', label: 'Fingerprint', icon: FaFingerprint },
    { value: 'FaFire', label: 'Fire', icon: FaFire },
    { value: 'FaFireAlt', label: 'Fire Alt', icon: FaFireAlt },
    { value: 'FaFireExtinguisher', label: 'Fire Extinguisher', icon: FaFireExtinguisher },
    { value: 'FaFirstAid', label: 'First Aid', icon: FaFirstAid },
    { value: 'FaFish', label: 'Fish', icon: FaFish },
    { value: 'FaFistRaised', label: 'Fist Raised', icon: FaFistRaised },
    { value: 'FaFlag', label: 'Flag', icon: FaFlag },
    { value: 'FaFlagCheckered', label: 'Flag Checkered', icon: FaFlagCheckered },
    { value: 'FaFlagUsa', label: 'Flag Usa', icon: FaFlagUsa },
    { value: 'FaFlask', label: 'Flask', icon: FaFlask },
    { value: 'FaFlushed', label: 'Flushed', icon: FaFlushed },
    { value: 'FaFolder', label: 'Folder', icon: FaFolder },
    { value: 'FaFolderMinus', label: 'Folder Minus', icon: FaFolderMinus },
    { value: 'FaFolderOpen', label: 'Folder Open', icon: FaFolderOpen },
    { value: 'FaFolderPlus', label: 'Folder Plus', icon: FaFolderPlus },
    { value: 'FaFont', label: 'Font', icon: FaFont },
    { value: 'FaFootballBall', label: 'Football Ball', icon: FaFootballBall },
    { value: 'FaForward', label: 'Forward', icon: FaForward },
    { value: 'FaFrog', label: 'Frog', icon: FaFrog },
    { value: 'FaFrown', label: 'Frown', icon: FaFrown },
    { value: 'FaFrownOpen', label: 'Frown Open', icon: FaFrownOpen },
    { value: 'FaFunnelDollar', label: 'Funnel Dollar', icon: FaFunnelDollar },
    { value: 'FaFutbol', label: 'Futbol', icon: FaFutbol },
    { value: 'FaGamepad', label: 'Gamepad', icon: FaGamepad },
    { value: 'FaGasPump', label: 'Gas Pump', icon: FaGasPump },
    { value: 'FaGavel', label: 'Gavel', icon: FaGavel },
    { value: 'FaGem', label: 'Gem', icon: FaGem },
    { value: 'FaGenderless', label: 'Genderless', icon: FaGenderless },
    { value: 'FaGhost', label: 'Ghost', icon: FaGhost },
    { value: 'FaGift', label: 'Gift', icon: FaGift },
    { value: 'FaGifts', label: 'Gifts', icon: FaGifts },
    { value: 'FaGlassCheers', label: 'Glass Cheers', icon: FaGlassCheers },
    { value: 'FaGlassMartini', label: 'Glass Martini', icon: FaGlassMartini },
    { value: 'FaGlassMartiniAlt', label: 'Glass Martini Alt', icon: FaGlassMartiniAlt },
    { value: 'FaGlassWhiskey', label: 'Glass Whiskey', icon: FaGlassWhiskey },
    { value: 'FaGlasses', label: 'Glasses', icon: FaGlasses },
    { value: 'FaGlobe', label: 'Globe', icon: FaGlobe },
    { value: 'FaGlobeAfrica', label: 'Globe Africa', icon: FaGlobeAfrica },
    { value: 'FaGlobeAmericas', label: 'Globe Americas', icon: FaGlobeAmericas },
    { value: 'FaGlobeAsia', label: 'Globe Asia', icon: FaGlobeAsia },
    { value: 'FaGlobeEurope', label: 'Globe Europe', icon: FaGlobeEurope },
    { value: 'FaGolfBall', label: 'Golf Ball', icon: FaGolfBall },
    { value: 'FaGopuram', label: 'Gopuram', icon: FaGopuram },
    { value: 'FaGraduationCap', label: 'Graduation Cap', icon: FaGraduationCap },
    { value: 'FaGreaterThan', label: 'Greater Than', icon: FaGreaterThan },
    { value: 'FaGreaterThanEqual', label: 'Greater Than Equal', icon: FaGreaterThanEqual },
    { value: 'FaGrimace', label: 'Grimace', icon: FaGrimace },
    { value: 'FaGrin', label: 'Grin', icon: FaGrin },
    { value: 'FaGrinAlt', label: 'Grin Alt', icon: FaGrinAlt },
    { value: 'FaGrinBeam', label: 'Grin Beam', icon: FaGrinBeam },
    { value: 'FaGrinBeamSweat', label: 'Grin Beam Sweat', icon: FaGrinBeamSweat },
    { value: 'FaGrinHearts', label: 'Grin Hearts', icon: FaGrinHearts },
    { value: 'FaGrinSquint', label: 'Grin Squint', icon: FaGrinSquint },
    { value: 'FaGrinSquintTears', label: 'Grin Squint Tears', icon: FaGrinSquintTears },
    { value: 'FaGrinStars', label: 'Grin Stars', icon: FaGrinStars },
    { value: 'FaGrinTears', label: 'Grin Tears', icon: FaGrinTears },
    { value: 'FaGrinTongue', label: 'Grin Tongue', icon: FaGrinTongue },
    { value: 'FaGrinTongueSquint', label: 'Grin Tongue Squint', icon: FaGrinTongueSquint },
    { value: 'FaGrinTongueWink', label: 'Grin Tongue Wink', icon: FaGrinTongueWink },
    { value: 'FaGrinWink', label: 'Grin Wink', icon: FaGrinWink },
    { value: 'FaGripHorizontal', label: 'Grip Horizontal', icon: FaGripHorizontal },
    { value: 'FaGripLines', label: 'Grip Lines', icon: FaGripLines },
    { value: 'FaGripLinesVertical', label: 'Grip Lines Vertical', icon: FaGripLinesVertical },
    { value: 'FaGripVertical', label: 'Grip Vertical', icon: FaGripVertical },
    { value: 'FaGuitar', label: 'Guitar', icon: FaGuitar },
    { value: 'FaHSquare', label: 'H Square', icon: FaHSquare },
    { value: 'FaHamburger', label: 'Hamburger', icon: FaHamburger },
    { value: 'FaHammer', label: 'Hammer', icon: FaHammer },
    { value: 'FaHamsa', label: 'Hamsa', icon: FaHamsa },
    { value: 'FaHandHolding', label: 'Hand Holding', icon: FaHandHolding },
    { value: 'FaHandHoldingHeart', label: 'Hand Holding Heart', icon: FaHandHoldingHeart },
    { value: 'FaHandHoldingUsd', label: 'Hand Holding Usd', icon: FaHandHoldingUsd },
    { value: 'FaHandLizard', label: 'Hand Lizard', icon: FaHandLizard },
    { value: 'FaHandMiddleFinger', label: 'Hand Middle Finger', icon: FaHandMiddleFinger },
    { value: 'FaHandPaper', label: 'Hand Paper', icon: FaHandPaper },
    { value: 'FaHandPeace', label: 'Hand Peace', icon: FaHandPeace },
    { value: 'FaHandPointDown', label: 'Hand Point Down', icon: FaHandPointDown },
    { value: 'FaHandPointLeft', label: 'Hand Point Left', icon: FaHandPointLeft },
    { value: 'FaHandPointRight', label: 'Hand Point Right', icon: FaHandPointRight },
    { value: 'FaHandPointUp', label: 'Hand Point Up', icon: FaHandPointUp },
    { value: 'FaHandPointer', label: 'Hand Pointer', icon: FaHandPointer },
    { value: 'FaHandRock', label: 'Hand Rock', icon: FaHandRock },
    { value: 'FaHandScissors', label: 'Hand Scissors', icon: FaHandScissors },
    { value: 'FaHandSpock', label: 'Hand Spock', icon: FaHandSpock },
    { value: 'FaHands', label: 'Hands', icon: FaHands },
    { value: 'FaHandsHelping', label: 'Hands Helping', icon: FaHandsHelping },
    { value: 'FaHandshake', label: 'Handshake', icon: FaHandshake },
    { value: 'FaHanukiah', label: 'Hanukiah', icon: FaHanukiah },
    { value: 'FaHardHat', label: 'Hard Hat', icon: FaHardHat },
    { value: 'FaHashtag', label: 'Hashtag', icon: FaHashtag },
    { value: 'FaHatCowboy', label: 'Hat Cowboy', icon: FaHatCowboy },
    { value: 'FaHatCowboySide', label: 'Hat Cowboy Side', icon: FaHatCowboySide },
    { value: 'FaHatWizard', label: 'Hat Wizard', icon: FaHatWizard },
    { value: 'FaHdd', label: 'Hdd', icon: FaHdd },
    { value: 'FaHeading', label: 'Heading', icon: FaHeading },
    { value: 'FaHeadphones', label: 'Headphones', icon: FaHeadphones },
    { value: 'FaHeadphonesAlt', label: 'Headphones Alt', icon: FaHeadphonesAlt },
    { value: 'FaHeadset', label: 'Headset', icon: FaHeadset },
    { value: 'FaHeart', label: 'Heart', icon: FaHeart },
    { value: 'FaHeartBroken', label: 'Heart Broken', icon: FaHeartBroken },
    { value: 'FaHeartbeat', label: 'Heartbeat', icon: FaHeartbeat },
    { value: 'FaHelicopter', label: 'Helicopter', icon: FaHelicopter },
    { value: 'FaHighlighter', label: 'Highlighter', icon: FaHighlighter },
    { value: 'FaHiking', label: 'Hiking', icon: FaHiking },
    { value: 'FaHippo', label: 'Hippo', icon: FaHippo },
    { value: 'FaHistory', label: 'History', icon: FaHistory },
    { value: 'FaHockeyPuck', label: 'Hockey Puck', icon: FaHockeyPuck },
    { value: 'FaHollyBerry', label: 'Holly Berry', icon: FaHollyBerry },
    { value: 'FaHome', label: 'Home', icon: FaHome },
    { value: 'FaHorse', label: 'Horse', icon: FaHorse },
    { value: 'FaHorseHead', label: 'Horse Head', icon: FaHorseHead },
    { value: 'FaHospital', label: 'Hospital', icon: FaHospital },
    { value: 'FaHospitalAlt', label: 'Hospital Alt', icon: FaHospitalAlt },
    { value: 'FaHospitalSymbol', label: 'Hospital Symbol', icon: FaHospitalSymbol },
    { value: 'FaHotTub', label: 'Hot Tub', icon: FaHotTub },
    { value: 'FaHotdog', label: 'Hotdog', icon: FaHotdog },
    { value: 'FaHotel', label: 'Hotel', icon: FaHotel },
    { value: 'FaHourglass', label: 'Hourglass', icon: FaHourglass },
    { value: 'FaHourglassEnd', label: 'Hourglass End', icon: FaHourglassEnd },
    { value: 'FaHourglassHalf', label: 'Hourglass Half', icon: FaHourglassHalf },
    { value: 'FaHourglassStart', label: 'Hourglass Start', icon: FaHourglassStart },
    { value: 'FaHouseDamage', label: 'House Damage', icon: FaHouseDamage },
    { value: 'FaHryvnia', label: 'Hryvnia', icon: FaHryvnia },
    { value: 'FaICursor', label: 'I Cursor', icon: FaICursor },
    { value: 'FaIceCream', label: 'Ice Cream', icon: FaIceCream },
    { value: 'FaIcicles', label: 'Icicles', icon: FaIcicles },
    { value: 'FaIcons', label: 'Icons', icon: FaIcons },
    { value: 'FaIdBadge', label: 'Id Badge', icon: FaIdBadge },
    { value: 'FaIdCard', label: 'Id Card', icon: FaIdCard },
    { value: 'FaIdCardAlt', label: 'Id Card Alt', icon: FaIdCardAlt },
    { value: 'FaIgloo', label: 'Igloo', icon: FaIgloo },
    { value: 'FaImage', label: 'Image', icon: FaImage },
    { value: 'FaImages', label: 'Images', icon: FaImages },
    { value: 'FaInbox', label: 'Inbox', icon: FaInbox },
    { value: 'FaIndent', label: 'Indent', icon: FaIndent },
    { value: 'FaIndustry', label: 'Industry', icon: FaIndustry },
    { value: 'FaInfinity', label: 'Infinity', icon: FaInfinity },
    { value: 'FaInfo', label: 'Info', icon: FaInfo },
    { value: 'FaInfoCircle', label: 'Info Circle', icon: FaInfoCircle },
    { value: 'FaItalic', label: 'Italic', icon: FaItalic },
    { value: 'FaJedi', label: 'Jedi', icon: FaJedi },
    { value: 'FaJoint', label: 'Joint', icon: FaJoint },
    { value: 'FaJournalWhills', label: 'Journal Whills', icon: FaJournalWhills },
    { value: 'FaKaaba', label: 'Kaaba', icon: FaKaaba },
    { value: 'FaKey', label: 'Key', icon: FaKey },
    { value: 'FaKeyboard', label: 'Keyboard', icon: FaKeyboard },
    { value: 'FaKhanda', label: 'Khanda', icon: FaKhanda },
    { value: 'FaKiss', label: 'Kiss', icon: FaKiss },
    { value: 'FaKissBeam', label: 'Kiss Beam', icon: FaKissBeam },
    { value: 'FaKissWinkHeart', label: 'Kiss Wink Heart', icon: FaKissWinkHeart },
    { value: 'FaKiwiBird', label: 'Kiwi Bird', icon: FaKiwiBird },
    { value: 'FaLandmark', label: 'Landmark', icon: FaLandmark },
    { value: 'FaLanguage', label: 'Language', icon: FaLanguage },
    { value: 'FaLaptop', label: 'Laptop', icon: FaLaptop },
    { value: 'FaLaptopCode', label: 'Laptop Code', icon: FaLaptopCode },
    { value: 'FaLaptopMedical', label: 'Laptop Medical', icon: FaLaptopMedical },
    { value: 'FaLaugh', label: 'Laugh', icon: FaLaugh },
    { value: 'FaLaughBeam', label: 'Laugh Beam', icon: FaLaughBeam },
    { value: 'FaLaughSquint', label: 'Laugh Squint', icon: FaLaughSquint },
    { value: 'FaLaughWink', label: 'Laugh Wink', icon: FaLaughWink },
    { value: 'FaLayerGroup', label: 'Layer Group', icon: FaLayerGroup },
    { value: 'FaLeaf', label: 'Leaf', icon: FaLeaf },
    { value: 'FaLemon', label: 'Lemon', icon: FaLemon },
    { value: 'FaLessThan', label: 'Less Than', icon: FaLessThan },
    { value: 'FaLessThanEqual', label: 'Less Than Equal', icon: FaLessThanEqual },
    { value: 'FaLevelDownAlt', label: 'Level Down Alt', icon: FaLevelDownAlt },
    { value: 'FaLevelUpAlt', label: 'Level Up Alt', icon: FaLevelUpAlt },
    { value: 'FaLifeRing', label: 'Life Ring', icon: FaLifeRing },
    { value: 'FaLightbulb', label: 'Lightbulb', icon: FaLightbulb },
    { value: 'FaLink', label: 'Link', icon: FaLink },
    { value: 'FaLiraSign', label: 'Lira Sign', icon: FaLiraSign },
    { value: 'FaList', label: 'List', icon: FaList },
    { value: 'FaListAlt', label: 'List Alt', icon: FaListAlt },
    { value: 'FaListOl', label: 'List Ol', icon: FaListOl },
    { value: 'FaListUl', label: 'List Ul', icon: FaListUl },
    { value: 'FaLocationArrow', label: 'Location Arrow', icon: FaLocationArrow },
    { value: 'FaLock', label: 'Lock', icon: FaLock },
    { value: 'FaLockOpen', label: 'Lock Open', icon: FaLockOpen },
    { value: 'FaLongArrowAltDown', label: 'Long Arrow Alt Down', icon: FaLongArrowAltDown },
    { value: 'FaLongArrowAltLeft', label: 'Long Arrow Alt Left', icon: FaLongArrowAltLeft },
    { value: 'FaLongArrowAltRight', label: 'Long Arrow Alt Right', icon: FaLongArrowAltRight },
    { value: 'FaLongArrowAltUp', label: 'Long Arrow Alt Up', icon: FaLongArrowAltUp },
    { value: 'FaLowVision', label: 'Low Vision', icon: FaLowVision },
    { value: 'FaLuggageCart', label: 'Luggage Cart', icon: FaLuggageCart },
    { value: 'FaMagic', label: 'Magic', icon: FaMagic },
    { value: 'FaMagnet', label: 'Magnet', icon: FaMagnet },
    { value: 'FaMailBulk', label: 'Mail Bulk', icon: FaMailBulk },
    { value: 'FaMale', label: 'Male', icon: FaMale },
    { value: 'FaMap', label: 'Map', icon: FaMap },
    { value: 'FaMapMarked', label: 'Map Marked', icon: FaMapMarked },
    { value: 'FaMapMarkedAlt', label: 'Map Marked Alt', icon: FaMapMarkedAlt },
    { value: 'FaMapMarker', label: 'Map Marker', icon: FaMapMarker },
    { value: 'FaMapMarkerAlt', label: 'Map Marker Alt', icon: FaMapMarkerAlt },
    { value: 'FaMapPin', label: 'Map Pin', icon: FaMapPin },
    { value: 'FaMapSigns', label: 'Map Signs', icon: FaMapSigns },
    { value: 'FaMarker', label: 'Marker', icon: FaMarker },
    { value: 'FaMars', label: 'Mars', icon: FaMars },
    { value: 'FaMarsDouble', label: 'Mars Double', icon: FaMarsDouble },
    { value: 'FaMarsStroke', label: 'Mars Stroke', icon: FaMarsStroke },
    { value: 'FaMarsStrokeH', label: 'Mars Stroke H', icon: FaMarsStrokeH },
    { value: 'FaMarsStrokeV', label: 'Mars Stroke V', icon: FaMarsStrokeV },
    { value: 'FaMask', label: 'Mask', icon: FaMask },
    { value: 'FaMedal', label: 'Medal', icon: FaMedal },
    { value: 'FaMedkit', label: 'Medkit', icon: FaMedkit },
    { value: 'FaMeh', label: 'Meh', icon: FaMeh },
    { value: 'FaMehBlank', label: 'Meh Blank', icon: FaMehBlank },
    { value: 'FaMehRollingEyes', label: 'Meh Rolling Eyes', icon: FaMehRollingEyes },
    { value: 'FaMemory', label: 'Memory', icon: FaMemory },
    { value: 'FaMenorah', label: 'Menorah', icon: FaMenorah },
    { value: 'FaMercury', label: 'Mercury', icon: FaMercury },
    { value: 'FaMeteor', label: 'Meteor', icon: FaMeteor },
    { value: 'FaMicrochip', label: 'Microchip', icon: FaMicrochip },
    { value: 'FaMicrophone', label: 'Microphone', icon: FaMicrophone },
    { value: 'FaMicrophoneAlt', label: 'Microphone Alt', icon: FaMicrophoneAlt },
    { value: 'FaMicrophoneAltSlash', label: 'Microphone Alt Slash', icon: FaMicrophoneAltSlash },
    { value: 'FaMicrophoneSlash', label: 'Microphone Slash', icon: FaMicrophoneSlash },
    { value: 'FaMicroscope', label: 'Microscope', icon: FaMicroscope },
    { value: 'FaMinus', label: 'Minus', icon: FaMinus },
    { value: 'FaMinusCircle', label: 'Minus Circle', icon: FaMinusCircle },
    { value: 'FaMinusSquare', label: 'Minus Square', icon: FaMinusSquare },
    { value: 'FaMitten', label: 'Mitten', icon: FaMitten },
    { value: 'FaMobile', label: 'Mobile', icon: FaMobile },
    { value: 'FaMobileAlt', label: 'Mobile Alt', icon: FaMobileAlt },
    { value: 'FaMoneyBill', label: 'Money Bill', icon: FaMoneyBill },
    { value: 'FaMoneyBillAlt', label: 'Money Bill Alt', icon: FaMoneyBillAlt },
    { value: 'FaMoneyBillWave', label: 'Money Bill Wave', icon: FaMoneyBillWave },
    { value: 'FaMoneyBillWaveAlt', label: 'Money Bill Wave Alt', icon: FaMoneyBillWaveAlt },
    { value: 'FaMoneyCheck', label: 'Money Check', icon: FaMoneyCheck },
    { value: 'FaMoneyCheckAlt', label: 'Money Check Alt', icon: FaMoneyCheckAlt },
    { value: 'FaMonument', label: 'Monument', icon: FaMonument },
    { value: 'FaMoon', label: 'Moon', icon: FaMoon },
    { value: 'FaMortarPestle', label: 'Mortar Pestle', icon: FaMortarPestle },
    { value: 'FaMosque', label: 'Mosque', icon: FaMosque },
    { value: 'FaMotorcycle', label: 'Motorcycle', icon: FaMotorcycle },
    { value: 'FaMountain', label: 'Mountain', icon: FaMountain },
    { value: 'FaMouse', label: 'Mouse', icon: FaMouse },
    { value: 'FaMousePointer', label: 'Mouse Pointer', icon: FaMousePointer },
    { value: 'FaMugHot', label: 'Mug Hot', icon: FaMugHot },
    { value: 'FaMusic', label: 'Music', icon: FaMusic },
    { value: 'FaNetworkWired', label: 'Network Wired', icon: FaNetworkWired },
    { value: 'FaNeuter', label: 'Neuter', icon: FaNeuter },
    { value: 'FaNewspaper', label: 'Newspaper', icon: FaNewspaper },
    { value: 'FaNotEqual', label: 'Not Equal', icon: FaNotEqual },
    { value: 'FaNotesMedical', label: 'Notes Medical', icon: FaNotesMedical },
    { value: 'FaObjectGroup', label: 'Object Group', icon: FaObjectGroup },
    { value: 'FaObjectUngroup', label: 'Object Ungroup', icon: FaObjectUngroup },
    { value: 'FaOilCan', label: 'Oil Can', icon: FaOilCan },
    { value: 'FaOm', label: 'Om', icon: FaOm },
    { value: 'FaOtter', label: 'Otter', icon: FaOtter },
    { value: 'FaOutdent', label: 'Outdent', icon: FaOutdent },
    { value: 'FaPager', label: 'Pager', icon: FaPager },
    { value: 'FaPaintBrush', label: 'Paint Brush', icon: FaPaintBrush },
    { value: 'FaPaintRoller', label: 'Paint Roller', icon: FaPaintRoller },
    { value: 'FaPalette', label: 'Palette', icon: FaPalette },
    { value: 'FaPallet', label: 'Pallet', icon: FaPallet },
    { value: 'FaPaperPlane', label: 'Paper Plane', icon: FaPaperPlane },
    { value: 'FaPaperclip', label: 'Paperclip', icon: FaPaperclip },
    { value: 'FaParachuteBox', label: 'Parachute Box', icon: FaParachuteBox },
    { value: 'FaParagraph', label: 'Paragraph', icon: FaParagraph },
    { value: 'FaParking', label: 'Parking', icon: FaParking },
    { value: 'FaPassport', label: 'Passport', icon: FaPassport },
    { value: 'FaPastafarianism', label: 'Pastafarianism', icon: FaPastafarianism },
    { value: 'FaPaste', label: 'Paste', icon: FaPaste },
    { value: 'FaPause', label: 'Pause', icon: FaPause },
    { value: 'FaPauseCircle', label: 'Pause Circle', icon: FaPauseCircle },
    { value: 'FaPaw', label: 'Paw', icon: FaPaw },
    { value: 'FaPeace', label: 'Peace', icon: FaPeace },
    { value: 'FaPen', label: 'Pen', icon: FaPen },
    { value: 'FaPenAlt', label: 'Pen Alt', icon: FaPenAlt },
    { value: 'FaPenFancy', label: 'Pen Fancy', icon: FaPenFancy },
    { value: 'FaPenNib', label: 'Pen Nib', icon: FaPenNib },
    { value: 'FaPenSquare', label: 'Pen Square', icon: FaPenSquare },
    { value: 'FaPencilAlt', label: 'Pencil Alt', icon: FaPencilAlt },
    { value: 'FaPencilRuler', label: 'Pencil Ruler', icon: FaPencilRuler },
    { value: 'FaPeopleCarry', label: 'People Carry', icon: FaPeopleCarry },
    { value: 'FaPepperHot', label: 'Pepper Hot', icon: FaPepperHot },
    { value: 'FaPercent', label: 'Percent', icon: FaPercent },
    { value: 'FaPercentage', label: 'Percentage', icon: FaPercentage },
    { value: 'FaPersonBooth', label: 'Person Booth', icon: FaPersonBooth },
    { value: 'FaPhone', label: 'Phone', icon: FaPhone },
    { value: 'FaPhoneAlt', label: 'Phone Alt', icon: FaPhoneAlt },
    { value: 'FaPhoneSlash', label: 'Phone Slash', icon: FaPhoneSlash },
    { value: 'FaPhoneSquare', label: 'Phone Square', icon: FaPhoneSquare },
    { value: 'FaPhoneSquareAlt', label: 'Phone Square Alt', icon: FaPhoneSquareAlt },
    { value: 'FaPhoneVolume', label: 'Phone Volume', icon: FaPhoneVolume },
    { value: 'FaPhotoVideo', label: 'Photo Video', icon: FaPhotoVideo },
    { value: 'FaPiggyBank', label: 'Piggy Bank', icon: FaPiggyBank },
    { value: 'FaPills', label: 'Pills', icon: FaPills },
    { value: 'FaPizzaSlice', label: 'Pizza Slice', icon: FaPizzaSlice },
    { value: 'FaPlaceOfWorship', label: 'Place Of Worship', icon: FaPlaceOfWorship },
    { value: 'FaPlane', label: 'Plane', icon: FaPlane },
    { value: 'FaPlaneArrival', label: 'Plane Arrival', icon: FaPlaneArrival },
    { value: 'FaPlaneDeparture', label: 'Plane Departure', icon: FaPlaneDeparture },
    { value: 'FaPlay', label: 'Play', icon: FaPlay },
    { value: 'FaPlayCircle', label: 'Play Circle', icon: FaPlayCircle },
    { value: 'FaPlug', label: 'Plug', icon: FaPlug },
    { value: 'FaPlus', label: 'Plus', icon: FaPlus },
    { value: 'FaPlusCircle', label: 'Plus Circle', icon: FaPlusCircle },
    { value: 'FaPlusSquare', label: 'Plus Square', icon: FaPlusSquare },
    { value: 'FaPodcast', label: 'Podcast', icon: FaPodcast },
    { value: 'FaPoll', label: 'Poll', icon: FaPoll },
    { value: 'FaPollH', label: 'Poll H', icon: FaPollH },
    { value: 'FaPoo', label: 'Poo', icon: FaPoo },
    { value: 'FaPooStorm', label: 'Poo Storm', icon: FaPooStorm },
    { value: 'FaPoop', label: 'Poop', icon: FaPoop },
    { value: 'FaPortrait', label: 'Portrait', icon: FaPortrait },
    { value: 'FaPoundSign', label: 'Pound Sign', icon: FaPoundSign },
    { value: 'FaPowerOff', label: 'Power Off', icon: FaPowerOff },
    { value: 'FaPray', label: 'Pray', icon: FaPray },
    { value: 'FaPrayingHands', label: 'Praying Hands', icon: FaPrayingHands },
    { value: 'FaPrescription', label: 'Prescription', icon: FaPrescription },
    { value: 'FaPrescriptionBottle', label: 'Prescription Bottle', icon: FaPrescriptionBottle },
    { value: 'FaPrescriptionBottleAlt', label: 'Prescription Bottle Alt', icon: FaPrescriptionBottleAlt },
    { value: 'FaPrint', label: 'Print', icon: FaPrint },
    { value: 'FaProcedures', label: 'Procedures', icon: FaProcedures },
    { value: 'FaProjectDiagram', label: 'Project Diagram', icon: FaProjectDiagram },
    { value: 'FaPumpMedical', label: 'Pump Medical', icon: FaPumpMedical },
    { value: 'FaPumpSoap', label: 'Pump Soap', icon: FaPumpSoap },
    { value: 'FaPuzzlePiece', label: 'Puzzle Piece', icon: FaPuzzlePiece },
    { value: 'FaQrcode', label: 'Qrcode', icon: FaQrcode },
    { value: 'FaQuestion', label: 'Question', icon: FaQuestion },
    { value: 'FaQuestionCircle', label: 'Question Circle', icon: FaQuestionCircle },
    { value: 'FaQuidditch', label: 'Quidditch', icon: FaQuidditch },
    { value: 'FaQuoteLeft', label: 'Quote Left', icon: FaQuoteLeft },
    { value: 'FaQuoteRight', label: 'Quote Right', icon: FaQuoteRight },
    { value: 'FaQuran', label: 'Quran', icon: FaQuran },
    { value: 'FaRadiation', label: 'Radiation', icon: FaRadiation },
    { value: 'FaRadiationAlt', label: 'Radiation Alt', icon: FaRadiationAlt },
    { value: 'FaRainbow', label: 'Rainbow', icon: FaRainbow },
    { value: 'FaRandom', label: 'Random', icon: FaRandom },
    { value: 'FaReceipt', label: 'Receipt', icon: FaReceipt },
    { value: 'FaRecordVinyl', label: 'Record Vinyl', icon: FaRecordVinyl },
    { value: 'FaRecycle', label: 'Recycle', icon: FaRecycle },
    { value: 'FaRedo', label: 'Redo', icon: FaRedo },
    { value: 'FaRedoAlt', label: 'Redo Alt', icon: FaRedoAlt },
    { value: 'FaRegistered', label: 'Registered', icon: FaRegistered },
    { value: 'FaRemoveFormat', label: 'Remove Format', icon: FaRemoveFormat },
    { value: 'FaReply', label: 'Reply', icon: FaReply },
    { value: 'FaReplyAll', label: 'Reply All', icon: FaReplyAll },
    { value: 'FaRepublican', label: 'Republican', icon: FaRepublican },
    { value: 'FaRestroom', label: 'Restroom', icon: FaRestroom },
    { value: 'FaRetweet', label: 'Retweet', icon: FaRetweet },
    { value: 'FaRibbon', label: 'Ribbon', icon: FaRibbon },
    { value: 'FaRing', label: 'Ring', icon: FaRing },
    { value: 'FaRoad', label: 'Road', icon: FaRoad },
    { value: 'FaRobot', label: 'Robot', icon: FaRobot },
    { value: 'FaRocket', label: 'Rocket', icon: FaRocket },
    { value: 'FaRoute', label: 'Route', icon: FaRoute },
    { value: 'FaRss', label: 'Rss', icon: FaRss },
    { value: 'FaRssSquare', label: 'Rss Square', icon: FaRssSquare },
    { value: 'FaRubleSign', label: 'Ruble Sign', icon: FaRubleSign },
    { value: 'FaRuler', label: 'Ruler', icon: FaRuler },
    { value: 'FaRulerCombined', label: 'Ruler Combined', icon: FaRulerCombined },
    { value: 'FaRulerHorizontal', label: 'Ruler Horizontal', icon: FaRulerHorizontal },
    { value: 'FaRulerVertical', label: 'Ruler Vertical', icon: FaRulerVertical },
    { value: 'FaRunning', label: 'Running', icon: FaRunning },
    { value: 'FaRupeeSign', label: 'Rupee Sign', icon: FaRupeeSign },
    { value: 'FaSadCry', label: 'Sad Cry', icon: FaSadCry },
    { value: 'FaSadTear', label: 'Sad Tear', icon: FaSadTear },
    { value: 'FaSatellite', label: 'Satellite', icon: FaSatellite },
    { value: 'FaSatelliteDish', label: 'Satellite Dish', icon: FaSatelliteDish },
    { value: 'FaSave', label: 'Save', icon: FaSave },
    { value: 'FaSchool', label: 'School', icon: FaSchool },
    { value: 'FaScrewdriver', label: 'Screwdriver', icon: FaScrewdriver },
    { value: 'FaScroll', label: 'Scroll', icon: FaScroll },
    { value: 'FaSdCard', label: 'Sd Card', icon: FaSdCard },
    { value: 'FaSearch', label: 'Search', icon: FaSearch },
    { value: 'FaSearchDollar', label: 'Search Dollar', icon: FaSearchDollar },
    { value: 'FaSearchLocation', label: 'Search Location', icon: FaSearchLocation },
    { value: 'FaSearchMinus', label: 'Search Minus', icon: FaSearchMinus },
    { value: 'FaSearchPlus', label: 'Search Plus', icon: FaSearchPlus },
    { value: 'FaSeedling', label: 'Seedling', icon: FaSeedling },
    { value: 'FaServer', label: 'Server', icon: FaServer },
    { value: 'FaShapes', label: 'Shapes', icon: FaShapes },
    { value: 'FaShare', label: 'Share', icon: FaShare },
    { value: 'FaShareAlt', label: 'Share Alt', icon: FaShareAlt },
    { value: 'FaShareAltSquare', label: 'Share Alt Square', icon: FaShareAltSquare },
    { value: 'FaShareSquare', label: 'Share Square', icon: FaShareSquare },
    { value: 'FaShekelSign', label: 'Shekel Sign', icon: FaShekelSign },
    { value: 'FaShieldAlt', label: 'Shield Alt', icon: FaShieldAlt },
    { value: 'FaShieldVirus', label: 'Shield Virus', icon: FaShieldVirus },
    { value: 'FaShip', label: 'Ship', icon: FaShip },
    { value: 'FaShippingFast', label: 'Shipping Fast', icon: FaShippingFast },
    { value: 'FaShoePrints', label: 'Shoe Prints', icon: FaShoePrints },
    { value: 'FaShoppingBag', label: 'Shopping Bag', icon: FaShoppingBag },
    { value: 'FaShoppingBasket', label: 'Shopping Basket', icon: FaShoppingBasket },
    { value: 'FaShoppingCart', label: 'Shopping Cart', icon: FaShoppingCart },
    { value: 'FaShower', label: 'Shower', icon: FaShower },
    { value: 'FaShuttleVan', label: 'Shuttle Van', icon: FaShuttleVan },
    { value: 'FaSign', label: 'Sign', icon: FaSign },
    { value: 'FaSignInAlt', label: 'Sign In Alt', icon: FaSignInAlt },
    { value: 'FaSignLanguage', label: 'Sign Language', icon: FaSignLanguage },
    { value: 'FaSignOutAlt', label: 'Sign Out Alt', icon: FaSignOutAlt },
    { value: 'FaSignal', label: 'Signal', icon: FaSignal },
    { value: 'FaSignature', label: 'Signature', icon: FaSignature },
    { value: 'FaSimCard', label: 'Sim Card', icon: FaSimCard },
    { value: 'FaSink', label: 'Sink', icon: FaSink },
    { value: 'FaSitemap', label: 'Sitemap', icon: FaSitemap },
    { value: 'FaSkating', label: 'Skating', icon: FaSkating },
    { value: 'FaSkiing', label: 'Skiing', icon: FaSkiing },
    { value: 'FaSkiingNordic', label: 'Skiing Nordic', icon: FaSkiingNordic },
    { value: 'FaSkull', label: 'Skull', icon: FaSkull },
    { value: 'FaSkullCrossbones', label: 'Skull Crossbones', icon: FaSkullCrossbones },
    { value: 'FaSlash', label: 'Slash', icon: FaSlash },
    { value: 'FaSleigh', label: 'Sleigh', icon: FaSleigh },
    { value: 'FaSlidersH', label: 'Sliders H', icon: FaSlidersH },
    { value: 'FaSmile', label: 'Smile', icon: FaSmile },
    { value: 'FaSmileBeam', label: 'Smile Beam', icon: FaSmileBeam },
    { value: 'FaSmileWink', label: 'Smile Wink', icon: FaSmileWink },
    { value: 'FaSmog', label: 'Smog', icon: FaSmog },
    { value: 'FaSmoking', label: 'Smoking', icon: FaSmoking },
    { value: 'FaSmokingBan', label: 'Smoking Ban', icon: FaSmokingBan },
    { value: 'FaSms', label: 'Sms', icon: FaSms },
    { value: 'FaSnowboarding', label: 'Snowboarding', icon: FaSnowboarding },
    { value: 'FaSnowflake', label: 'Snowflake', icon: FaSnowflake },
    { value: 'FaSnowman', label: 'Snowman', icon: FaSnowman },
    { value: 'FaSnowplow', label: 'Snowplow', icon: FaSnowplow },
    { value: 'FaSoap', label: 'Soap', icon: FaSoap },
    { value: 'FaSocks', label: 'Socks', icon: FaSocks },
    { value: 'FaSolarPanel', label: 'Solar Panel', icon: FaSolarPanel },
    { value: 'FaSort', label: 'Sort', icon: FaSort },
    { value: 'FaSortAlphaDown', label: 'Sort Alpha Down', icon: FaSortAlphaDown },
    { value: 'FaSortAlphaDownAlt', label: 'Sort Alpha Down Alt', icon: FaSortAlphaDownAlt },
    { value: 'FaSortAlphaUp', label: 'Sort Alpha Up', icon: FaSortAlphaUp },
    { value: 'FaSortAlphaUpAlt', label: 'Sort Alpha Up Alt', icon: FaSortAlphaUpAlt },
    { value: 'FaSortAmountDown', label: 'Sort Amount Down', icon: FaSortAmountDown },
    { value: 'FaSortAmountDownAlt', label: 'Sort Amount Down Alt', icon: FaSortAmountDownAlt },
    { value: 'FaSortAmountUp', label: 'Sort Amount Up', icon: FaSortAmountUp },
    { value: 'FaSortAmountUpAlt', label: 'Sort Amount Up Alt', icon: FaSortAmountUpAlt },
    { value: 'FaSortDown', label: 'Sort Down', icon: FaSortDown },
    { value: 'FaSortNumericDown', label: 'Sort Numeric Down', icon: FaSortNumericDown },
    { value: 'FaSortNumericDownAlt', label: 'Sort Numeric Down Alt', icon: FaSortNumericDownAlt },
    { value: 'FaSortNumericUp', label: 'Sort Numeric Up', icon: FaSortNumericUp },
    { value: 'FaSortNumericUpAlt', label: 'Sort Numeric Up Alt', icon: FaSortNumericUpAlt },
    { value: 'FaSortUp', label: 'Sort Up', icon: FaSortUp },
    { value: 'FaSpa', label: 'Spa', icon: FaSpa },
    { value: 'FaSpaceShuttle', label: 'Space Shuttle', icon: FaSpaceShuttle },
    { value: 'FaSpellCheck', label: 'Spell Check', icon: FaSpellCheck },
    { value: 'FaSpider', label: 'Spider', icon: FaSpider },
    { value: 'FaSpinner', label: 'Spinner', icon: FaSpinner },
    { value: 'FaSplotch', label: 'Splotch', icon: FaSplotch },
    { value: 'FaSprayCan', label: 'Spray Can', icon: FaSprayCan },
    { value: 'FaSquare', label: 'Square', icon: FaSquare },
    { value: 'FaSquareFull', label: 'Square Full', icon: FaSquareFull },
    { value: 'FaSquareRootAlt', label: 'Square Root Alt', icon: FaSquareRootAlt },
    { value: 'FaStamp', label: 'Stamp', icon: FaStamp },
    { value: 'FaStar', label: 'Star', icon: FaStar },
    { value: 'FaStarAndCrescent', label: 'Star And Crescent', icon: FaStarAndCrescent },
    { value: 'FaStarHalf', label: 'Star Half', icon: FaStarHalf },
    { value: 'FaStarHalfAlt', label: 'Star Half Alt', icon: FaStarHalfAlt },
    { value: 'FaStarOfDavid', label: 'Star Of David', icon: FaStarOfDavid },
    { value: 'FaStarOfLife', label: 'Star Of Life', icon: FaStarOfLife },
    { value: 'FaStepBackward', label: 'Step Backward', icon: FaStepBackward },
    { value: 'FaStepForward', label: 'Step Forward', icon: FaStepForward },
    { value: 'FaStethoscope', label: 'Stethoscope', icon: FaStethoscope },
    { value: 'FaStickyNote', label: 'Sticky Note', icon: FaStickyNote },
    { value: 'FaStop', label: 'Stop', icon: FaStop },
    { value: 'FaStopCircle', label: 'Stop Circle', icon: FaStopCircle },
    { value: 'FaStopwatch', label: 'Stopwatch', icon: FaStopwatch },
    { value: 'FaStore', label: 'Store', icon: FaStore },
    { value: 'FaStoreAlt', label: 'Store Alt', icon: FaStoreAlt },
    { value: 'FaStoreAltSlash', label: 'Store Alt Slash', icon: FaStoreAltSlash },
    { value: 'FaStoreSlash', label: 'Store Slash', icon: FaStoreSlash },
    { value: 'FaStream', label: 'Stream', icon: FaStream },
    { value: 'FaStreetView', label: 'Street View', icon: FaStreetView },
    { value: 'FaStrikethrough', label: 'Strikethrough', icon: FaStrikethrough },
    { value: 'FaStroopwafel', label: 'Stroopwafel', icon: FaStroopwafel },
    { value: 'FaSubscript', label: 'Subscript', icon: FaSubscript },
    { value: 'FaSubway', label: 'Subway', icon: FaSubway },
    { value: 'FaSuitcase', label: 'Suitcase', icon: FaSuitcase },
    { value: 'FaSuitcaseRolling', label: 'Suitcase Rolling', icon: FaSuitcaseRolling },
    { value: 'FaSun', label: 'Sun', icon: FaSun },
    { value: 'FaSuperscript', label: 'Superscript', icon: FaSuperscript },
    { value: 'FaSurprise', label: 'Surprise', icon: FaSurprise },
    { value: 'FaSwatchbook', label: 'Swatchbook', icon: FaSwatchbook },
    { value: 'FaSwimmer', label: 'Swimmer', icon: FaSwimmer },
    { value: 'FaSwimmingPool', label: 'Swimming Pool', icon: FaSwimmingPool },
    { value: 'FaSynagogue', label: 'Synagogue', icon: FaSynagogue },
    { value: 'FaSync', label: 'Sync', icon: FaSync },
    { value: 'FaSyncAlt', label: 'Sync Alt', icon: FaSyncAlt },
    { value: 'FaSyringe', label: 'Syringe', icon: FaSyringe },
    { value: 'FaTable', label: 'Table', icon: FaTable },
    { value: 'FaTableTennis', label: 'Table Tennis', icon: FaTableTennis },
    { value: 'FaTablet', label: 'Tablet', icon: FaTablet },
    { value: 'FaTabletAlt', label: 'Tablet Alt', icon: FaTabletAlt },
    { value: 'FaTablets', label: 'Tablets', icon: FaTablets },
    { value: 'FaTachometerAlt', label: 'Tachometer Alt', icon: FaTachometerAlt },
    { value: 'FaTag', label: 'Tag', icon: FaTag },
    { value: 'FaTags', label: 'Tags', icon: FaTags },
    { value: 'FaTape', label: 'Tape', icon: FaTape },
    { value: 'FaTasks', label: 'Tasks', icon: FaTasks },
    { value: 'FaTaxi', label: 'Taxi', icon: FaTaxi },
    { value: 'FaTeeth', label: 'Teeth', icon: FaTeeth },
    { value: 'FaTeethOpen', label: 'Teeth Open', icon: FaTeethOpen },
    { value: 'FaTemperatureHigh', label: 'Temperature High', icon: FaTemperatureHigh },
    { value: 'FaTemperatureLow', label: 'Temperature Low', icon: FaTemperatureLow },
    { value: 'FaTenge', label: 'Tenge', icon: FaTenge },
    { value: 'FaTerminal', label: 'Terminal', icon: FaTerminal },
    { value: 'FaTextHeight', label: 'Text Height', icon: FaTextHeight },
    { value: 'FaTextWidth', label: 'Text Width', icon: FaTextWidth },
    { value: 'FaTh', label: 'Th', icon: FaTh },
    { value: 'FaThLarge', label: 'Th Large', icon: FaThLarge },
    { value: 'FaThList', label: 'Th List', icon: FaThList },
    { value: 'FaTheaterMasks', label: 'Theater Masks', icon: FaTheaterMasks },
    { value: 'FaThermometer', label: 'Thermometer', icon: FaThermometer },
    { value: 'FaThermometerEmpty', label: 'Thermometer Empty', icon: FaThermometerEmpty },
    { value: 'FaThermometerFull', label: 'Thermometer Full', icon: FaThermometerFull },
    { value: 'FaThermometerHalf', label: 'Thermometer Half', icon: FaThermometerHalf },
    { value: 'FaThermometerQuarter', label: 'Thermometer Quarter', icon: FaThermometerQuarter },
    { value: 'FaThermometerThreeQuarters', label: 'Thermometer Three Quarters', icon: FaThermometerThreeQuarters },
    { value: 'FaThumbsDown', label: 'Thumbs Down', icon: FaThumbsDown },
    { value: 'FaThumbsUp', label: 'Thumbs Up', icon: FaThumbsUp },
    { value: 'FaThumbtack', label: 'Thumbtack', icon: FaThumbtack },
    { value: 'FaTicketAlt', label: 'Ticket Alt', icon: FaTicketAlt },
    { value: 'FaTimes', label: 'Times', icon: FaTimes },
    { value: 'FaTimesCircle', label: 'Times Circle', icon: FaTimesCircle },
    { value: 'FaTint', label: 'Tint', icon: FaTint },
    { value: 'FaTintSlash', label: 'Tint Slash', icon: FaTintSlash },
    { value: 'FaTired', label: 'Tired', icon: FaTired },
    { value: 'FaToggleOff', label: 'Toggle Off', icon: FaToggleOff },
    { value: 'FaToggleOn', label: 'Toggle On', icon: FaToggleOn },
    { value: 'FaToilet', label: 'Toilet', icon: FaToilet },
    { value: 'FaToiletPaper', label: 'Toilet Paper', icon: FaToiletPaper },
    { value: 'FaToiletPaperSlash', label: 'Toilet Paper Slash', icon: FaToiletPaperSlash },
    { value: 'FaToolbox', label: 'Toolbox', icon: FaToolbox },
    { value: 'FaTools', label: 'Tools', icon: FaTools },
    { value: 'FaTooth', label: 'Tooth', icon: FaTooth },
    { value: 'FaTorah', label: 'Torah', icon: FaTorah },
    { value: 'FaToriiGate', label: 'Torii Gate', icon: FaToriiGate },
    { value: 'FaTractor', label: 'Tractor', icon: FaTractor },
    { value: 'FaTrademark', label: 'Trademark', icon: FaTrademark },
    { value: 'FaTrafficLight', label: 'Traffic Light', icon: FaTrafficLight },
    { value: 'FaTrailer', label: 'Trailer', icon: FaTrailer },
    { value: 'FaTrain', label: 'Train', icon: FaTrain },
    { value: 'FaTram', label: 'Tram', icon: FaTram },
    { value: 'FaTransgender', label: 'Transgender', icon: FaTransgender },
    { value: 'FaTransgenderAlt', label: 'Transgender Alt', icon: FaTransgenderAlt },
    { value: 'FaTrash', label: 'Trash', icon: FaTrash },
    { value: 'FaTrashAlt', label: 'Trash Alt', icon: FaTrashAlt },
    { value: 'FaTrashRestore', label: 'Trash Restore', icon: FaTrashRestore },
    { value: 'FaTrashRestoreAlt', label: 'Trash Restore Alt', icon: FaTrashRestoreAlt },
    { value: 'FaTree', label: 'Tree', icon: FaTree },
    { value: 'FaTrophy', label: 'Trophy', icon: FaTrophy },
    { value: 'FaTruck', label: 'Truck', icon: FaTruck },
    { value: 'FaTruckLoading', label: 'Truck Loading', icon: FaTruckLoading },
    { value: 'FaTruckMonster', label: 'Truck Monster', icon: FaTruckMonster },
    { value: 'FaTruckMoving', label: 'Truck Moving', icon: FaTruckMoving },
    { value: 'FaTruckPickup', label: 'Truck Pickup', icon: FaTruckPickup },
    { value: 'FaTshirt', label: 'Tshirt', icon: FaTshirt },
    { value: 'FaTty', label: 'Tty', icon: FaTty },
    { value: 'FaTv', label: 'Tv', icon: FaTv },
    { value: 'FaUmbrella', label: 'Umbrella', icon: FaUmbrella },
    { value: 'FaUmbrellaBeach', label: 'Umbrella Beach', icon: FaUmbrellaBeach },
    { value: 'FaUnderline', label: 'Underline', icon: FaUnderline },
    { value: 'FaUndo', label: 'Undo', icon: FaUndo },
    { value: 'FaUndoAlt', label: 'Undo Alt', icon: FaUndoAlt },
    { value: 'FaUniversalAccess', label: 'Universal Access', icon: FaUniversalAccess },
    { value: 'FaUniversity', label: 'University', icon: FaUniversity },
    { value: 'FaUnlink', label: 'Unlink', icon: FaUnlink },
    { value: 'FaUnlock', label: 'Unlock', icon: FaUnlock },
    { value: 'FaUnlockAlt', label: 'Unlock Alt', icon: FaUnlockAlt },
    { value: 'FaUpload', label: 'Upload', icon: FaUpload },
    { value: 'FaUser', label: 'User', icon: FaUser },
    { value: 'FaUserAlt', label: 'User Alt', icon: FaUserAlt },
    { value: 'FaUserAltSlash', label: 'User Alt Slash', icon: FaUserAltSlash },
    { value: 'FaUserAstronaut', label: 'User Astronaut', icon: FaUserAstronaut },
    { value: 'FaUserCheck', label: 'User Check', icon: FaUserCheck },
    { value: 'FaUserCircle', label: 'User Circle', icon: FaUserCircle },
    { value: 'FaUserClock', label: 'User Clock', icon: FaUserClock },
    { value: 'FaUserCog', label: 'User Cog', icon: FaUserCog },
    { value: 'FaUserEdit', label: 'User Edit', icon: FaUserEdit },
    { value: 'FaUserFriends', label: 'User Friends', icon: FaUserFriends },
    { value: 'FaUserGraduate', label: 'User Graduate', icon: FaUserGraduate },
    { value: 'FaUserInjured', label: 'User Injured', icon: FaUserInjured },
    { value: 'FaUserLock', label: 'User Lock', icon: FaUserLock },
    { value: 'FaUserMd', label: 'User Md', icon: FaUserMd },
    { value: 'FaUserMinus', label: 'User Minus', icon: FaUserMinus },
    { value: 'FaUserNinja', label: 'User Ninja', icon: FaUserNinja },
    { value: 'FaUserNurse', label: 'User Nurse', icon: FaUserNurse },
    { value: 'FaUserPlus', label: 'User Plus', icon: FaUserPlus },
    { value: 'FaUserSecret', label: 'User Secret', icon: FaUserSecret },
    { value: 'FaUserShield', label: 'User Shield', icon: FaUserShield },
    { value: 'FaUserSlash', label: 'User Slash', icon: FaUserSlash },
    { value: 'FaUserTag', label: 'User Tag', icon: FaUserTag },
    { value: 'FaUserTie', label: 'User Tie', icon: FaUserTie },
    { value: 'FaUserTimes', label: 'User Times', icon: FaUserTimes },
    { value: 'FaUsers', label: 'Users', icon: FaUsers },
    { value: 'FaUsersCog', label: 'Users Cog', icon: FaUsersCog },
    { value: 'FaUsersSlash', label: 'Users Slash', icon: FaUsersSlash },
    { value: 'FaUtensilSpoon', label: 'Utensil Spoon', icon: FaUtensilSpoon },
    { value: 'FaUtensils', label: 'Utensils', icon: FaUtensils },
    { value: 'FaVectorSquare', label: 'Vector Square', icon: FaVectorSquare },
    { value: 'FaVenus', label: 'Venus', icon: FaVenus },
    { value: 'FaVenusDouble', label: 'Venus Double', icon: FaVenusDouble },
    { value: 'FaVenusMars', label: 'Venus Mars', icon: FaVenusMars },
    { value: 'FaVest', label: 'Vest', icon: FaVest },
    { value: 'FaVestPatches', label: 'Vest Patches', icon: FaVestPatches },
    { value: 'FaVial', label: 'Vial', icon: FaVial },
    { value: 'FaVials', label: 'Vials', icon: FaVials },
    { value: 'FaVideo', label: 'Video', icon: FaVideo },
    { value: 'FaVideoSlash', label: 'Video Slash', icon: FaVideoSlash },
    { value: 'FaVihara', label: 'Vihara', icon: FaVihara },
    { value: 'FaVirus', label: 'Virus', icon: FaVirus },
    { value: 'FaVirusSlash', label: 'Virus Slash', icon: FaVirusSlash },
    { value: 'FaViruses', label: 'Viruses', icon: FaViruses },
    { value: 'FaVoicemail', label: 'Voicemail', icon: FaVoicemail },
    { value: 'FaVolleyballBall', label: 'Volleyball Ball', icon: FaVolleyballBall },
    { value: 'FaVolumeDown', label: 'Volume Down', icon: FaVolumeDown },
    { value: 'FaVolumeMute', label: 'Volume Mute', icon: FaVolumeMute },
    { value: 'FaVolumeOff', label: 'Volume Off', icon: FaVolumeOff },
    { value: 'FaVolumeUp', label: 'Volume Up', icon: FaVolumeUp },
    { value: 'FaVoteYea', label: 'Vote Yea', icon: FaVoteYea },
    { value: 'FaVrCardboard', label: 'Vr Cardboard', icon: FaVrCardboard },
    { value: 'FaWalking', label: 'Walking', icon: FaWalking },
    { value: 'FaWallet', label: 'Wallet', icon: FaWallet },
    { value: 'FaWarehouse', label: 'Warehouse', icon: FaWarehouse },
    { value: 'FaWater', label: 'Water', icon: FaWater },
    { value: 'FaWaveSquare', label: 'Wave Square', icon: FaWaveSquare },
    { value: 'FaWeight', label: 'Weight', icon: FaWeight },
    { value: 'FaWeightHanging', label: 'Weight Hanging', icon: FaWeightHanging },
    { value: 'FaWheelchair', label: 'Wheelchair', icon: FaWheelchair },
    { value: 'FaWifi', label: 'Wifi', icon: FaWifi },
    { value: 'FaWind', label: 'Wind', icon: FaWind },
    { value: 'FaWindowClose', label: 'Window Close', icon: FaWindowClose },
    { value: 'FaWindowMaximize', label: 'Window Maximize', icon: FaWindowMaximize },
    { value: 'FaWindowMinimize', label: 'Window Minimize', icon: FaWindowMinimize },
    { value: 'FaWindowRestore', label: 'Window Restore', icon: FaWindowRestore },
    { value: 'FaWineBottle', label: 'Wine Bottle', icon: FaWineBottle },
    { value: 'FaWineGlass', label: 'Wine Glass', icon: FaWineGlass },
    { value: 'FaWineGlassAlt', label: 'Wine Glass Alt', icon: FaWineGlassAlt },
    { value: 'FaWonSign', label: 'Won Sign', icon: FaWonSign },
    { value: 'FaWrench', label: 'Wrench', icon: FaWrench },
    { value: 'FaXRay', label: 'XRay', icon: FaXRay },
    { value: 'FaYenSign', label: 'Yen Sign', icon: FaYenSign },
    { value: 'FaYinYang', label: 'Yin Yang', icon: FaYinYang }    
];
