import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Icon,
  SimpleGrid,
  useDisclosure,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { FaSearch, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { debounce } from 'lodash';
import { icons } from './IconData';

const ITEMS_PER_PAGE = 20;

const IconSelector = ({ selectedIcon, setSelectedIcon }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSearchChange = debounce((event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reset to first page on search
  }, 300);

  const filteredIcons = useMemo(() =>
    icons.filter((icon) =>
      icon.label.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [searchTerm]
  );

  const totalPages = Math.ceil(filteredIcons.length / ITEMS_PER_PAGE);
  const displayedIcons = filteredIcons.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);

  const selectedIconObject = icons.find((icon) => icon.value === selectedIcon);

  return (
    <Box>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-start">
        <PopoverTrigger>
          <Button
            width="75px"
            height="75px"
            padding="0"
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {selectedIconObject ? <Icon as={selectedIconObject.icon} boxSize={10} /> : <FaSearch />}
          </Button>
        </PopoverTrigger>
        <PopoverContent maxH="400px" overflowY="auto" css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
          '&::-webkit-scrollbar-track': {
            width: '0px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
          },
        }}>
          <Box position="sticky" top={0} bg="white" zIndex={1}>
            <PopoverArrow />
            <PopoverCloseButton mt={1} />
            <PopoverHeader fontWeight="bold">Select an Icon</PopoverHeader>
            <Input
              placeholder="Search icons..."
              onChange={handleSearchChange}
              mb={2}
              borderRadius="0"
              border="1px solid #e6e6e6"
              borderBottom="1px solid #e6e6e6"
            />
          </Box>
          <PopoverBody>
            <SimpleGrid columns={5} spacing={2}>
              {displayedIcons.map((icon) => (
                <Button
                  key={icon.value}
                  onClick={() => {
                    setSelectedIcon(icon.value);
                    onClose();
                  }}
                  variant="outline"
                  size="lg"
                  p={2}
                >
                  <Icon as={icon.icon} boxSize={6} />
                </Button>
              ))}
            </SimpleGrid>
            <Flex mt={4}>
              <Button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                isDisabled={currentPage === 0}
                leftIcon={<FaArrowLeft />}
              >
                Previous
              </Button>
              <Spacer />
              <Button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
                isDisabled={currentPage === totalPages - 1}
                rightIcon={<FaArrowRight />}
              >
                Next
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default IconSelector;
