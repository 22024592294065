import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  HStack,
  Text,
  IconButton,
  useClipboard,
  useToast,
  Spinner,
  VStack,
  Collapse,
  Checkbox,
  Avatar,
} from '@chakra-ui/react';
import { DragHandleIcon, CopyIcon, DeleteIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useOutletContext } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../../Firebase';

const ItemType = 'DEPARTMENT';
const PermissionKeys = [
  'See Staff Tab',
  'Edit Verification',
  'Edit Department',
  'Edit Role',
  'Edit Permissions',
  'See Evaluations Tab',
  'Create New Evaluation',
  'Edit Evaluation',
  'Make Evaluation',
  'See Protocols Tab',
  'Edit Protocols',
  'See All Reports',
];

const DraggableDepartment = ({ department, index, moveDepartment, onDelete }) => {
  const [, drag] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveDepartment(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <Box ref={(node) => drag(drop(node))} mt={2} p={2} bg="gray.100" borderRadius="md">
      <HStack spacing={2}>
        <IconButton
          icon={<DragHandleIcon />}
          variant="ghost"
          cursor="grab"
          aria-label="Drag to reorder"
        />
        <Text flex="1">{department}</Text>
        <IconButton
          icon={<DeleteIcon />}
          colorScheme="red"
          size="xs"
          aria-label="Delete department"
          onClick={() => onDelete(index)}
        />
      </HStack>
    </Box>
  );
};

const Role = ({ role, index, updateRoleName, togglePermission, onDeleteRole }) => {
  const [showPermissions, setShowPermissions] = useState(false);

  const handleTogglePermissions = () => setShowPermissions(!showPermissions);

  return (
    <Box p={2} bg="gray.100" borderRadius="md" mb={4}>
      <HStack spacing={2}>
        <Input
          value={role.name}
          onChange={(e) => updateRoleName(index, e.target.value)}
          placeholder="Role Name"
          flex="1"
          fontWeight="bold"
          fontSize="16px"
        />
        <IconButton
          icon={<DeleteIcon />}
          colorScheme="red"
          size="xs"
          aria-label="Delete role"
          onClick={() => onDeleteRole(index)}
        />
        <IconButton
          icon={showPermissions ? <ChevronUpIcon /> : <ChevronDownIcon />}
          size="xs"
          aria-label="Toggle permissions"
          onClick={handleTogglePermissions}
        />
      </HStack>
      <Collapse in={showPermissions} animateOpacity>
        <VStack align="flex-start" mt={2}>
          {PermissionKeys.map((permission, i) => (
            <Checkbox
              key={i}
              isChecked={role.permissions[permission] || false} // Ensure it's false by default
              onChange={() => togglePermission(index, permission)}
            >
              {permission}
            </Checkbox>
          ))}
        </VStack>
      </Collapse>
    </Box>
  );
};

const Organization = () => {
  const { orgData } = useOutletContext();
  const { onCopy } = useClipboard(orgData?.code || '');
  const toast = useToast();

  const [orgName, setOrgName] = useState('');
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState('');
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState('');
  const [orgImage, setOrgImage] = useState(null);
  const [orgImageURL, setOrgImageURL] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (orgData) {
      setOrgName(orgData.name);
      setDepartments(orgData.departments || []);
      setRoles(orgData.roles || []);
      setOrgImageURL(orgData.imageURL || '');
      setIsLoading(false); // Data is loaded, hide spinner
    }
  }, [orgData]);

  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value);
    setIsSaveDisabled(false); // Enable save button when the name is changed
  };

  // Utility function to convert an image file to JPG format using Canvas
  const convertImageToJpg = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            'image/jpeg',
            0.8 // Adjust the quality if needed (0.0 to 1.0)
          );
        };
        img.src = event.target.result;
      };
      reader.onerror = () => reject(new Error('Failed to read the image file.'));
      reader.readAsDataURL(file);
    });
  };

  const handleOrgImageChange = async (e) => {
    const file = e.target.files[0];
    try {
      const jpgBlob = await convertImageToJpg(file); // Convert image to JPG
      const jpgFile = new File([jpgBlob], 'organization-image.jpg', { type: 'image/jpeg' });
      setOrgImage(jpgFile);
      setOrgImageURL(URL.createObjectURL(jpgFile));
      setIsSaveDisabled(false); // Enable save button when the image is changed
    } catch (error) {
      console.error('Error converting image to JPG:', error);
      toast({
        title: 'Error',
        description: 'Failed to process the image. Please try again.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleAddDepartment = () => {
    if (newDepartment.trim() !== '') {
      setDepartments([...departments, newDepartment.trim()]);
      setNewDepartment('');
      setIsSaveDisabled(false); // Enable save button when a new department is added
    }
  };

  const moveDepartment = useCallback((dragIndex, hoverIndex) => {
    setDepartments((prevDepartments) => {
      const updatedDepartments = [...prevDepartments];
      const [movedItem] = updatedDepartments.splice(dragIndex, 1);
      updatedDepartments.splice(hoverIndex, 0, movedItem);
      return updatedDepartments;
    });
    setIsSaveDisabled(false); // Enable save button when departments are reordered
  }, []);

  const handleDeleteDepartment = (index) => {
    setDepartments(departments.filter((_, i) => i !== index));
    setIsSaveDisabled(false); // Enable save button when a department is deleted
  };

  const handleAddRole = () => {
    if (newRole.trim() !== '') {
      const rolePermissions = PermissionKeys.reduce((acc, permission) => {
        acc[permission] = false; // Set all permissions to false by default
        return acc;
      }, {});
      setRoles([...roles, { name: newRole.trim(), permissions: rolePermissions }]);
      setNewRole('');
      setIsSaveDisabled(false); // Enable save button when a new role is added
    }
  };

  const updateRoleName = (index, name) => {
    const updatedRoles = [...roles];
    updatedRoles[index].name = name;
    setRoles(updatedRoles);
    setIsSaveDisabled(false); // Enable save button when a role name is updated
  };

  const togglePermission = (roleIndex, permission) => {
    const updatedRoles = [...roles];
    const currentRole = updatedRoles[roleIndex];
    currentRole.permissions[permission] = !currentRole.permissions[permission];
    setRoles(updatedRoles);
    setIsSaveDisabled(false); // Enable save button when a permission is toggled
  };

  const handleDeleteRole = (index) => {
    setRoles(roles.filter((_, i) => i !== index));
    setIsSaveDisabled(false); // Enable save button when a role is deleted
  };

  const handleSave = async () => {
    const functions = getFunctions();
    const updateOrganizationDetails = httpsCallable(functions, 'updateOrgDetails');
    setIsSaving(true); // Set saving state to true to show spinner

    let imageURL = orgImageURL;

    if (orgImage) {
      const storageRef = ref(storage, `org/${orgData.code}/icons/orgImage`);
      await uploadBytes(storageRef, orgImage);
      imageURL = await getDownloadURL(storageRef);
    }

    try {
      const response = await updateOrganizationDetails({
        orgName,
        departments,
        roles,
        imageURL,
        orgCode: orgData.code, // Assuming orgData contains the organization code
      });

      toast({
        title: 'Saved successfully!',
        description: response.data.message,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setIsSaveDisabled(true); // Disable the save button after saving

      // Refresh the tab after saving
      window.location.reload();
    } catch (error) {
      console.error('Error updating organization details:', error);
      toast({
        title: 'Error',
        description: 'Failed to save organization details.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false); // Reset saving state after the operation
    }
  };

  const handleCopy = () => {
    onCopy();
    toast({
      title: 'Copied to clipboard!',
      description: "Organization code has been copied.",
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box maxW="500px" mx="auto">
      <Heading as="h3" size="md" mb={2}>
        Organization Name
      </Heading>
      <Input
        value={orgName}
        onChange={handleOrgNameChange}
        mb={4}
      />

      <Heading as="h3" size="md" mb={2}>
        Organization Image
      </Heading>
      <HStack>
        <Avatar src={orgImageURL} size="xl" />
        <Input
          type="file"
          accept="image/*"
          onChange={handleOrgImageChange}
        />
      </HStack>

      <Heading as="h3" size="md" mt={6} mb={2}>
        Departments
      </Heading>
      <DndProvider backend={HTML5Backend}>
        {departments.map((department, index) => (
          <DraggableDepartment
            key={department}
            index={index}
            department={department}
            moveDepartment={moveDepartment}
            onDelete={handleDeleteDepartment}
          />
        ))}
      </DndProvider>

      <HStack mt={4} alignItems="center">
        <Input
          value={newDepartment}
          onChange={(e) => setNewDepartment(e.target.value)}
          placeholder="Add a new department"
          flex="1"
        />
        <Button onClick={handleAddDepartment} colorScheme="blue" size="sm">
          Add Department
        </Button>
      </HStack>

      <Heading as="h3" size="md" mt={6} mb={2}>
        Roles
      </Heading>
      <HStack mb={2} alignItems="center">
        <Input
          value={newRole}
          onChange={(e) => setNewRole(e.target.value)}
          placeholder="Add a new role"
          flex="1"
        />
        <Button onClick={handleAddRole} colorScheme="blue" size="sm">
          Add Role
        </Button>
      </HStack>
      {roles.map((role, index) => (
        <Role
          key={index}
          role={role}
          index={index}
          updateRoleName={updateRoleName}
          togglePermission={togglePermission}
          onDeleteRole={handleDeleteRole}
        />
      ))}

      <HStack mt={4} alignItems="center">
        <Text fontSize="lg" fontWeight="bold">
          Organization Code: {orgData.code}
        </Text>
        <Button
          onClick={handleCopy}
          leftIcon={<CopyIcon />}
          colorScheme="blue"
          size="sm"
        >
          Copy
        </Button>
      </HStack>

      <Button
        mt={4}
        colorScheme="blue"
        onClick={handleSave}
        isDisabled={isSaveDisabled || isSaving}
        leftIcon={isSaving && <Spinner size="sm" />}
      >
        {isSaving ? 'Saving...' : 'Save Changes'}
      </Button>
    </Box>
  );
};

export default Organization;
