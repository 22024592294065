import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  VStack,
  Image,
  Button,
  Text,
  Flex,
  IconButton,
  Spinner,
  Avatar,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import logo from "../assets/bluelogo.svg";
import icon from "../assets/trans.png";
import {
  FiGrid,
  FiUser,
  FiRefreshCcw,
  FiShield,
  FiUsers,
  FiClipboard,
  FiBookOpen,
  FiBarChart,
  FiMenu,
} from "react-icons/fi";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import { useAuth } from "./utils/AuthContext";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";

const Dashboard = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [userDoc, setUserDoc] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser) {
      const db = getFirestore();
      const userDocRef = doc(db, "users", currentUser.uid);

      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          setUserDoc(docData);

          if (docData.orgId) {
            const orgDocRef = doc(db, "org", docData.orgId);
            onSnapshot(orgDocRef, (orgDocSnapshot) => {
              if (orgDocSnapshot.exists()) {
                setOrgData(orgDocSnapshot.data());
              }
            });
          }
        }
        setLoading(false);
      });

      return () => unsubscribe();
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  const isRouteActive = (route) => location.pathname.startsWith(`/dashboard/${route}`);

  const getCurrentPageTitle = () => {
    const pathParts = location.pathname.split("/");
    if (pathParts.length > 2 && pathParts[1] === "dashboard") {
      return capitalizeFirstLetter(pathParts[2]);
    }
    return "Dashboard";
  };

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const getSidebarButtons = () => {
    const baseButtons = [
      { label: "Overview", icon: FiGrid, route: "overview" }
    ];

    const isVerified = orgData?.staff?.some(
      (staffMember) =>
        staffMember.uid === currentUser.uid && staffMember.verified === true
    );

    const currentUserStaffData = orgData?.staff?.find(staff => staff.uid === userDoc?.uid);

    const seeProtocols = currentUserStaffData?.permissions?.["See Protocols Tab"] || false;
    const seeStaff = currentUserStaffData?.permissions?.["See Staff Tab"] || false;
    const seeEvaluations = currentUserStaffData?.permissions?.["See Evaluations Tab"] || false;

    if (seeEvaluations) {
      baseButtons.push({ label: "Reports", icon: FiBarChart, route: "reports" });
    }

    if (isVerified) {
      if (seeStaff) {
        baseButtons.push({ label: "Staff", icon: FiUsers, route: "staff" });
      }

      if (seeEvaluations) {
        baseButtons.push({ label: "Evaluations", icon: FiClipboard, route: "evaluations" });
      }

      if (seeProtocols) {
        baseButtons.push({ label: "Protocols", icon: FiBookOpen, route: "protocols" });
      }
    }

    if (orgData && orgData.directors.includes(currentUser.uid)) {
      baseButtons.unshift({ label: "Organization", icon: FiShield, route: "organization" });
    }

    const profileButton = { label: "My Profile", icon: FiUser, route: "profile" };
    const buttons = [...baseButtons, profileButton];

    if (!isMobile) {
      const collapseButton = {
        label: "Collapse",
        icon: isSidebarCollapsed ? IoChevronForward : IoChevronBack,
        route: "collapse"
      };
      buttons.push(collapseButton);
    }

    return buttons;
  };

  const handleNavigation = (route) => {
    if (route === "collapse") {
      toggleSidebar();
    } else {
      navigate(`/dashboard/${route}`);
      if (isMobile) {
        setIsMobileMenuOpen(false);
      }
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" minHeight="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const sidebarButtons = getSidebarButtons();

  const SidebarContent = () => (
    <VStack spacing={2} align="stretch">
      {sidebarButtons.map((button) => (
        <Button
          key={button.route}
          onClick={() => handleNavigation(button.route)}
          variant="ghost"
          color="white"
          leftIcon={<button.icon size={24} />}
          justifyContent={isSidebarCollapsed && !isMobile ? "center" : "flex-start"}
          borderWidth="1px"
          borderColor={isRouteActive(button.route) ? "#4d6d8b" : "#001c36"}
          bg={isRouteActive(button.route) ? "#002c55" : "transparent"}
          _hover={{
            bg: "#002c55",
            borderColor: "#4d6d8b",
          }}
          fontSize={18}
          py={6}
          px={isSidebarCollapsed && !isMobile ? 2 : 6}
          pl="16px"
        >
          {(!isSidebarCollapsed || isMobile) &&
            (button.route === "collapse" ? "Collapse" : button.label)}
        </Button>
      ))}
    </VStack>
  );

  return (
    <Flex minHeight="100vh">
      {!isMobile && (
        <Box
          as="aside"
          width={isSidebarCollapsed ? "100px" : "280px"}
          bg="#001c36"
          color="white"
          p={6}
          position="fixed"
          top={0}
          left={0}
          bottom={0}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          transition="width 0.3s"
        >
          <Box>
            <Image
              src={isSidebarCollapsed ? icon : logo}
              alt="Logo"
              mb={10}
              mx={isSidebarCollapsed ? "auto" : 0}
              h="40px"
            />
            <SidebarContent />
          </Box>
        </Box>
      )}
      <Box
        flex={1}
        ml={isMobile ? 0 : (isSidebarCollapsed ? "100px" : "280px")}
        transition="margin-left 0.3s"
      >
        {isMobile ? (
          <Box 
            position="sticky" 
            top={0} 
            zIndex={1000} 
            bg="#001c36" 
            borderBottomWidth="1px" 
            borderBottomColor="gray.300" 
            p={3}
            w="100vw"
          >
            <Flex justify="space-between" align="center">
              <Image src={logo} alt="Logo" h="30px" />
              <IconButton
                aria-label="Open menu"
                icon={<FiMenu />}
                onClick={() => setIsMobileMenuOpen(true)}
                color="white"
                bg="#001c36"
              />
            </Flex>
          </Box>
        ) : (
          <Box borderBottomWidth="1px" borderBottomColor="gray.300" p={3}>
            <Flex justify="space-between" align="center">
              <Text fontSize="2xl" fontWeight="bold">
                {getCurrentPageTitle()}
              </Text>
              <Flex align="center">
                {userDoc && (
                  <Text mr={4} fontSize="lg" fontWeight="bold">
                    {userDoc.firstName} {userDoc.lastName}
                  </Text>
                )}
                {userDoc && userDoc.profilePictureURL && (
                  <Avatar
                    src={userDoc.profilePictureURL}
                    size="sm"
                    name={`${userDoc.firstName} ${userDoc.lastName}`}
                    mr={4}
                    bg="gray.200"
                    color="#222222"
                  />
                )}
                <IconButton
                  aria-label="Refresh page"
                  icon={<FiRefreshCcw />}
                  onClick={refreshPage}
                  variant="ghost"
                  bg="gray.200"
                  size="sm"
                />
              </Flex>
            </Flex>
          </Box>
        )}
        <Box p={4}>
          <Outlet context={{ userDoc, orgData }} />
        </Box>
      </Box>
      {isMobile && (
        <Drawer
          isOpen={isMobileMenuOpen}
          placement="left"
          onClose={() => setIsMobileMenuOpen(false)}
        >
          <DrawerOverlay />
          <DrawerContent bg="#001c36" color="white">
            <DrawerCloseButton color="white" />
            <DrawerHeader>
              <Image src={icon} alt="Logo" h="40px" />
            </DrawerHeader>
            <DrawerBody>
              <SidebarContent />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Flex>
  );
};

export default Dashboard;