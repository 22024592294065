import React, { useState, useRef, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  List,
  ListItem,
  Flex,
  Avatar,
  Text,
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
  VStack,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const StaffMemberSelector = ({ staffMembers, selectedStaffMembers, onStaffMemberChange, removeStaffMember }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();

  const filteredStaffMembers = staffMembers.filter((staff) =>
    `${staff.firstName} ${staff.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FormControl mb={4} ref={inputRef}>
      <FormLabel htmlFor="staffMember">Select Staff Member(s)</FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIcon} color="gray.300" />
        </InputLeftElement>
        <Input
          id="staffMember"
          placeholder="Search and select staff members"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setIsOpen(true);
          }}
          borderWidth="0px"
          bg="gray.100"
        />
      </InputGroup>
      {isOpen && (
        <List
          spacing={2}
          mt={2}
          border="1px solid #e2e8f0"
          borderRadius="md"
          bg="white"
          zIndex={1}
          maxHeight="200px"
          overflowY="auto"
        >
          {filteredStaffMembers.map((staff) => (
            <ListItem
              key={staff.uid}
              onClick={() => {
                onStaffMemberChange(staff);
                setSearchQuery(""); // Clear search after selection
                setIsOpen(false); // Close the list after selection
              }}
              cursor="pointer"
              p={2}
              _hover={{ bg: "gray.100" }}
            >
              <Flex align="center">
                <Avatar size="sm" bg="gray.200" color="#222222" name={`${staff.firstName} ${staff.lastName}`} src={staff.profilePictureURL} mr={2} />
                <Box>
                  <Text>{`${staff.firstName} ${staff.lastName}`}</Text>
                </Box>
              </Flex>
            </ListItem>
          ))}
        </List>
      )}
      <VStack spacing={2} mt={4} align="flex-start">
        {selectedStaffMembers.map((member) => (
          <Tag size="lg" key={member.uid} borderRadius="full" borderWidth="1px" bg="white">
            <Avatar bg="gray.200" color="#222222" size="xs" name={`${member.firstName} ${member.lastName}`} src={member.profilePictureURL} mr={2} />
            <TagLabel>{`${member.firstName} ${member.lastName}`}</TagLabel>
            <TagCloseButton onClick={() => removeStaffMember(member.uid)} />
          </Tag>
        ))}
      </VStack>
    </FormControl>
  );
};

export default StaffMemberSelector;
