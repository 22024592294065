// src/components/utils/DirectRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const DirectRoute = ({ children }) => {
  const { currentUser } = useAuth();

  return currentUser ? <Navigate to="/dashboard" /> : children;
};

export default DirectRoute;