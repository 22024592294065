import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Flex,
  VStack,
  Text,
} from '@chakra-ui/react';
import { FaAlignLeft, FaAlignJustify, FaListUl, FaHeading, FaFont } from 'react-icons/fa';

const fieldTypes = [
  {
    value: 'heading',
    label: 'Heading',
    description: 'A heading to separate sections.',
    icon: FaHeading,
  },
  {
    value: 'text',
    label: 'Text',
    description: 'A static text block for displaying information.',
    icon: FaFont,
  },
  {
    value: 'single-line',
    label: 'Single Line Input',
    description: 'A simple single line input field.',
    icon: FaAlignLeft,
  },
  {
    value: 'multi-line',
    label: 'Multi Line Input',
    description: 'A textarea for multi-line input.',
    icon: FaAlignJustify,
  },
  {
    value: 'multi-choice',
    label: 'Multi Option Select',
    description: 'A select field for multiple choices.',
    icon: FaListUl,
  },
];

const SelectionPicker = ({ isOpen, onClose, onSave }) => {
  const [selectedField, setSelectedField] = useState(fieldTypes[0]);

  const handleSave = () => {
    onSave({ type: selectedField.value });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <VStack spacing={3} align="start" flex="1">
              {fieldTypes.map((field) => (
                <Button
                  key={field.value}
                  onClick={() => setSelectedField(field)}
                  variant="outline"
                  leftIcon={<field.icon />}
                  width="250px"
                  justifyContent="flex-start"
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="md"
                  p={4}
                  _hover={{ bg: "gray.100" }}
                >
                  {field.label}
                </Button>
              ))}
            </VStack>
            <Box flex="2" ml={4}>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                {selectedField.label}
              </Text>
              <Text>{selectedField.description}</Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave} mr={3}>
            Add {selectedField.label}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectionPicker;
