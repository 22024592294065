import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { IconButton, Tooltip, Flex } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import fontData from '../../../fonts/Reddit_Sans/static/RedditSans-Medium.ttf';
import boldFontData from '../../../fonts/Reddit_Sans/static/RedditSans-Bold.ttf'; // Import the bold font
import logo from '../../../assets/whitelogo.png';

const StaffReportsPDF = ({ staffMember, reports, reportStats, formatDate, startDate, endDate, department, role }) => {
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add custom fonts
    doc.addFont(fontData, 'RedditSans', 'normal');
    doc.addFont(boldFontData, 'RedditSansBold', 'bold'); // Add the bold font

    // Add logo with fixed dimensions
    const logoWidth = 40;
    const logoHeight = 6;
    const logoY = 10;
    doc.addImage(logo, 'PNG', 12, logoY, logoWidth, logoHeight);

    // Position title and subtitle directly below the image
    const titleY = logoY + logoHeight + 16; // Adjust the vertical gap as needed

    // Set font style to bold for the title
    doc.setFont('RedditSansBold', 'bold');
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185); // Blue color
    doc.text('Staff Member Report Summary', 10, titleY);

    // Determine date range
    const formattedStartDate = startDate ? formatDate(startDate) : 'N/A';
    const formattedEndDate = endDate ? formatDate(endDate) : 'N/A';

    // Create an info box with a border for Staff Member Details and Report Statistics
    const infoBoxY = titleY + 10; // Start below the title
    const infoBoxHeight = 70; // Adjust the height based on content
    doc.setDrawColor(41, 128, 185); // Blue border color
    doc.setLineWidth(0.5);
    doc.roundedRect(10, infoBoxY, 190, infoBoxHeight, 5, 5, 'S');

    // Add Staff Member Details inside the info box
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(`Name: ${staffMember.firstName} ${staffMember.lastName}`, 15, infoBoxY + 10);
    doc.text(`Email: ${staffMember.email}`, 15, infoBoxY + 20);
    doc.text(`Department: ${department}`, 15, infoBoxY + 30);
    doc.text(`Role: ${role}`, 15, infoBoxY + 40);

    // Add Report Statistics inside the info box
    doc.text(`Total Reports: ${reportStats.totalReports}`, 110, infoBoxY + 10);
    doc.text(`Passed Reports: ${reportStats.passedReports}`, 110, infoBoxY + 20);
    doc.text(`Failed Reports: ${reportStats.failedReports}`, 110, infoBoxY + 30);
    doc.text(`Overall Pass Percentage: ${reportStats.passPercentage.toFixed(2)}%`, 110, infoBoxY + 40);
    doc.text(`Average Score: ${reportStats.averageScore.toFixed(2)}%`, 110, infoBoxY + 50);
    doc.text(`Pass/Fail Question Ratio: ${reportStats.passFailRatio.toFixed(2)}%`, 110, infoBoxY + 60);

    // Add Date Range inside the info box
    doc.text(`Date Range: ${formattedStartDate} to ${formattedEndDate}`, 15, infoBoxY + 60);

    // Move the table of reports below the info box
    const tableStartY = infoBoxY + infoBoxHeight + 10; // Start below the info box

    // Table of reports
    const tableData = reports.map(report => [
      report.evaluationName || '',
      formatDate(report.timeSubmitted) || 'N/A',
      report.stats.earnedPoints || 0,
      report.stats.totalPoints || 0,
      report.stats.passedCount || 0,
      report.stats.totalPassFail || 0,
      report.stats.overallPass ? 'PASS' : 'FAIL'
    ]);

    doc.autoTable({
      startY: tableStartY, // Adjust startY to align below the info box
      head: [['Evaluation Name', 'Submitted At', 'Earned Points', 'Total Points', 'Pass/Fail Count', 'Total Pass/Fail', 'Result']],
      body: tableData,
      theme: 'striped',
      headStyles: { fillColor: [41, 128, 185], textColor: 255 },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      styles: { font: 'RedditSans' },
      didDrawPage: (data) => {
        // Footer for each page
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.setTextColor(150);
        doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
      },
    });

    const fileName = `Staff_Report_Summary_${staffMember.firstName}_${staffMember.lastName}_${new Date().toISOString().split('T')[0].replace(/-/g, '_')}.pdf`;
    doc.save(fileName);
  };

  return (
    <Flex alignItems="center">
      <Tooltip label="Download Staff Report Summary" aria-label="Download Staff Report Summary">
        <IconButton
          colorScheme="blue"
          icon={<DownloadIcon />}
          onClick={generatePDF}
        />
      </Tooltip>
    </Flex>
  );
};

export default StaffReportsPDF;
